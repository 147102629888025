import { TicketsLabelsCreatedDto } from '../../../api/models/tickets-labels-created-dto';
// tslint:disable-next-line:max-line-length
import { IntersectionBoardSettingsReqDtoPartialTypeClass } from '../../../api/models/intersection-board-settings-req-dto-partial-type-class';
import { ColumnsDbDto } from '../../../api/models/columns-db-dto';
import { TicketsDbDto } from '../../../api/models/tickets-db-dto';
import { IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto } from '../../../api/models/intersection-tickets-create-req-dto-common-optional-files-arr-req-dto';
import { IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto } from '../../../api/models/intersection-tickets-update-req-dto-common-optional-files-arr-req-dto';
import { BacklogTicket, BoardTicket } from '../../../pages/full-pages/board/board-tickets.types';

/** Set object - objectId **/
export class SetObjectObjectId {
  static readonly type = '[Boards] SetObjectObjectId';
  constructor(public payload: { object: string; objectId: string }) {}
}

/** Get tickets list events **/
export class ColumnsGetList {
  static readonly type = '[Boards] ColumnsGetList';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class ColumnsCreate {
  static readonly type = '[Boards] ColumnsCreate';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class ColumnsDelete {
  static readonly type = '[Boards] ColumnsDelete';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class ColumnsUpdate {
  static readonly type = '[Boards] ColumnsUpdate';
  constructor(public payload) {}
}

/** Update ticket socket event **/
export class ColumnsSocketUpdate {
  static readonly type = '[Boards] ColumnsSocketUpdate';
  constructor(public payload: ColumnsDbDto) {}
}

export class ColumnsUpdateOrders {
  static readonly type = '[Boards] ColumnsSocketUpdate';
  constructor(
    public payload: {
      columns: Array<ColumnsDbDto>;
      object: string;
      objectId: string;
    },
  ) {}
}

/** Move ticket socket event **/
export class ColumnsSocketMove {
  static readonly type = '[Boards] ColumnsSocketMove';
  constructor(public payload: { columns: Array<ColumnsDbDto>; requestId: string }) {}
}

/** Create ticket event **/
export class TicketsCreate {
  static readonly type = '[Boards] TicketsCreate';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsGetList {
  static readonly type = '[Boards] TicketsGetList';
  constructor(public payload) {}
}

/** Get short tickets list events **/
export class TicketsGetShortList {
  static readonly type = '[Boards] TicketsGetShortList';
  constructor(public payload) {}
}

/** Get short tickets list events **/
export class TicketsClear {
  static readonly type = '[Boards] TicketsClear';
  constructor(public payload) {}
}

export class TicketsUpdateOrders {
  static readonly type = '[Boards] TicketsUpdateOrders';
  constructor(
    public payload: {
      ticketIdToUpdate: string;
      tickets: Partial<BoardTicket | BacklogTicket>[];
      object: string;
      objectId: string;
      sprintId?: string;
      isBacklog?: boolean;
    },
  ) {}
}

/** Get user tickets list events **/
export class TicketsGetUserList {
  static readonly type = '[Boards] TicketsGetUserList';
  constructor(public payload) {}
}

export class DraftsGetList {
  static readonly type = '[Boards] DraftsGetList';
  constructor() {}
}

export class DraftGetById {
  static readonly type = '[Boards] DraftGetById';
  constructor(public payload: { id: string }) {}
}

export class DraftCreate {
  static readonly type = '[Boards] DraftCreate';
  constructor(
    public payload: {
      ticketId?: string;
      body: IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto;
    },
  ) {}
}

export class DraftUpdate {
  static readonly type = '[Boards] DraftUpdate';
  constructor(
    public payload: {
      id: string;
      isNeedToCreateNew?: boolean;
      isNeedToSaveChanges?: boolean;
      body: IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto;
    },
  ) {}
}

export class DraftDelete {
  static readonly type = '[Boards] DraftDelete';
  constructor(public payload: { id: string }) {}
}

/** Get checked boards events **/
export class BoardsGetChecked {
  static readonly type = '[Boards] GetChecked';
  constructor(public payload) {}
}

/** Get short tickets list events **/
export class TicketsGetFilesList {
  static readonly type = '[Boards] TicketsGetFilesList';
  constructor(public payload) {}
}

/** Get ticket by id list events **/
export class TicketsGetById {
  static readonly type = '[Boards] TicketsGetById';
  constructor(public payload) {}
}

/** Set columns last id events **/
export class ColumnsSetLastAddedId {
  static readonly type = '[Boards] ColumnsSetLastAddedId';
  constructor(public payload) {}
}

/** Set tickets last id events **/
export class TicketsSetLastAddedId {
  static readonly type = '[Boards] TicketsSetLastAddedId';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsDelete {
  static readonly type = '[Boards] TicketsDelete';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsUpdate {
  static readonly type = '[Boards] TicketsUpdate';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsMove {
  static readonly type = '[Boards] TicketsMove';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsUpdateLocal {
  static readonly type = '[Boards] TicketsUpdate';
  constructor(public payload) {}
}

/** Update socket tickets **/
export class TicketsSocketUpdate {
  static readonly type = '[Boards] TicketsSocketUpdate';
  constructor(public payload: TicketsDbDto) {}
}

/** Move socket tickets **/
export class TicketsSocketMove {
  static readonly type = '[Boards] TicketsSocketMove';
  constructor(public payload: { tickets: Array<TicketsDbDto>; requestId: string }) {}
}

/** Update socket tickets **/
export class TicketsSocketUpdateUserBoard {
  static readonly type = '[Boards] TicketsSocketUpdateUserBoard';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsUpdateColumn {
  static readonly type = '[Boards] TicketsUpdateColumn';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsUpdateOrdersInColumns {
  static readonly type = '[Boards] TicketsUpdateOrdersInColumns';
  constructor(public payload) {}
}

/** Upload ticket files events **/
export class TicketsFilesUpload {
  static readonly type = '[Boards] TicketsFilesUpload';
  constructor(public payload) {}
}

/** Delete ticket files events **/
export class TicketsFilesDelete {
  static readonly type = '[Boards] TicketsFilesDelete';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsMembersGetList {
  static readonly type = '[Boards] TicketsMembersGetList';
  constructor(public payload) {}
}

/** Get tickets list events **/
export class TicketsMembersCreate {
  static readonly type = '[Boards] TicketsMembersCreate';
  constructor(public payload) {}
}

/** Update tickets members action **/
export class TicketsMembersUpdate {
  static readonly type = '[Boards] TicketsMembersUpdate';
  constructor(public payload) {}
}

/** Create tickets label action **/
export class TicketsLabelCreate {
  static readonly type = '[Boards] LabelCreate';

  constructor(public payload: TicketsLabelsCreatedDto) {}
}

/** Get tickets labels action **/
export class TicketsLabelsGet {
  static readonly type = '[Boards] LabelsGet';
  constructor(public payload) {}
}

/** Move all tickets to Archive action **/
export class TicketsMoveAllToArchive {
  static readonly type = '[Boards] MoveAllToArchive';
  constructor(public payload) {}
}

/** Move tickets to Archive action **/
export class TicketsMoveToArchive {
  static readonly type = '[Boards] MoveToArchive';
  constructor(public payload) {}
}

/** Open ticket status change action **/
export class TicketsOpenTicketChange {
  static readonly type = '[Boards] OpenTicketChange';
  constructor(public payload) {}
}

/** Create estimation session for ticket **/
export class TicketsCreateEstimationSession {
  static readonly type = '[Boards] CreateEstimationSession';
  constructor(public payload) {}
}

/** Socket Update estimation session for ticket **/
export class TicketsSocketUpdateEstimationSession {
  static readonly type = '[Boards] SocketUpdateEstimationSession';
  constructor(public payload) {}
}

/** Get estimation session for ticket **/
export class TicketsGetEstimationSession {
  static readonly type = '[Boards] GetEstimationSession';
  constructor(public payload) {}
}

/** Update estimation session for ticket **/
export class TicketsUpdateEstimationSession {
  static readonly type = '[Boards] UpdateEstimationSession';
  constructor(public payload) {}
}

/** Delete estimation session for ticket **/
export class TicketsDeleteEstimationSession {
  static readonly type = '[Boards] DeleteEstimationSession';
  constructor(public payload) {}
}

/** Join member to estimation session for the ticket **/
export class TicketsJoinMemberToEstimationSession {
  static readonly type = '[Boards] JoinMemberEstimationSession';
  constructor(public payload) {}
}

/** Update member to estimation session for the ticket **/
export class TicketsUpdateMemberEstimationSession {
  static readonly type = '[Boards] UpdateMemberEstimationSession';
  constructor(public payload) {}
}

export class TicketsGetChecklistItems {
  static readonly type = '[Boards] TicketsGetChecklistItems';
  constructor(public payload) {}
}

export class TicketsAddChecklistItems {
  static readonly type = '[Boards] TicketsAddChecklistItems';
  constructor(public payload) {}
}

export class TicketsDeleteChecklistItem {
  static readonly type = '[Boards] TicketsDeleteChecklistItem';
  constructor(public payload) {}
}

export class TicketsUpdateChecklistItem {
  static readonly type = '[Boards] TicketsUpdateChecklistItem';
  constructor(public payload) {}
}

export class TicketsDeleteChecklist {
  static readonly type = '[Boards] TicketsDeleteChecklist';
  constructor(public payload) {}
}

export class TicketsOrderUpdate {
  static readonly type = '[Boards] TicketsOrderUpdate';
  constructor(public payload) {}
}

export class TicketUpdateChecklist {
  static readonly type = '[Boards] TicketUpdateChecklist';
  constructor(public payload) {}
}

export class TicketClearLocalList {
  static readonly type = '[Boards] TicketClearLocalList';
  constructor(public payload) {}
}

export class BoardsGetSettings {
  static readonly type = '[Boards] BoardsGetSettings';
  constructor(public payload: { object: 'spaces' | 'projects'; objectId: string }) {}
}

export class BoardsSetSettings {
  static readonly type = '[Boards] BoardsSetSettings';
  constructor(public payload: IntersectionBoardSettingsReqDtoPartialTypeClass) {}
}

export class BoardsApplyTicketSettings {
  static readonly type = '[Boards] BoardsApplyTicketSettings';
  constructor(public payload: { id: string }) {}
}
