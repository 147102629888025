import { Injectable } from '@angular/core';
import { CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import moment from 'moment-timezone';

@Injectable()
export class CustomCalendarDateFormatter extends CalendarNativeDateFormatter {
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return moment(date).format('HH');
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return moment(date).format('HH');
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }

  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'MMM y', locale);
  }
}
