import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import { DatePipe, NgIf, NgFor, NgClass } from '@angular/common';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { delay, filter, map, pairwise, pluck, takeUntil, tap } from 'rxjs/operators';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import {
  NgbModal,
  NgbModalRef,
  NgbTooltip,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { endOfDay, startOfDay } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { NgScrollbar } from 'ngx-scrollbar';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import ResizeObserver from 'resize-observer-polyfill';
import moment from 'moment-timezone';
import equal from 'deep-equal';
import { LocalStorageService } from 'ngx-localstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { Message } from '../chat.model';
import { CheckPermissionPipe } from '../../../pipes/check-permission.pipe';
import { EmojisHelper } from '../../../utils/emojis-helper';
import { FilesHelper } from '../../../utils/files-helper';
import { ConfirmAlert } from '../../../alerts/alerts';
import { SocketsService } from '../../../services/sockets.service';
import { MediaService } from '../../../services/media.service';
import { ConfigService } from '../../../services/config.service';
import {
  ChatMessagesClear,
  ChatsCreatePin,
  ChatsDeletePin,
  ChatsEditLastMessageWithArrowUp,
  ChatsEmojiPicker,
  ChatsGetFile,
  ChatsGetMessages,
  ChatsSocketNewMessage,
  ChatsUpdateFile,
  MarkAll,
  MarkAsRead,
  MarkAsUnread,
} from '../../../store/actions/chats.action';
import { EmojiCreate, EmojiDelete } from '../../../store/actions/emojis.action';
import { ThreadsSocketNewMessage } from '../../../store/actions/threads.action';
import { TicketsGetList } from '../../../store/actions/board.action';
import { AuthState } from '../../../store/states/auth.state';
import { ChatsState } from '../../../store/states/chats.state';
import { BoardsState } from '../../../store/states/boards.state';
import { ChatForwardModalComponent } from '../chat-forward-modal/chat-forward-modal.component';
import { BoardTicketModalComponent } from '../../../../modals/board-ticket/board-ticket.component';
import { CalendarEventModalComponent } from '../../../../modals/calendar-event/calendar-event.component';
import { NoteModalComponent } from '../../../../modals/note/note.component';
import { HtmlHelper } from '../../../utils/html-helper';
import { SpacesState } from '../../../store/states/spaces.state';
import { ProjectsState } from '../../../store/states/projects.state';
import { SpacesDbDto } from '../../../../api/models/spaces-db-dto';
import { ScrollingChatService } from '../../../services/scrolling-chat.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RolesTypeEnum } from '../chat-pinned-messages/enums/roles-type.enum';
import { PinType } from '../chat-pinned-messages/enums/pin-type.enum';
import { MinimizeService } from '../../../services/minimize.service';
import { FullImagePreviewService } from '../../../services/full-image-preview.service';
import { PreviewMediaComponent } from '../../../../modals/data-room-modals/preview-media/preview-media.component';
import { OfflineMessagesService } from '../../../services/offline-messages.service';
import { ChatService, ChatTypeEnum } from '../chat.service';
import { UsersState } from '../../../store/states/users.state';
import { UsersDbDto } from '../../../../api/models/users-db-dto';
import { ImageService } from '../../../services/image.service';
import { QuillInitializeService } from '../../../services/quill/quill-init.service';
import { LocalStorageKeys } from '../../../../types/local-storage-keys.enum';
import { UploadFileService } from '../../../services/upload-file.service';
import { PlatformService } from '../../../../../app/shared/services/platform.service';
import { DownloadService } from '../../../../api/services/download.service';
import { CheckIsPinPipeAlone } from '../../../../standalone/pipes/check-isPin.pipe';
import { CheckIsPinPipe } from '../../../pipes/check-isPin.pipe';
import { BottomSheetComponent } from '../../bottom-sheet/bottom-sheet.component';
import { MessageActionsButtonsComponent } from './message-actions-buttons/message-actions-buttons.component';
import { MessageEditComponent } from './message-edit/message-edit.component';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { DocMessageComponent } from '../chat-message-content/doc-message/doc-message.component';
import { VideoMessageComponent } from '../chat-message-content/video-message/video-message.component';
import { AudioMessageComponent } from '../chat-message-content/audio-message/audio-message.component';
import { ImageMessageComponent } from '../chat-message-content/image-message/image-message.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { MessagePollComponent } from './message-poll/message-poll.component';
import { MessageFilesComponent } from './message-files/message-files.component';
import { LinkedObjectBadgeComponent } from '../../linked-object-badge/linked-object-badge.component';
import { ChatMessageContentComponent } from '../chat-message-content/chat-message-content.component';
import { ChatMessageDetailsComponent } from '../chat-message-details/chat-message-details.component';
import { WebhookAvatarComponent } from './webhook-avatar/webhook-avatar.component';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { NgScrollbarReachedTop, NgScrollbarReachedBottom } from 'ngx-scrollbar/reached-event';
import { DragDropViewComponent } from '../../documents/drag-drop-view/drag-drop-view.component';
import { DragDropDirective } from '../../../directives/drag-drop.directive';
import { MediaPreviewComponent } from '../../media-preview/media-preview.component';
import { DataroomLocationComponent } from '../../dataroom-location/dataroom-location.component';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';

export enum ConversionActionType {
  Ticket = 'TICKET',
  Event = 'EVENT',
  Note = 'NOTE',
}

export enum LinkedEntityActionType {
  Ticket = 'tickets',
  Files = 'files',
  Event = 'calendar-events',
  Note = 'notes',
  LaneBoard = 'lane-boards',
  Estimation = 'estimation-session',
  Wiki = 'wiki-pages',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DataroomLocationComponent,
    MediaPreviewComponent,
    TranslocoDirective,
    DragDropDirective,
    DragDropViewComponent,
    NgScrollbar,
    NgScrollbarReachedTop,
    NgScrollbarReachedBottom,
    NgIf,
    forwardRef(() => AvatarComponent),
    WebhookAvatarComponent,
    ChatMessageDetailsComponent,
    ChatMessageContentComponent,
    NgFor,
    LinkedObjectBadgeComponent,
    MessageFilesComponent,
    MessagePollComponent,
    NgClass,
    SvgIconComponent,
    ImageMessageComponent,
    AudioMessageComponent,
    VideoMessageComponent,
    DocMessageComponent,
    NgbTooltip,
    SvgComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    MessageEditComponent,
    MessageActionsButtonsComponent,
    BottomSheetComponent,
    DatePipe,
    CheckIsPinPipe,
    RouterTenantPipe,

    CheckIsPinPipeAlone,
  ],
})
export class ChatMessagesComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @ViewChild('scrollableMessages') scrollbarRef: NgScrollbar; // Get scrollbar component reference
  @ViewChild('imagePreviewModal') imagePreviewModal;
  @ViewChild('dataRoomImagePreviewModal') dataRoomImagePreviewModal;
  @ViewChild('dataRoomModalTemplate') dataRoomModalTemplate;
  @ViewChild('editMessageInput', { static: false })
  editMessageInput: ElementRef;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @ViewChild('chatMessagesWrapper') chatMessagesWrapper: ElementRef;
  @ViewChildren('chatMessagesWrapper') chatMessagesList: QueryList<ElementRef>;
  @ViewChild('bottomSheetComponent') bottomSheetRef: ElementRef;
  @ViewChild('messageEdit') messageEdit: ElementRef;

  @Input() platform = 'web';
  @Input() object: string;
  @Input() objectId: string;
  @Input() chatId: string;
  @Input() threadId: string;
  @Input() messages: any[];
  @Input() mentionChatMembers: string[];
  @Input() showNoMessagesContainer: boolean;
  @Input() isVisible: boolean;
  @Input() isSeparate: boolean;

  @Input() mainMessage: any;
  @Input() isPreview = false;
  @Input() isPreviewMobile = false;
  @Input() numberOfReplies = 0;
  @Input() heightKeyboard = 0;
  @Input() isThread = false;
  @Input() isOpenedThread: boolean;
  @Input() readOnly = false;

  @Input() perPage = 20;
  @Input() perChat = 80;
  @Input() currentPage = 1;
  @Input() previousPage = 0;
  @Input() chatMessageCnt = 0;
  @Input() lastMessageLoaded: boolean;

  @Output() downloadFileCompleted = new EventEmitter();
  @Output() uploadClipboardFile = new EventEmitter();
  @Output() copyTextToClipboard = new EventEmitter<string>();
  @Output() recheckScroll = new EventEmitter();
  @Output() openThread = new EventEmitter();
  @Output() jumpToBottom = new EventEmitter();
  @Output() deletedThreadMessageId = new EventEmitter();

  @Output() onScrolledToTop = new EventEmitter<boolean>();
  @Output() onScrolledToBottom = new EventEmitter();
  MESSAGES_AGGREGATE_TIME = 10;

  stopMarkAll = false;
  /*todo add types instead any*/
  chatMessages: any[] = [];
  boardTickets: any[] = [];
  initLoadingScroll = false;

  config: any = {};
  currTzAbbr = null;
  emojiPickerImage = 'assets/img/emojis/emojis.png';
  messageReactionIsOpen = false;
  isBottomSheetOpened = false;
  initChat = false;
  isLoadingMessages = false;
  language: string = this.localStorage.get(LocalStorageKeys.language) || 'en';
  options: any;
  user: any;
  touchedMessage: any;
  editingMessage: any;

  frequentlyUsedEmojis = ['👍', '😊', '😀', '🙏', '✅'];
  convertIntoList: {
    title: string;
    icon: string;
    action: ConversionActionType;
    translation?: string;
  }[] = [
    {
      title: this.translocoService.translate('chat.event-convert'),
      icon: 'calendar',
      action: ConversionActionType.Event,
      translation: 'chat.event-convert',
    },
    {
      title: this.translocoService.translate('chat.ticket-convert'),
      icon: 'board',
      action: ConversionActionType.Ticket,
      translation: 'chat.ticket-convert',
    },
    {
      title: this.translocoService.translate('chat.note-convert'),
      icon: 'pen',
      action: ConversionActionType.Note,
      translation: 'chat.note-convert',
    },
  ];

  destroy$: Subject<void> = new Subject<void>();
  scrollSubs: Subscription;
  previewModal: NgbModalRef;
  dataRoomModal: NgbModalRef;

  allImageMessages = [];
  imagePreviewMessage;
  isOpenPinned = true;
  chatMembers = [];
  pinnedMessage = [];
  messagesMedia = [];
  currentMediaPath: string;
  isLastPreviewImage = false;
  isFirstPreviewImage = false;
  canUnpinMessage = true;
  isLoadingPin = false;

  updateEvent: EventEmitter<any> = new EventEmitter();
  editingMessageElement: any;
  hoveredMessageId: string;
  selectedFileToSaveInDataRoom: any;
  selectedMessageType: string;
  searchIsActive = false;
  searchMessageId = null;

  avatarSize = 24;
  statusSize = 9;

  endlessScrollRatio: number;
  scrollHeight = 0;
  pageMessagesCnt = 0;
  scrolledTo: string;
  space: SpacesDbDto;
  mediaPreviewModal: NgbModalRef;
  contentInited = false;
  isPreloadActive = false;
  needScrollToBottom = true;
  isLoadFirstMessagesPart = false;
  isOffline = false;
  private chatMessagesResizeObserver: ResizeObserver;
  spaces: any[] = [];
  projects: any[] = [];
  threadAvatarsLimitation = 4;

  isFullSizeSheet = false;
  touchstartX = 0;
  touchendX = 0;
  touchstartY = 0;
  touchendY = 0;
  touchStart$ = fromEvent(document, 'touchstart').pipe(
    takeUntil(this.destroy$),
    delay(300),
    tap(() => {
      this.options = {
        title: 'Actions',
        theme: this.config?.layout.variant,
      };
    }),
  );
  touchEnd$ = fromEvent(document, 'touchend').pipe(
    takeUntil(this.destroy$),
    tap(() => this.subscribeToTouchObservables()),
  );
  touchMove$ = fromEvent(document, 'touchmove').pipe(
    takeUntil(this.destroy$),
    tap(() => this.subscribeToTouchObservables()),
  );
  isDirect: boolean = null;
  tenantUsers: UsersDbDto[] = [];
  isDraggingFile: boolean;

  constructor(
    public cdr: ChangeDetectorRef,
    private store: Store,
    private actions: Actions,
    private datePipe: DatePipe,
    private modalsService: NgbModal,
    private filesHelper: FilesHelper,
    private scrollingService: ScrollingChatService,
    private emojisHelper: EmojisHelper,
    private toastr: ToastrService,
    private routerTenantPipe: RouterTenantPipe,
    private configService: ConfigService,
    private socketsService: SocketsService,
    private offlineService: OfflineMessagesService,
    private mediaService: MediaService,
    private localStorage: LocalStorageService,
    private checkPermissionPipe: CheckPermissionPipe,
    private quillInitializeService: QuillInitializeService,
    private htmlHelper: HtmlHelper,
    private router: Router,
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private minimizeService: MinimizeService,
    public fullImagePreviewService: FullImagePreviewService,
    private chatService: ChatService,
    private imageService: ImageService,
    private renderer: Renderer2,
    private translocoService: TranslocoService,
    public uploadFileService: UploadFileService,
    private platformService: PlatformService,
  ) {
    this.config = this.configService.templateConf;
    this.endlessScrollRatio = this.configService.ENDLESS_SCROLL_RATIO;
  }

  ngOnInit(): void {
    this.chatService.needScrollToBottom$.pipe(untilDestroyed(this)).subscribe((chatType) => {
      setTimeout(() => {
        if (this.isThread) {
          this.scrollbarRef
            .scrollTo({ bottom: 0, duration: 0 })
            .then(() => this.scrollbarRef.update());
        }
      }, 100);
    });
    this.chatService.currentChatType = this.isThread ? ChatTypeEnum.Thread : ChatTypeEnum.Chat;
    if (this.isThread) {
      this.endlessScrollRatio -= 0.01;
      if (this.mainMessage.objectId) {
        this.object = this.mainMessage.object;
        this.objectId = this.mainMessage.objectId;
      }
    }

    this.translocoService.langChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const newItems = this.convertIntoList.map((item) => ({
        ...item,
        title: this.translocoService.translate(item.translation),
      }));
      this.convertIntoList = [...newItems];
    });

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });

    this.translocoService.langChanges$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      this.language = lang || 'en';
    });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user;
          if (user?.timezone) {
            this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
          }
        },
      });

    this.store
      .select(ChatsState.getSearchIsActive)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.searchIsActive = res;
        this.cdr.detectChanges();
      });

    this.store
      .select(ChatsState.getStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isOffline = res;
        if (!this.isOffline) {
          this.offlineService.resendMessages({
            chatId: this.chatId,
            threadId: this.threadId,
            isThread: this.isThread,
          });
        }
        this.cdr.detectChanges();
      });

    this.store
      .select(ChatsState.getSearchMessageId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.searchMessageId = res;
        this.cdr.detectChanges();
      });

    this.store
      .select(ChatsState.getPinMessages)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.pinnedMessage = res;
        this.cdr.detectChanges();
      });

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(ChatsSocketNewMessage))
      .subscribe(() => {
        if (!this.isThread) {
          this.needScrollToBottom = true;
        }
      });
    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(ThreadsSocketNewMessage))
      .subscribe(() => {
        if (this.isThread) {
          this.needScrollToBottom = true;
        }
      });

    this.offlineService.isComplete.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.messages = this.messages.filter((msg) => msg._id);
      this.chatMessages = this.chatMessages.filter((msg) => msg._id);
    });

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(ChatsEditLastMessageWithArrowUp))
      .subscribe(({ payload }) => {
        const lastMessage = this.messages[this.messages.length - 1];

        if (
          lastMessage &&
          this.user._id === lastMessage.userId &&
          this.isThread === payload.isThread
        ) {
          this.onMessageEditStart({
            message: lastMessage,
            passedElement: `edit-${payload.isThread ? 'thread' : 'chat'}-message-${this.messages.length - 1}`,
          });
        }
      });

    combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([spaces, projects]) => {
        this.spaces = spaces.filter((item) =>
          this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate'),
        );
        this.projects = projects.filter((item) =>
          this.checkPermissionPipe.transform('projects::' + item._id + '::calendarEventCreate'),
        );
      });

    this.store
      .select(ChatsState.getChats)
      .pipe(takeUntil(this.destroy$))
      .subscribe((chats) => {
        if (this.isDirect === null && chats?.length) {
          const chat = chats.find((item) => item._id === this.chatId);
          this.isDirect = chat ? chat.type === 'direct' : null;
        }
      });

    this.platform = this.store.selectSnapshot(AuthState.getPlatform) || this.platform;

    this.store
      .select(UsersState.getTenantUsers)
      .pipe(untilDestroyed(this))
      .subscribe((tenantUsers) => {
        this.tenantUsers = tenantUsers;
      });

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      console.log('Query params:', params);
      const messageId = params['messageId'];
      if (messageId) {
        this.scrollToMessage(messageId);
      }
    });
  }

  ngAfterViewInit() {
    if (this.isThread) {
      this.imageService._threadImagesLoading.pipe(untilDestroyed(this)).subscribe(
        () => {},
        () => {},
        () => {
          if (this.currentPage <= 1) {
            setTimeout(() => {
              this.scrollbarRef.scrollTo({ bottom: 0, duration: 0 }).then(() => {
                this.scrollbarRef.update();
                this.cdr.detectChanges();
              });
            }, 100);
          }
        },
      );
    } else {
      this.imageService._imagesLoading
        .pipe(untilDestroyed(this))
        .pipe()
        .subscribe(
          () => {},
          () => {},
          () => {
            if (this.currentPage <= 1) {
              setTimeout(() => {
                this.scrollbarRef.scrollTo({ bottom: 0, duration: 0 }).then(() => {
                  this.scrollbarRef.update();
                  this.cdr.detectChanges();
                });
              }, 100);
            }
          },
        );
    }

    if (this.platform !== 'web') {
      this.subscribeToTouchObservables();
    }

    this.initializeScrollListeners();

    this.updateScrollbar();

    this.route.queryParams.subscribe((params) => {
      const messageId = params['messageId'];
      if (messageId) {
        this.scrollToMessage(messageId);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.heightKeyboard && this.platform !== 'web' && this.heightKeyboard > 0) {
      if (this.scrollbarRef) {
        this.scrollbarRef
          .scrollTo({
            top: this.scrollbarRef.viewport.scrollTop + this.heightKeyboard,
            duration: 0,
          })
          .then();
      } else {
        const selector = !this.isThread
          ? 'chat-messages'
          : 'thread-messages' + (this.readOnly ? '' : '-separate');
        const messagesElement = document.querySelector(`#${selector} .chat-messages`);
        messagesElement?.scrollIntoView(false);
        this.heightKeyboard = 0;
      }
    }

    if (changes.objectId && !!changes.objectId.currentValue) {
      this.getBoardTickets();
      this.scrollingService.setScrolling(false);
      this.isOpenPinned = true;
      this.store
        .select(ChatsState.getChatMembers)
        .pipe(
          takeUntil(this.destroy$),
          map((filterFn) => filterFn(this.chatId)),
        )
        .subscribe((members) => (this.chatMembers = members));
    }

    // --------------- START OFFLINE BEHAVIOR --------------------

    if (changes.objectId || !!changes.threadId?.currentValue) {
      this.initChat = true;
      this.offlineService.resendMessages({
        chatId: this.chatId,
        threadId: this.threadId,
        isThread: this.isThread,
      });
    }

    // --------------- END OFFLINE BEHAVIOR --------------------

    // TODO: for scroll to bottom
    // if (changes.objectId) {
    //   this.chatMessagesList.changes.pipe(take(1)).subscribe((e) => {
    //     this.needScrollToBottom = true;
    //     this.scrollbarRef.scrollTo({ bottom: 0 });
    //   });
    // }
    if (changes.messages && !!changes.messages.currentValue) {
      if (changes.messages.currentValue.length && !changes.messages.previousValue?.length) {
        setTimeout(() => this.scrollbarRef?.scrollTo({ bottom: 0, duration: 0 }), 200);
      }
      this.space = this.store
        .selectSnapshot(SpacesState.getLoadedSpaces)
        .find((space) => space._id === this.objectId);

      this.contentInited = false;
      this.chatMessages = this.populateMessages(this.messages);
      this.isLoadingMessages = false;
      this.changeMediaFile();

      if (changes.messages.currentValue.length > 0) {
        this.isVisible = true;
      }

      if (this.currentPage === 1) {
        this.pageMessagesCnt = this.messages.length;
      }

      this.updateScrollbar();

      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    if (!this.isOffline && this.chatId) {
      this.store.dispatch(new ChatMessagesClear({ chatId: this.chatId }));
    }
    if (this.stopMarkAll === false) {
      if (this.isThread) {
        this.store.dispatch(new MarkAll({ chatId: this.threadId, isThread: true, isChat: false }));
      } else {
        this.store.dispatch(new MarkAll({ chatId: this.chatId, isThread: false, isChat: true }));
      }
    }
    this.chatMessagesResizeObserver?.disconnect();
    this.scrollSubs?.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  isEqual<T>(prevItems: Array<T>, nextItems: Array<T>): boolean {
    const prevItemsMap = new Map(prevItems.map((item) => [item['_id'], item]));
    const nextItemsMap = new Map(nextItems.map((item) => [item['_id'], item]));

    if (prevItemsMap.size !== nextItemsMap.size) {
      return false;
    } else {
      for (const [key, value] of prevItemsMap) {
        if (!nextItemsMap.has(key) || !equal(value, nextItemsMap.get(key))) {
          return false;
        }
      }
    }

    return true;
  }

  public get orderMessage(): any[] {
    // Step 1: Filter and deduplicate messages with ID
    const filteredWithId = this.chatMessages
      .reduce((acc, msg) => {
        if (
          msg?._id &&
          !acc.some((message) => message?.timestamp && message?.timestamp === msg?.timestamp)
        ) {
          acc.push(msg);
        }
        return acc;
      }, [])
      .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

    // Step 2: Filter messages without ID that do not duplicate those with ID
    const filteredWithoutId = this.chatMessages.filter(
      (msg) =>
        !msg._id && !filteredWithId.some((msgWithId) => msgWithId.timestamp === msg.timestamp),
    );

    // Step 3: Combine both filtered lists and remove any remaining duplicates
    const combinedMessages = [
      ...filteredWithId,
      ...filteredWithoutId.filter(
        (m) =>
          !filteredWithId.some(
            (msg) =>
              msg._id &&
              msg.userId === m.userId &&
              msg.text === m.text &&
              msg.timestamp === m.timestamp,
          ),
      ),
    ];

    // Step 4: Find the index of the first unread message
    const firstUnreadIndex = combinedMessages.findIndex((msg) => msg.isUnread);

    // Step 5: If there is an unread message, insert "THIS IS BEFORE UNREAD" before it
    if (firstUnreadIndex !== -1) {
      combinedMessages.splice(firstUnreadIndex, 0, { isUnreadBadge: true });
    }

    // Step 6: Add "THIS IS SPACE" between messages from different users and mark isFirstEl and isLastEl
    const messagesWithSpaces = [];
    let lastUserId = null;

    combinedMessages.forEach((msg, index) => {
      if (lastUserId && lastUserId !== msg.userId) {
        // Mark the last message of the previous user
        messagesWithSpaces[messagesWithSpaces.length - 1].isLastEl = true;
        // Insert space element
        messagesWithSpaces.push({ isSpacer: true });
        // Mark the first message of the current user
        msg.isFirstEl = true;
      } else if (lastUserId === null || lastUserId !== msg.userId) {
        // Mark the first message of the first user
        msg.isFirstEl = true;
      }

      messagesWithSpaces.push(msg);
      lastUserId = msg.userId;
    });

    // Mark the last message of the final user
    if (messagesWithSpaces.length > 0) {
      messagesWithSpaces[messagesWithSpaces.length - 1].isLastEl = true;
    }
    if (this.initLoadingScroll === false) {
      this.scrollToLastUnreadMessage();
      this.initLoadingScroll = true;
    }
    // Step 7: Populate and return the final list of messages
    return this.populateMessages(messagesWithSpaces);
  }

  public get isNotDirectMessage(): boolean {
    return !this.router.url.includes('page=dm');
  }

  changeMediaFile() {
    if (!this.chatMessages.length) {
      return;
    }

    const mediaFiles = this.store.selectSnapshot(ChatsState.getCurrentFileChat);
    const lastMessage = this.chatMessages[this.chatMessages?.length - 1];

    // Condition for checking if it`s a media file
    const isMediaFile =
      lastMessage?.fileData &&
      mediaFiles.every((media: any) => media._id !== lastMessage.fileData._id) &&
      this.checkIsMedia(lastMessage.fileData.originalFileName);

    // Change media list in modal
    if (isMediaFile) {
      const media: Array<any> = this.store.selectSnapshot(ChatsState.getCurrentFileChat);
      if (!!media.length && !this.isThread) {
        this.store.dispatch(new ChatsUpdateFile(lastMessage.fileData));
      }

      if (this.mediaPreviewModal) {
        this.mediaPreviewModal.componentInstance.media = this.handleMediaFile(lastMessage.fileData);
      }
    }
  }
  onMarkAsUnread() {
    this.stopMarkAll = true;
  }

  checkIsMedia(fileName): boolean {
    return (
      this.filesHelper.checkFileIsImage(fileName) || this.filesHelper.checkFileIsVideo(fileName)
    );
  }

  getBoardTickets() {
    if (this.checkPermissionPipe.transform(`${this.object}::${this.objectId}::ticketsGetList`)) {
      this.store
        .dispatch(
          new TicketsGetList({
            object: this.object,
            objectId: this.objectId,
            short: 'chat',
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.boardTickets = result.Boards.chatTickets[this.objectId];
          if (this.messages?.length) {
            this.chatMessages = this.populateMessages(this.messages);
          }
        });
    } else {
      this.boardTickets = [];
    }
  }

  checkScrollBar() {
    const scrollableElement = this.scrollbarRef.nativeElement;
    const containerElement = this.chatMessagesWrapper.nativeElement;

    const hasScrollBar = scrollableElement.scrollHeight < containerElement.clientHeight;

    return !hasScrollBar;
  }

  getUrl(message): boolean {
    return (
      (message.ticketsUrlData && message.ticketsUrlData.length) ||
      (message.wikiPagesUrlData && message.wikiPagesUrlData.length)
    );
  }

  get isMobile(): boolean {
    return this.platform !== 'web';
  }

  getIsFilesObject(message): boolean {
    return message.linkObject === 'files';
  }

  messagesListUpdated(): void {
    this.contentInited = true;
    this.loadFirstMessagesPart();

    if (!this.isLoadFirstMessagesPart) {
      this.isPreloadActive = false;
      if (this.searchMessageId) {
        this.scrollToMessage(this.searchMessageId);
      } else if (this.needScrollToBottom) {
        this.scrollToBottom();
      } else {
        this.scrollCorrection();
      }
    } else {
      this.isLoadFirstMessagesPart = false;
    }
  }

  updateScrollbar() {
    if (this.messagesContainer) {
      this.chatMessagesResizeObserver = new ResizeObserver(() => {
        this.messagesListUpdated();
      });

      this.chatMessagesResizeObserver.observe(this.messagesContainer.nativeElement);
    }
  }
  _markAsUnread(message: any): void {
    if (message.isUnread) {
      this.store.dispatch(new MarkAsRead({ message }));
    } else {
      this.store.dispatch(new MarkAsUnread({ message }));
      this.stopMarkAll = true;
    }
    this.closeBottomSheet();
  }

  public getPinUserName(message) {
    const creatorId = this.pinnedMessage.find(
      (msg: any) => msg.linkDocument && msg.linkMessage?._id === message._id,
    )?.creatorId;

    if (creatorId && this.chatMembers?.length) {
      let pinUserName = this.chatMembers.find((member) => member.userId === creatorId)?.userName;
      if (!pinUserName) {
        pinUserName = this.tenantUsers.find((tenantUser) => tenantUser._id === creatorId)?.userName;
      }
      return pinUserName;
    } else {
      return 'unknown';
    }
  }

  public getIsLinkObjectFile(message) {
    return (
      message.linkFile ||
      message.linkChatFile ||
      message.linkTicketFile ||
      message.linkThreadFile ||
      message.linkInlineFile
    );
  }

  public showLinkObject(message) {
    return message.linkObject === 'files'
      ? this.getIsLinkObjectFile(message)
      : message.linkObject && message?.linkObject !== 'video-calls';
  }

  updateScrolledView() {
    this.scrollbarRef?.update();
    this.needScrollToBottom = false;
    this.isVisible = true;
    this.cdr.detectChanges();
  }

  scrollToMessage(messageId = null) {
    if (messageId) {
      console.log(`msg-${messageId}`);

      setTimeout(() => {
        const messageElement = document.getElementById(`msg-${messageId}`);
        if (messageElement) {
          console.log('message is exist');
        } else {
          console.log('message is not exist');
        }
        messageElement?.scrollIntoView();
      }, 1000);
    }
  }

  scrollToLastUnreadMessage() {
    setTimeout(() => {
      const lastUnreadMessageIndex = this.chatMessages.findIndex((msg) => msg.isUnread);
      if (lastUnreadMessageIndex !== -1) {
        const startIndex = Math.max(0, lastUnreadMessageIndex - 5);
        const endIndex = lastUnreadMessageIndex;
        const messagesToScroll = this.chatMessages.slice(startIndex, endIndex + 1);

        const messageElement = document.getElementById(`msg-${messagesToScroll[0]._id}`);
        messageElement?.scrollIntoView();
      }
    }, 500);
  }

  scrollToBottom(): void {
    if (this.scrollbarRef) {
      if (!this.isThread && this.previousPage > 5) {
        this.store.dispatch(
          new ChatsGetMessages({
            chatId: this.messages[0].chatId,
            page: 1,
            perPage: this.perPage,
          }),
        );
        this.currentPage = 1;
        this.previousPage = 0;
        this.jumpToBottom.emit();
      } else {
        this.scrollbarRef.scrollTo({ bottom: 0, duration: 0 }).then(() => {
          setTimeout(() => this.updateScrolledView(), 300);
        });
      }
    } else {
      const selector = !this.isThread
        ? 'chat-messages'
        : 'thread-messages' + (this.readOnly ? '' : '-separate');
      const messagesElement = document.querySelector(`#${selector} .chat-messages`);
      messagesElement?.scrollIntoView(false);
      this.updateScrolledView();
    }
  }

  scrollBottom(): void {
    const selector = !this.isThread
      ? 'chat-messages'
      : 'thread-messages' + (this.readOnly ? '' : '-separate');
    const messagesElement = document.querySelector(`#${selector} .chat-messages`);
    messagesElement?.scrollIntoView(false);
    this.updateScrolledView();
    this.cdr.detectChanges();
  }

  scrolledToEnd() {
    this.scrollHeight = this.scrollbarRef.viewport.nativeElement?.scrollHeight;
  }

  scrollCorrection(): void {
    const viewportEl = this.scrollbarRef.viewport.nativeElement;
    const scrollRatio = viewportEl.scrollTop / viewportEl.scrollHeight;

    if (this.scrolledTo === 'top' && scrollRatio > 0.65) {
      this.scrolledTo = 'bottom';
    }

    if (this.scrolledTo === 'top' && scrollRatio < this.endlessScrollRatio) {
      const diff = viewportEl.scrollHeight - this.scrollHeight;
      viewportEl.scrollTop =
        diff > 0 ? diff : viewportEl.scrollHeight * this.endlessScrollRatio + 1;
    } else if (
      this.scrolledTo === 'bottom' &&
      (scrollRatio > 0.65 || (scrollRatio > 0.6 && this.searchIsActive))
    ) {
      viewportEl.scrollTop =
        this.scrollHeight > 0
          ? this.scrollHeight
          : viewportEl.scrollHeight * (scrollRatio > 0.65 ? 0.65 : 0.6) - 1;
    }

    this.scrolledTo = '';

    this.scrollbarRef.update();
    this.cdr.markForCheck();
  }

  private initializeScrollListeners(): void {
    this.scrollSubs?.unsubscribe();
    this.scrollSubs = this.scrollbarRef?.verticalScrolled
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: any) => {
        const selector = !this.isThread
          ? 'chat-messages'
          : 'thread-messages' + (this.readOnly ? '' : '-separate');
        const scrollbar = document
          .querySelector(`#${selector} .scrollbar-control`)
          ?.getBoundingClientRect();

        // Pinned
        if (!this.checkScrollBar()) {
          this.isOpenPinned = false;
        }

        if (
          (this.lastMessageLoaded && e.target.scrollTop <= 1) ||
          (e.target.scrollTop + scrollbar.height) / e.target.scrollHeight > 0.999
        ) {
          if (this.scrollingService.getValue && !this.isThread) {
            setTimeout(() => {
              this.scrollingService.setScrolling(false);
            }, 200);
          }
        }
        this.cdr.detectChanges();

        if (
          !this.isPreloadActive &&
          this.contentInited &&
          e.target.scrollHeight > e.target.offsetHeight
        ) {
          if (
            !this.lastMessageLoaded &&
            e.target.scrollTop / e.target.scrollHeight < this.endlessScrollRatio
          ) {
            if (this.scrolledTo || (!this.scrolledTo && !e.target.scrollTop)) {
              if (!this.isLoadingMessages) {
                this.scrolledTo = 'top';
                this.isPreloadActive = true;
                this.isLoadingMessages = this.isMobile;
                this.onScrolledToTop.emit();
              }
            }
          } else if (
            ((this.currentPage > this.perChat / this.perPage &&
              (this.perChat <= this.chatMessageCnt || this.lastMessageLoaded)) ||
              (this.currentPage > 1 && this.searchIsActive)) &&
            (e.target.scrollTop + scrollbar.height) / e.target.scrollHeight > 0.999
          ) {
            this.scrolledTo = 'bottom';
            this.isPreloadActive = true;
            // this.onScrolledToBottom.emit();
          }

          if (this.lastMessageLoaded && e.target.scrollTop <= 1) {
            this.isOpenPinned = true;
            this.cdr.detectChanges();
          }
        }
      });

    this.scrollbarRef.scrolled
      .pipe(
        filter(() => this.contentInited),
        pluck('target', 'scrollTop'),
        pairwise(),
        takeUntil(this.destroy$),
      )
      .subscribe(([previousScrollTop, currentScrollTop]: [number, number]) => {
        if (!this.scrollingService.getValue && this.platform !== 'mobile' && !this.isThread) {
          this.scrollingService.setScrolling(true);
        }

        if (currentScrollTop - previousScrollTop < 0) {
          this.needScrollToBottom = false;

          if (!this.scrollingService.getValue && this.platform === 'mobile') {
            this.scrollingService.setScrolling(true);
          }
        }

        if (currentScrollTop - previousScrollTop > 0) {
          if (this.scrollingService.getValue && this.platform === 'mobile') {
            this.scrollingService.setScrolling(false);
          }
        }
      });
  }

  private loadFirstMessagesPart(): void {
    if (this.currentPage === 1 && this.pageMessagesCnt === this.perPage) {
      const selector = this.isThread
        ? 'thread-messages' + (this.readOnly ? '' : '-separate')
        : 'chat-messages';
      const chatsElement = document.querySelector(`#${selector} .chat-messages`);

      if (chatsElement) {
        const chatsElemHeight = chatsElement.getBoundingClientRect().height;
        const parentContainer = document.querySelector(`#${selector} .chats`);
        const parentContainerHeight = parentContainer.getBoundingClientRect().height;

        if (parentContainerHeight > chatsElemHeight && !this.isPreloadActive) {
          this.isLoadFirstMessagesPart = true;
          this.isPreloadActive = true;
          this.needScrollToBottom = true;
          this.onScrolledToTop.emit(true);
        } else {
          this.isLoadFirstMessagesPart = false;
        }
      }
    }
  }

  get checkMessageMax(): boolean {
    return (
      this.pinnedMessage.filter((pin) => pin.linkDocument && pin.linkDocument === PinType.Message)
        ?.length <= 14
    );
  }

  messageHovered(messageId) {
    this.hoveredMessageId = messageId;

    if (this.platform !== 'web') {
      setTimeout(() => this.messageHoveredOut(), 10000);
    }
  }

  messageHoveredOut(): void {
    this.hoveredMessageId = null;
    this.cdr.markForCheck();
  }

  isChildOfElement(parent, child) {
    let isChild = false;
    let current = child;
    while ((current = current.parentNode)) {
      if (current === parent) {
        isChild = true;
        break;
      }
    }
    return isChild;
  }

  // retrieve file from clipboard
  @HostListener('document:paste', ['$event'])
  onPaste(event) {
    const item = event.clipboardData.items[0];

    if (item === undefined) {
      return;
    }

    const isChat = this.isChildOfElement(document.getElementById('chatInput'), event.target);
    const isThread = this.isChildOfElement(document.getElementById('threadInput'), event.target);

    if (isChat || isThread) {
      const uploadData = {
        retrievedFile: item.getAsFile(),
        type: isChat ? 'chat' : 'thread',
      };

      this.uploadClipboardFile.emit(uploadData);
    }
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event) {
    const element = document.querySelector('.image-preview');
    const actionsSheet = document.getElementById('bottomSheet');

    if (element?.contains(event.target)) {
      this.touchstartX = event.changedTouches[0].screenX;
    } else if (actionsSheet?.contains(event.target)) {
      this.touchstartY = event.changedTouches[0].screenY;
    }
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event) {
    const element = document.querySelector('.image-preview');
    const actionsSheet = document.getElementById('bottomSheet');

    if (element?.contains(event.target)) {
      this.touchendX = event.changedTouches[0].screenX;
      this.handleXGesture();
    } else if (actionsSheet?.contains(event.target)) {
      this.touchendY = event.changedTouches[0].screenY;
      this.handleYGesture();
    }
  }

  // open inline images in preview window
  // close emoji reactions when clicked outside
  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.messageReactionIsOpen && !event.target.classList.contains('emoji-reactions-button')) {
      const emojiPickerGeneralElement = document.querySelector('.emoji-picker-general');
      const emojiPickerDirectElement = document.querySelector('.emoji-picker');
      const emojiMartElement = document.querySelector('.emoji-mart');

      if (
        !emojiPickerGeneralElement?.contains(event.target) &&
        !emojiPickerDirectElement?.contains(event.target) &&
        !emojiMartElement?.contains(event.target)
      ) {
        this.closeEmojiReactions();
      }
    }
  }

  private handleImageClick(target: any): void {
    this.currentMediaPath = target.src;
    const messageId$ = target.closest('.message-text')?.id;
    const sharedMessage = target.closest('.shared-message');

    if (messageId$) {
      this.prepareMediaPreview(messageId$.replace('message-text-', ''), !!sharedMessage);

      if (this.messagesMedia.length > 0) {
        this.showModalImage();
      }
    }
  }

  public openImage(event): void {
    if (
      event?.target['src'] &&
      !event.target.classList.contains('uploaded-image') &&
      !this.mediaService.checkIfAvatar(event.target.classList)
    ) {
      const imageElements = document.querySelectorAll('.message-text img');

      if (imageElements.length > 0) {
        for (let i = 0; i < imageElements.length; i++) {
          if (imageElements[i]?.contains(event.target)) {
            this.handleImageClick(event?.target);
            break;
          }
        }
      }
    }
  }

  private showModalImage() {
    const mediaPreviewModal = this.modalsService.open(PreviewMediaComponent, {
      size: 'xl',
      windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
      centered: true,
    });

    mediaPreviewModal.componentInstance.previewData = {
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
      isMobile: this.isMobile,
      currentMedia: this.messagesMedia[0],
    };

    mediaPreviewModal.componentInstance.isNotDataRoom = true;
    mediaPreviewModal.componentInstance.pinnedType = this.isThread
      ? PinType.ThreadsFile
      : PinType.ChatFile;
    mediaPreviewModal.componentInstance.media = this.messagesMedia;

    this.minimizeService.minimizeInit(mediaPreviewModal, {
      width: '360px',
      height: '307px',
      right: '40px',
      borderRadius: '10px',
    });
  }

  private prepareMediaPreview(messageId: string, isSharedMessage = false): void {
    const message = isSharedMessage
      ? this.chatMessages.find((item) => item.sharedMessage && item.sharedMessage._id === messageId)
          .sharedMessage
      : this.chatMessages.find((item) => item._id === messageId) || this.mainMessage;
    const imagesUrls = this.mediaService.fetchImagesFromString(message?.text);

    if (imagesUrls?.length) {
      this.messagesMedia = [];
      imagesUrls.forEach((imageUrls) => {
        this.filesHelper.getFileNameWithoutExtension(imageUrls);
        this.messagesMedia.push({
          url: imageUrls,
          fileName: 'image.jpg',
          originalFileName: 'image.jpg',
          ownerUserId: message.userId,
          updated_at: message.updated_at,
          created_at: message.created_at,
        });
      });

      this.messagesMedia[0].isFirstMedia = true;
      this.messagesMedia[this.messagesMedia.length - 1].isLastMedia = true;
    }
  }

  closeImagePreview(): void {
    this.previewModal.close();
    this.currentMediaPath = '';
    this.messagesMedia = [];
  }

  get checkDirectMessage() {
    return this.router.url.includes('page=dm');
  }

  handleXGesture() {
    const currentImageIndex = this.getParticularMessage();
    this.invisibleImagePreviewArrows(currentImageIndex);

    if (this.touchendX < this.touchstartX && Math.abs(this.touchstartX - this.touchendX) > 20) {
      // swipe left
      if (!this.isLastPreviewImage) {
        this.goToNextImage();
      }
    } else if (
      this.touchendX > this.touchstartX &&
      Math.abs(this.touchendX - this.touchstartX) > 20
    ) {
      // swipe right
      if (!this.isFirstPreviewImage) {
        this.goToPreviousImage();
      }
    }
  }

  handleYGesture() {
    if (this.touchendY < this.touchstartY && Math.abs(this.touchstartY - this.touchendY) > 20) {
      // swipe top
      this.isFullSizeSheet = true;
    } else if (
      this.touchendY > this.touchstartY &&
      Math.abs(this.touchendY - this.touchstartY) > 20
    ) {
      // swipe down
      if (!this.isFullSizeSheet) {
        this.closeBottomSheet();
      }
      this.isFullSizeSheet = false;
    }
    this.cdr.detectChanges();
  }

  downloadFile(message) {
    if (this.platformService.isCapacitor || this.platformService.isTauri) {
      this.downloadService.downloadFile(message.fileData);
    } else {
      this.fullImagePreviewService.dataRoomDownloadFiles({
        ...message.fileData,
        fromDataRoom: false,
      });
    }
  }

  private subscribeToTouchObservables() {
    this.touchStart$
      .pipe(takeUntil(this.destroy$), takeUntil(this.touchEnd$), takeUntil(this.touchMove$))
      .subscribe((res: any) => {
        const index = res?.path?.reverse()[this.chatMessages.length]?.classList[1]?.split('-')[1];
        this.touchedMessage = this.chatMessages[index];

        if (this.touchedMessage?.fileData && !this.touchedMessage?.isDownloadingFile) {
          this.openBottomSheet();
        }
      });
  }

  onMessageForward(message) {
    const modalRef = this.modalsService.open(ChatForwardModalComponent, {
      size: this.platform === 'web' ? 'lg' : 'sm',
      centered: true,
      windowClass: this.platform !== 'web' ? 'invite-modal-mobile' : 'invite-modal',
    });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.mentionChatMembers = this.mentionChatMembers;
    modalRef.componentInstance.tz = this.currTzAbbr;
  }

  openThreadSidebar(message) {
    this.openThread.emit(message);
  }

  /**
   * Opens bottom sheet component
   */
  openBottomSheet() {
    this.isBottomSheetOpened = true;
    this.checkPinMessage();
    this.cdr.detectChanges();
    this.renderer.appendChild(document.body, this.bottomSheetRef.nativeElement);
  }

  /**
   * Close bottom sheet component
   */
  closeBottomSheet() {
    this.isBottomSheetOpened = false;
    this.touchedMessage = null;
    this.options = null;
    this.cdr.detectChanges();
  }

  toggleBottomSheetSize() {
    this.isFullSizeSheet = !this.isFullSizeSheet;
  }

  downloadTouchedFile(message) {
    this.closeBottomSheet();
    this.downloadFile(message);
  }

  trackByMessages(index, item) {
    return item._id;
  }

  getCheckOffline(message): boolean {
    return this.isOffline && !message._id;
  }

  stopEditMessage() {
    this.editingMessage = null;
    this.recheckScroll.emit();
    this.editingMessageElement = null;
  }

  onMessageEditStart(data) {
    const { message, passedElement } = data;

    this.editingMessageElement = passedElement;

    this.editingMessage = { ...message };
    this.cdr.detectChanges();
    this.handleScrollWhenEditMessagesWithAttachedData();
  }

  onMessageEditSubmit(data) {
    const { message, isThread } = data;
    const text = message.text.trim();

    if (text !== '') {
      const socket = this.socketsService.get();
      const socketActionType = !isThread ? 'chats:update' : 'chats:thread:update';
      socket.emit(socketActionType, {
        _id: this.editingMessage._id,
        text: text,
      });
      this.stopEditMessage();
    }
  }

  onMessageDelete(deleteInfo: { message: Message; isThread: boolean }) {
    ConfirmAlert(this.translocoService.translate('alert.message-title'), {
      platform: this.platform,
    }).then(
      () => {
        const socket = this.socketsService.get();
        const socketActionType = !deleteInfo.isThread ? 'chats:delete' : 'chats:thread:delete';
        socket.emit(socketActionType, { id: deleteInfo.message._id });
        this.stopEditMessage();
        this.deletedThreadMessageId.emit(deleteInfo.message._id);
      },
      () => {},
    );
  }

  onPinMessage(message) {
    this.closeBottomSheet();
    this.isLoadingPin = true;

    this.store
      .dispatch(
        new ChatsCreatePin({
          id: this.chatId,
          body: {
            linkDocument: 'messages',
            linkDocumentId: message._id,
            object: this.object,
            objectId: this.objectId,
          },
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.isLoadingPin = false;
        },
        () => {
          this.isLoadingPin = false;
        },
      );
  }

  onUnPinMessage(message) {
    this.closeBottomSheet();

    const id = this.pinnedMessage.find(
      (msg: any) => msg.linkDocument && msg.linkMessage?._id === message._id,
    )?._id;
    this.isLoadingPin = true;

    this.store
      .dispatch(
        new ChatsDeletePin({
          id: this.chatId,
          pinnedMessageId: id,
          object: this.object,
          objectId: this.objectId,
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.isLoadingPin = false;
        },
        () => {
          this.isLoadingPin = false;
        },
      );
  }

  checkPinMessage() {
    const pinMessage = this.getPinnedMessage(this.options.message);

    if (pinMessage) {
      this.canUnpinMessage = this.canDeletePin(pinMessage);
    }
  }

  getParticularMessage() {
    return this.allImageMessages.findIndex((item) => item._id === this.imagePreviewMessage._id);
  }

  getPinnedMessage(message) {
    return this.store
      .selectSnapshot(ChatsState.getPinMessages)
      .find((pin: any) => pin.linkDocument && pin.linkMessage?._id === message._id);
  }

  openImagePreview(message, isThread = false) {
    this.imagePreviewMessage = message;
    const currentImageIndex = isThread ? 0 : this.getParticularMessage();

    if (isThread) {
      this.isFirstPreviewImage = true;
      this.isLastPreviewImage = true;
    } else {
      this.invisibleImagePreviewArrows(currentImageIndex);
    }

    this.showModalPreview();
  }

  updateModal() {
    this.updateEvent.next({
      imagePreviewMessage: this.imagePreviewMessage,
      isLastPreviewImage: this.isLastPreviewImage,
      isFirstPreviewImage: this.isFirstPreviewImage,
    });
  }

  showModalPreview(): void {
    this.mediaPreviewModal = this.modalsService.open(PreviewMediaComponent, {
      size: 'xl',
      windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
      centered: true,
    });

    const fileData =
      this.isThread && this.imagePreviewMessage?.chatId
        ? {
            ...this.imagePreviewMessage?.fileData,
            chatId: this.imagePreviewMessage?.chatId,
          }
        : this.imagePreviewMessage.fileData;

    this.mediaPreviewModal.componentInstance.previewData = {
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
      isMobile: this.isMobile,
      currentMedia: fileData,
    };

    this.mediaPreviewModal.componentInstance.isNotDataRoom = true;
    this.mediaPreviewModal.componentInstance.pinnedType = this.isThread
      ? PinType.ThreadsFile
      : PinType.ChatFile;

    const mediaFiles = this.store.selectSnapshot(ChatsState.getCurrentFileChat);

    if (this.checkDirectMessage) {
      this.mediaPreviewModal.componentInstance.isDirect = true;
    }

    const data: {
      chatId?: string;
      threadId?: string;
      mainMessage?: string;
      chatFiles?: any;
    } = {};

    if (
      this.mainMessage?.fileData &&
      this.checkIsMedia(this.mainMessage.fileData.originalFileName)
    ) {
      data.mainMessage = {
        ...this.mainMessage?.fileData,
        chatId: this.mainMessage.chatId,
      };
    }

    if (this.isThread && this.threadId) {
      data.threadId = this.threadId || this.mainMessage?.threadId;
    } else {
      data.chatId = this.chatId;
    }

    data.chatFiles = this.chatMessages
      .filter((message) => message.fileData && this.checkIsMedia(message.fileData.originalFileName))
      .map((msg) => msg.fileData);

    this.store
      .dispatch(new ChatsGetFile(data))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.mediaPreviewModal.componentInstance.media = this.handleMediaFile();
      });

    this.minimizeService.minimizeInit(this.mediaPreviewModal, {
      width: '360px',
      height: '307px',
      right: '40px',
      borderRadius: '10px',
    });

    this.mediaPreviewModal.result.then(() => this.clearModal()).catch(() => this.clearModal());
  }

  clearModal() {
    this.mediaPreviewModal = null;
  }

  goToNextImage() {
    const currentImageIndex = this.getParticularMessage();

    if (this.platform !== 'mobile') {
      this.invisibleImagePreviewArrows(currentImageIndex + 1);
    }

    if (this.platform === 'mobile' && this.fullImagePreviewService.isZoomedImage()) {
      return;
    }

    this.imagePreviewMessage = {
      ...this.allImageMessages[currentImageIndex + 1],
    };

    this.updateModal();
    this.fullImagePreviewService.onResetOptions();
    this.cdr.detectChanges();
  }

  goToPreviousImage() {
    const currentImageIndex = this.getParticularMessage();

    if (this.platform !== 'mobile') {
      this.invisibleImagePreviewArrows(currentImageIndex - 1);
    }

    if (this.platform === 'mobile' && this.fullImagePreviewService.isZoomedImage()) {
      return;
    }

    this.imagePreviewMessage = {
      ...this.allImageMessages[currentImageIndex - 1],
    };

    this.updateModal();
    this.fullImagePreviewService.onResetOptions();
    this.cdr.detectChanges();
  }

  invisibleImagePreviewArrows(imageIndex) {
    this.isFirstPreviewImage = imageIndex === 0;
    this.isLastPreviewImage = imageIndex === this.allImageMessages.length - 1;
  }

  handleMediaFile(fileData?: any) {
    const media: Array<any> = this.store.selectSnapshot(
      this.isThread ? ChatsState.getThreadFileChat : ChatsState.getCurrentFileChat,
    );
    const modifiedMedia = [...media]; // Create a new array with the same elements as `media`
    if (fileData) {
      modifiedMedia.push(fileData);
    }

    if (modifiedMedia.length > 0) {
      modifiedMedia[0] = { ...modifiedMedia[0], isFirstMedia: true };
      modifiedMedia[modifiedMedia.length - 1] = {
        ...modifiedMedia[modifiedMedia.length - 1],
        isLastMedia: true,
      };
    }

    return modifiedMedia;
  }

  populateMessages(messages) {
    const username = this.store.selectSnapshot(AuthState.getUser)?.userName;
    this.boardTickets = this.objectId
      ? this.store.selectSnapshot(BoardsState.getChatTicketsList)(this.objectId)
      : [];
    this.allImageMessages = [];

    return this.chatService.aggregateMessages(messages).map((item) => {
      item.chatTickets = this.boardTickets;
      item.aggregatedEmojis =
        item.aggregatedEmojis?.length > 0
          ? item.aggregatedEmojis
          : item.emojis
            ? this.emojisHelper.aggregateIdenticalEmojis(username, item.emojis)
            : [];

      if (item.threadsMessagesInfo) {
        item.lastReply = item.threadsMessagesInfo?.lastReply;
      }

      if (item.sharedMessage) {
        item.sharedMessage = this.addShowDetails(item.sharedMessage);
      }

      if (item.fileData && this.filesHelper.checkFileIsImage(item.fileData.originalFileName)) {
        this.allImageMessages.push(item);
      }

      return item;
    });
  }

  addShowDetails(message) {
    return { ...message, showDetails: true };
  }

  openDataRoomModal(message) {
    if (this.platform !== 'web') {
      this.closeBottomSheet();
    }

    this.selectedFileToSaveInDataRoom = message.fileData;
    this.selectedMessageType = this.isThread ? 'thread' : message.chatType;

    this.dataRoomModal = this.modalsService.open(this.dataRoomModalTemplate, {
      backdrop: 'static',
      windowClass: this.platform !== 'mobile' ? 'dataroom-modal' : '',
    });
  }

  closeDataRoomModal() {
    this.dataRoomModal.close();
    this.selectedFileToSaveInDataRoom = null;
    this.selectedMessageType = null;
  }

  getLastReplyText(date: Date): string {
    return moment(date, '', this.language).fromNow();
  }

  getImageUrlsFromHtml(string) {
    const imgRex = /<img [^>]*src=["|']([^"|']+)/gi;
    const images = [];
    let img;

    while ((img = imgRex.exec(string))) {
      images.push(img[1]);
    }
    return images;
  }

  convertMessage(messageInfo: {
    action: ConversionActionType;
    messageId: string;
    chatType: string;
    text: string;
    attachment: any;
  }): void {
    const attachment = messageInfo.attachment;
    let description = '';
    if (attachment) {
      description += this.filesHelper.checkFileIsImage(attachment.fileName)
        ? `<img src="${attachment.url}" alt="Attachment"><br>`
        : `<a href="${attachment.url}">${attachment.fileName}</a><br>`;
    }

    switch (messageInfo.action) {
      case ConversionActionType.Ticket:
        const imagesUrls = this.getImageUrlsFromHtml(messageInfo.text);
        if (imagesUrls.length > 0) {
          for (let i = 0; i < imagesUrls.length; i++) {
            description += `<img src="${imagesUrls[i]}" alt="Attachment ${i}"><br>`;
          }
        }
        const attachmentToTicket = [];

        //TODO: add attachment to ticket after backend refactor
        // if(attachment) {
        //   attachmentToTicket.push(attachment);
        // }
        const ticketModalRef = this.modalsService.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          scrollable: true,
          keyboard: false,
          beforeDismiss: () => ticketModalRef.componentInstance.closeImagePreview(true),
        });
        ticketModalRef.componentInstance.ticketData = {
          object: this.object,
          objectId: this.objectId,
          chatMessageId: messageInfo.messageId,
          chatType: messageInfo.chatType,
          fileData: attachmentToTicket,
          data: {
            title: messageInfo.text?.replace(/(<([^>]+)>)/gi, ''),
            description,
          },
          ticketCreator: this.user._id,
          showToastMessage: true,
          isNeedToSelectObject: true,
          isConvert: true,
        };
        this.minimizeService.minimizeInit(ticketModalRef);
        break;

      case ConversionActionType.Event:
        const eventModalRef = this.modalsService.open(CalendarEventModalComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
        });
        eventModalRef.componentInstance.modalData = {
          object: this.object,
          objectId: this.objectId,
          action: 'Add new event',
          displayName: this.translocoService.translate('calendar.add-new-event'),
          chatMessageId: messageInfo.messageId,
          event: {
            title: messageInfo.text?.replace(/(<([^>]+)>)/gi, ''),
            repeat: 'never',
            reminder: 'without',
            object: this.object,
            start: startOfDay(new Date()),
            end: endOfDay(new Date()),
            spaceId: this.object === 'spaces' ? this.objectId : undefined,
            projectId: this.object === 'projects' ? this.objectId : undefined,
            allDay: false,
            workdays: false,
            description,
          },
        };
        break;

      case ConversionActionType.Note:
        const noteModalRef = this.modalsService.open(NoteModalComponent, {
          size: 'lg',
          backdrop: 'static',
        });
        noteModalRef.componentInstance.modalData = {
          action: this.translocoService.translate('notes.add-new'),
          object: 'users',
          chatMessageId: messageInfo.messageId,
          note: {
            _id: null,
            title: messageInfo.text?.replace(/(<([^>]+)>)/gi, ''),
            created_at: null,
            text: description,
          },
        };
        break;
    }
  }

  emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) =>
    this.emojiPickerImage;

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  openMessageReaction(data) {
    const { message, messageIndex, quickReaction, passedElement } = data;

    let element = passedElement;
    let elementTop, elementOffset;

    this.messageReactionIsOpen = !this.messageReactionIsOpen;

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    if (quickReaction) {
      element = document.getElementById(
        `add-quick-reaction-${this.isThread ? 'thread-' : ''}${messageIndex}`,
      );
    }

    elementOffset = this.getOffset(element);
    elementTop = elementOffset.top.toFixed(0) - 60;

    if (vh - elementTop < 400) {
      elementTop -= 270;
    }

    this.store.dispatch(
      new ChatsEmojiPicker({
        emojiPickerIsOpen: this.messageReactionIsOpen,
        isChatEmojiReaction: !this.isThread,
        isThreadEmojiReaction: this.isThread,
        left: elementOffset.left.toFixed(0),
        top: elementTop,
        chatInfo: {
          message,
          object: this.object,
          objectId: this.objectId,
        },
      }),
    );
  }

  closeEmojiReactions() {
    this.messageReactionIsOpen = false;
    this.store.dispatch(
      new ChatsEmojiPicker({
        emojiPickerIsOpen: false,
        isChatEmojiReaction: true,
      }),
    );
  }

  openQuickReaction(e, message, messageIndex) {
    e.preventDefault();
    e.stopPropagation();
    this.openMessageReaction({ message, messageIndex, quickReaction: true });
  }

  emojiClicked(emoji, message) {
    if (!this.isCurrentUserReaction(emoji)) {
      this.chatService.isReactionProcess = true;
      this.store.dispatch(
        new EmojiCreate({
          body: {
            object: this.object || 'users',
            objectId: this.objectId || this.user._id,
            messageObject: emoji.messageObject,
            messageObjectId: message._id,
            emojiName: emoji.emojiName,
          },
        }),
      );
    } else {
      this.store.dispatch(
        new EmojiDelete({
          emojiId: message.emojis
            .filter(
              (item) => item.emojiName === emoji.emojiName && item.userName === this.user.userName,
            )
            .pop()._id,
        }),
      );
    }
  }

  getEmojiUsers(emoji) {
    const currentUserIndex = emoji.users.indexOf(this.user.userName);

    let users = [...emoji.users];

    if (currentUserIndex !== -1) {
      if (emoji.users.length === 1) {
        users[currentUserIndex] = 'You (click to remove)';
      } else {
        users = [...users.filter((_, i) => i !== currentUserIndex), 'You'];
      }
    }

    return users.join(', ');
  }

  isCurrentUserReaction(emoji) {
    return emoji.users.includes(this.user.userName);
  }

  openActionsSheet({ message, messageIndex }) {
    this.options = {
      title: '-',
      theme: this.config?.layout.variant,
      message,
      messageIndex,
    };

    this.openBottomSheet();
  }

  _convertMessage(action: ConversionActionType, message: any): void {
    this.closeBottomSheet();
    this.convertMessage({
      action,
      messageId: message._id,
      chatType: message.chatType,
      text: message.text,
      attachment: message.fileData ? message.fileData : null,
    });
  }

  _emojiClicked(emojiName, message) {
    const emoji = {
      emojiName,
      isCurrentUserReaction: true,
      messageObject: `${this.isThread ? 'thread' : 'chat'}-messages`,
      users: [
        ...(message.emojis?.length
          ? message.emojis
              .map((item) => {
                if (item.emojiName === emojiName) {
                  return item.userName;
                }
              })
              ?.filter((item) => item !== undefined)
          : []),
      ],
    };

    this.closeBottomSheet();
    this.emojiClicked(emoji, message);
  }

  _openMessageReaction(e, message: any, messageIndex: number): void {
    e.preventDefault();
    e.stopPropagation();

    const element = document.getElementById(
      `msg-actions${this.isThread ? '-thread' : ''}-${messageIndex}`,
    );

    this.closeBottomSheet();
    this.openMessageReaction({
      message,
      messageIndex,
      quickReaction: false,
      passedElement: element,
    });
  }

  _onMessageEditStart(message, index): void {
    const element = document.getElementById(
      `edit-${this.isThread ? 'thread' : 'chat'}-message-${index}`,
    );
    const test = document.getElementById(`msg-${message._id}`);

    this.closeBottomSheet();

    // Autofocus for mobile keyboard
    setTimeout(() => {
      test?.scrollIntoView({ block: 'center' });
    }, 600);

    this.onMessageEditStart({ message, passedElement: element });
  }

  _onMessageDelete(message, isThread): void {
    this.closeBottomSheet();
    this.onMessageDelete({ message, isThread });
  }

  _downloadFile(message: any): void {
    this.closeBottomSheet();
    this.downloadFile(message);
  }

  _openDataRoomModal(message: any): void {
    this.closeBottomSheet();
    this.openDataRoomModal(message);
  }

  _onMessageForward(message: any): void {
    this.closeBottomSheet();
    this.onMessageForward(message);
  }

  _openThreadSidebar(message: any): void {
    this.closeBottomSheet();
    this.openThreadSidebar(message);
  }

  copyMessageToClipboard(message): void {
    const element = document.getElementById(`message-text-${message._id}`);
    if (this.platform !== 'web') {
      this.copyTextToClipboard.emit(element.innerText);
    } else {
      this.htmlHelper.copyValueToBuffer(element.innerText);
    }
    this.closeBottomSheet();
    this.toastr.success(
      'Text has been successfully copied.',
      this.translocoService.translate('toastr.title-success'),
    );
  }

  hasPermissionToTheBoard(message: any): boolean {
    const { linkObject, linkObjectData } = message;

    let hasPermission = true;
    if (linkObject === 'tickets' && linkObjectData?.object === 'spaces') {
      hasPermission = this.spaces.some((space) => space._id === linkObjectData?.objectId);
    } else if (linkObject === 'tickets' && linkObjectData?.object === 'projects') {
      hasPermission = this.projects.some((project) => project._id === linkObjectData?.objectId);
    }

    return hasPermission;
  }

  hasPermissionToTicket(ticket: any): boolean {
    let hasPermission = true;

    if (ticket.object === 'spaces') {
      hasPermission = this.spaces.some((space) => space._id === ticket.objectId);
    } else if (ticket.object === 'projects') {
      hasPermission = this.projects.some((project) => project._id === ticket.objectId);
    }

    return hasPermission;
  }

  canDeletePin(item): boolean {
    const userId = this.localStorage.get('userId') || '';
    const chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;
    const allUser = this.store.selectSnapshot(ChatsState.getChatMembers)(chatId);
    const currentUser = allUser?.find((user) => user.userId === userId);

    return (
      userId === item.creatorId ||
      currentUser?.roleName?.includes(
        RolesTypeEnum.Owner || RolesTypeEnum.SpaceLeader || RolesTypeEnum.ProjectLeader,
      )
    );
  }

  public isImageMessage(message: any) {
    return (
      message?.fileData && this.filesHelper.checkFileIsImage(message.fileData.originalFileName)
    );
  }

  public isAudioMessage(message: any) {
    return (
      message?.fileData && this.filesHelper.checkFileIsAudio(message.fileData.originalFileName)
    );
  }

  public isVideoMessage(message: any) {
    return (
      message?.fileData &&
      this.filesHelper.checkFileIsPlayableVideoInWeb(message.fileData.originalFileName)
    );
  }

  public isVideoOrDocMessage(message: any) {
    return (
      message?.fileData && this.filesHelper.checkFileIsVideoOrDoc(message.fileData.originalFileName)
    );
  }

  setIsDraggingFile(isDragging: boolean): void {
    this.isDraggingFile = isDragging;
  }

  fileDropped(files: FileList): void {
    if (files?.length) {
      this.uploadFileService.setFiles(Array.from(files));
    }
  }

  handleScrollWhenEditMessagesWithAttachedData() {
    const { threadsMessagesInfo, linkObject, fileData, ticketsUrlData, wikiPagesUrlData } =
      this.editingMessage || {};
    if (
      !threadsMessagesInfo &&
      !linkObject &&
      !fileData &&
      !ticketsUrlData?.length &&
      !wikiPagesUrlData?.length
    ) {
      return;
    }

    setTimeout(() => {
      this.messageEdit?.nativeElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 0);
  }

  // scrollToMessage(id: string) {
  //   // Sprawdź, czy wiadomość o podanym id istnieje
  //   const element = document.getElementById(id);
  //   if (element) {
  //     this.scrollbar.scrollTo({
  //       top: element.offsetTop,
  //       behavior: 'smooth'
  //     });
  //   }
  // }
}
