import { Injectable, NgZone } from '@angular/core';
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import LinkBlot from './LinkBlot';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlChangeModalComponent } from '../../../modals/url-change-modal/url-change-modal.component';
import QuillMarkdown from 'quilljs-markdown';
import ImageResize from 'quill-image-resize';
import QuillTable from 'quill-table';

@Injectable({
  providedIn: 'root',
})
export class QuillInitializeService {
  static modalService: NgbModal;

  constructor(
    private modalService: NgbModal,
    private ngZone: NgZone,
  ) {
    LinkBlot.ngZone = ngZone;
    LinkBlot.modalService = modalService;
    QuillInitializeService.modalService = modalService;
    Quill.register('formats/link', LinkBlot, true);
    Quill.register('modules/magicUrl', MagicUrl);
    Quill.register('modules/QuillMarkdown', QuillMarkdown, true);
    Quill.register('modules/imageResize', ImageResize);
    Quill.register('modules/table', QuillTable);

    Quill.register(QuillTable.TableCell);
    Quill.register(QuillTable.TableRow);
    Quill.register(QuillTable.Table);
    Quill.register(QuillTable.Contain);
    Quill.register('modules/table', QuillTable.TableModule);
  }

  static quillToolbarLinkHandler({ editorInstance }) {
    editorInstance.focus();
    const range = editorInstance.getSelection();
    const modalRef = LinkBlot.modalService.open(UrlChangeModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.titleData.setValue(
      editorInstance.getText(range.index, range.length),
    );

    modalRef.result
      .then((result) => {
        const { index } = editorInstance.getSelection(true);
        const nextIndex = index + (result.title?.length || 0);
        if (range.length === 0) {
          editorInstance.insertText(index, result.title);
          editorInstance.insertText(nextIndex, ' ');
          editorInstance.setSelection(index, result.title?.length);
        }
        editorInstance.format('link', { url: result.url, target: '_blank' }, Quill.sources.USER);
        editorInstance.setSelection(nextIndex + 1, 0);
      })
      .catch(() => {});
  }

  static quillBackspaceFix = function (range, context) {
    if (range.index === 0 && range.length === 0) {
      return;
    }
    if (range.length === 0) {
      const size = context?.format?.link ? context.prefix.length : 1;
      this.quill.deleteText(range.index - size, size, Quill.sources.USER);
    } else {
      this.quill.deleteText(range, Quill.sources.USER);
    }
  };

  static processDotKeyChange = function (range: any, ctx: any) {
    this.quill.deleteText(range.index - ctx.offset, ctx.offset);
    const selection = this.quill.getSelection(true);
    const listIndent = '      ';
    const specialCharacter = '․';
    this.quill.insertText(selection, `${listIndent}${ctx.prefix.trim()}${specialCharacter}`);

    return false;
  };

  static handleEditorCreated(editorInstance): void {
    editorInstance.keyboard.addBinding(
      {
        collapsed: true,
        format: { list: false },
        key: 190, // dot key
        prefix: /^\s*?((?!(?:1)$)\d+)$/, // all numbers except '1'
      },
      QuillInitializeService.processDotKeyChange,
    );

    editorInstance.keyboard.addBinding(
      {
        collapsed: true,
        format: { list: false },
        key: 110, // dot key
        prefix: /^\s*?((?!(?:1)$)\d+)$/, // all numbers except '1'
      },
      QuillInitializeService.processDotKeyChange,
    );

    editorInstance.keyboard.addBinding(
      {
        format: ['link'],
        key: 'backspace',
      },
      QuillInitializeService.quillBackspaceFix,
    );

    const toolbar = editorInstance.getModule('toolbar');

    toolbar?.addHandler('link', () => {
      console.log('link handler');
      QuillInitializeService.quillToolbarLinkHandler({ editorInstance });
    });
  }
}
