import { NgModule, forwardRef } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoModule } from '@ngneat/transloco';
import { CalendarDateFormatter, CalendarModule, DateAdapter, MOMENT } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MentionModule } from 'angular-mentions';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { AngularSplitModule } from 'angular-split';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChartistModule } from 'ng-chartist';
import { FileUploadModule } from 'ng2-file-upload';
import { DragulaModule } from 'ng2-dragula';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LinkyModule } from 'ngx-linky';
import { QuillModule } from 'ngx-quill';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import {
  VgCoreModule,
  VgControlsModule,
  VgOverlayPlayModule,
  VgBufferingModule,
} from 'ngx-videogular';
import moment from 'moment-timezone';

import { QuillConfig } from './data/quill-configuration';

import { PipeModule } from './pipes/pipe.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

// DIRECTIVES
import { DragDropDirective } from './directives/drag-drop.directive';
import { FocusDirective } from './directives/focus.directive';
import { ExternalLinkDirective } from './directives/external-link.directive';
import { ControlledInputDirective } from './directives/controlled-input.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';

// COMPONENTS
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { SubNavbarComponent } from './components/sub-navbar/sub-navbar.component';
import { ChatGroupMembersModalComponent } from '../modals/chat-group-members/chat-group-members.component';
import { ActionLogsComponent } from './components/action-logs/action-logs.component';
import { NotificationsComponent } from '../standalone/components/notifications/notifications.component';
import { SpaceProjectsComponent } from './components/space-projects/space-projects.component';
import { ChatComponent } from './components/chat/chat.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NotesComponent } from './components/notes/notes.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { AccessControlDocumentsComponent } from './components/access-documents/access-control-documents.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { CustomCalendarDateFormatter } from './utils/custom-calendar-date-formatter';
import { MembersInviteComponent } from './components/members-invite/members-invite.component';
import { AvatarComponent } from '../standalone/components/avatar/avatar.component';
import { DataroomLocationComponent } from './components/dataroom-location/dataroom-location.component';
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';

import { ListenersComponent } from './components/listeners.component';
import { ChatMenuComponent } from './components/chat/chat-navigation/chat-menu.component';
import { ChatNavigationComponent } from './components/chat/chat-navigation/chat-navigation.component';
import { ChatUsersListComponent } from './components/chat/chat-users-list/chat-users-list.component';
import { ChatMessagesComponent } from './components/chat/chat-messages/chat-messages.component';
import { ChatImagePreviewComponent } from './components/chat/chat-image-preview/chat-image-preview.component';
import { NgbDateFrParserFormatter } from './utils/ngb-date-fr-parser-formatter';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { CustomSpinnerComponent } from './components/custom-spinner/custom-spinner.component';

import { ChatForwardModalComponent } from './components/chat/chat-forward-modal/chat-forward-modal.component';
import { ChatMessageContentComponent } from './components/chat/chat-message-content/chat-message-content.component';
import {
  TextMessageComponent,
  AudioMessageComponent,
  GenericMessageComponent,
  ImageMessageComponent,
  DocMessageComponent,
  VideoMessageComponent,
} from './components/chat/chat-message-content';
import { ChatThreadComponent } from './components/chat/chat-thread/chat-thread.component';
import { ChatThreadsListComponent } from './components/chat/chat-threads-list/chat-threads-list.component';
import { ChatAppFormComponent } from './components/chat/chat-app-form/chat-app-form.component';
import { ThreadAppFormComponent } from './components/chat/thread-app-form/thread-app-form.component';
import { UserBoardComponent } from './components/board/user-board/user-board.component';
import { BoardActionBarComponent } from './components/board/board-actionbar/board-actionbar.component';
import { MessageActionsButtonsComponent } from './components/chat/chat-messages/message-actions-buttons/message-actions-buttons.component';
import { ProjectAvatarComponent } from './components/space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from './components/space-projects/space-avatar/space-avatar.component';
import { NotepadComponent } from './components/notepad/notepad.component';
import { MessageEditComponent } from './components/chat/chat-messages/message-edit/message-edit.component';
import { LinkedObjectBadgeComponent } from './components/linked-object-badge/linked-object-badge.component';
import { ChatMessageDetailsComponent } from './components/chat/chat-message-details/chat-message-details.component';
import { SortingToggleComponent } from './components/sorting-toggle/sorting-toggle.component';
import { ChatSearchComponent } from './components/chat/chat-search/chat-search.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { SvgComponent } from './svgs/svg/svg.component';
import { GroupAvatarComponent } from './components/space-projects/group-avatar/group-avatar.component';
import { WebhookAvatarComponent } from './components/chat/chat-messages/webhook-avatar/webhook-avatar.component';
import { WebhooksListComponent } from './components/webhooks-list/webhooks-list.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { WebhookCreateUpdateModalComponent } from '../modals/webhook-create-update/webhook-create-update-modal.component';
import { AvatarImageShowEditComponent } from './components/avatar-image-show-edit/avatar-image-show-edit.component';
import { WebhookMessageComponent } from './components/chat/chat-message-content/webhook-message/webhook-message.component';
import { WebhookGithubMessageComponent } from './components/chat/chat-message-content/webhook-message/webhook-github-message/webhook-github-message.component';
import { WebhookGitlabMessageComponent } from './components/chat/chat-message-content/webhook-message/webhook-gitlab-message/webhook-gitlab-message.component';
import { WebhookBitbucketMessageComponent } from './components/chat/chat-message-content/webhook-message/webhook-bitbucket-message/webhook-bitbucket-message.component';
import { WebhookPushMessageComponent } from './components/chat/chat-message-content/webhook-message/webhook-push-message/webhook-push-message.component';
import { WebhookPRMessageComponent } from './components/chat/chat-message-content/webhook-message/webhook-pr-message/webhook-pr-message.component';
import { BoardLaneComponent } from './components/board-lane/board-lane.component';
import { ColumnComponent } from './components/board-lane/components/column/column.component';
import { CardComponent } from './components/board-lane/components/card/card.component';
import { WebhookJobStatusComponent } from './components/chat/chat-message-content/webhook-message/webhook-job-status/webhook-job-status.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ChatIntegrationsModalComponent } from '../modals/chat-integrations/chat-integrations.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SubTabsComponent } from './components/sub-tabs/sub-tabs.component';
import { EmptyStateSpaceComponent } from '../pages/full-pages/manage-spaces/components/empty-state-space/empty-state-space.component';
import { TableComponent } from './components/table/table.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { ChecklistItemComponent } from './components/checklist/checklist-item/checklist-item.component';
import { TicketColoringComponent } from './components/ticket-coloring/ticket-coloring.component';
import { CustomizeTicketFieldsComponent } from './components/customize-ticket-fields/customize-ticket-fields.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { MessagePollComponent } from './components/chat/chat-messages/message-poll/message-poll.component';
import { ChatPinnedMessagesComponent } from './components/chat/chat-pinned-messages/chat-pinned-messages.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChatPinnedMessageComponent } from './components/chat/chat-pinned-messages/components/chat-pinned-message/chat-pinned-message.component';
import { ChatPinnedItemComponent } from './components/chat/chat-pinned-messages/components/chat-pinned-item/chat-pinned-item.component';
import { PinContextMenuComponent } from './components/chat/chat-pinned-messages/components/pin-context-menu/pin-context-menu.component';
import { DataRoomHeaderComponent } from './components/documents/components/data-room-header/data-room-header.component';
import { ContainerRefDirective } from './directives/container-ref.directive';
import { DragDropViewComponent } from './components/documents/drag-drop-view/drag-drop-view.component';
import { DraftsComponent } from './components/drafts/drafts.component';
import { DraftComponent } from './components/drafts/draft/draft.component';
import { DataRoomGridComponent } from './components/documents/components/data-room-grid/data-room-grid.component';
import { DataRoomFileGridComponent } from './components/documents/components/data-room-file-grid/data-room-file-grid.component';
import { ClickDirective } from './directives/double-click.directive';
import { MessageFilesComponent } from './components/chat/chat-messages/message-files/message-files.component';
import { KanbanBoardTicketComponent } from '../pages/full-pages/board/kanban-board/components/kanban-board-ticket/kanban-board-ticket.component';
import { ImgDirective } from './directives/img.directive';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { EditorComponent } from './components/editor/editor.component';
import { EmptyContentComponent } from './components/empty-content/empty-content.component';
import { RxFor } from '@rx-angular/template/for';
import { TippyDirective } from './directives/tippy.directive';

// UI ELEMENTS
import { ButtonComponent } from './components/ui-elements/button/button.component';
import { InputTextComponent } from './components/ui-elements/Fields/input-text/input-text.component';
import { InputSelectComponent } from './components/ui-elements/Fields/input-select/input-select.component';
import { InputTextareaComponent } from './components/ui-elements/Fields/input-textarea/input-textarea.component';
import { UserNameAlonePipe } from '../standalone/pipes/user-name.pipe';
import { TmpTooltipDirective } from '../standalone/components/tmp-tooltip/tmp-tooltip.derective';
import { TmpTooltipContainerComponent } from '../standalone/components/tmp-tooltip/tmp-tooltip.component';

@NgModule({
  exports: [
    ActionLogsComponent,
    EmptyContentComponent,
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SubNavbarComponent,
    ChatGroupMembersModalComponent,
    DateTimePickerComponent,
    SpaceProjectsComponent,
    ChatComponent,
    CalendarComponent,
    DashboardComponent,
    NotesComponent,
    DocumentsComponent,
    DataRoomHeaderComponent,
    DataRoomGridComponent,
    DataRoomFileGridComponent,
    AccessControlDocumentsComponent,
    UsersListComponent,
    ContainerRefDirective,
    FocusDirective,
    ClickDirective,
    ControlledInputDirective,
    ExternalLinkDirective,
    DebounceClickDirective,
    TextareaAutoresizeDirective,
    TmpTooltipDirective,
    ImgDirective,
    TippyDirective,
    NgbModule,
    LinkyModule,
    PipeModule,
    // HTMLEscapeUnescapeModule,
    ListenersComponent,
    ChatMenuComponent,
    ChatNavigationComponent,
    ChatUsersListComponent,
    ChatMessagesComponent,
    ChatImagePreviewComponent,
    ImageCropperComponent,
    ChatThreadComponent,
    CustomSpinnerComponent,
    DragDropDirective,
    ClickOutsideDirective,
    VideoPlayerComponent,
    BottomSheetComponent,
    MembersInviteComponent,
    AvatarComponent,
    DataroomLocationComponent,
    MediaPreviewComponent,
    PickerModule,
    UserBoardComponent,
    BoardActionBarComponent,
    ChatMessageContentComponent,
    ProjectAvatarComponent,
    ChatMessageContentComponent,
    SpaceAvatarComponent,
    NotepadComponent,
    MessageEditComponent,
    ChatThreadsListComponent,
    LinkedObjectBadgeComponent,
    ChatMessageDetailsComponent,
    ChatIntegrationsModalComponent,
    SortingToggleComponent,
    ProjectSettingsComponent,
    GroupAvatarComponent,
    WebhookAvatarComponent,
    WebhooksListComponent,
    IntegrationsComponent,
    WebhookCreateUpdateModalComponent,
    AvatarImageShowEditComponent,
    BoardLaneComponent,
    ColumnComponent,
    CardComponent,
    ProgressBarComponent,
    SubTabsComponent,
    EmptyStateSpaceComponent,
    TableComponent,
    ChecklistComponent,
    TicketColoringComponent,
    CustomizeTicketFieldsComponent,
    EmptyStateComponent,
    ChatPinnedMessagesComponent,
    ChatPinnedMessageComponent,
    ChatPinnedItemComponent,
    PinContextMenuComponent,
    DraftsComponent,
    DraftComponent,
    DragDropViewComponent,
    KanbanBoardTicketComponent,
    EditorComponent,
    // UI ELEMENTS
    ButtonComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputSelectComponent,
  ],
  imports: [
    UserNameAlonePipe,
    TmpTooltipDirective,
    TmpTooltipContainerComponent,
    FileUploadModule,
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    OverlayModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    SvgComponent,
    PipeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DragulaModule.forRoot(),
    NgbDatepickerModule,
    NgbTimepickerModule,
    ChartistModule,
    PickerModule,
    ImageCropperModule,
    EmojiModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    QuillModule.forRoot(QuillConfig),
    AutocompleteLibModule,
    LinkyModule,
    MentionModule,
    // HTMLEscapeUnescapeModule,
    ReactiveFormsModule,
    AngularSplitModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    PinchZoomModule,
    AngularSvgIconModule.forRoot(),
    TranslocoDirective,
    RxFor,
    NotificationsComponent,
    forwardRef(() => AvatarComponent),
    TranslocoModule,
    MessageActionsButtonsComponent,
    ContainerRefDirective,
    FocusDirective,
    ClickDirective,
    DragDropDirective,
    ClickOutsideDirective,
    ControlledInputDirective,
    ExternalLinkDirective,
    DebounceClickDirective,
    TextareaAutoresizeDirective,
    ImgDirective,
    TippyDirective,
    FooterComponent,
    NavbarComponent,
    SubNavbarComponent,
    ChatGroupMembersModalComponent,
    ActionLogsComponent,
    DateTimePickerComponent,
    SpaceProjectsComponent,
    ChatComponent,
    CalendarComponent,
    DashboardComponent,
    NotesComponent,
    DocumentsComponent,
    AccessControlDocumentsComponent,
    UsersListComponent,
    ListenersComponent,
    ChatMenuComponent,
    ChatNavigationComponent,
    ChatUsersListComponent,
    ChatMessagesComponent,
    ChatIntegrationsModalComponent,
    ChatImagePreviewComponent,
    ImageCropperComponent,
    CustomSpinnerComponent,
    VideoPlayerComponent,
    BottomSheetComponent,
    MembersInviteComponent,
    MediaPreviewComponent,
    ChatForwardModalComponent,
    ChatMessageContentComponent,
    TextMessageComponent,
    AudioMessageComponent,
    GenericMessageComponent,
    ImageMessageComponent,
    DocMessageComponent,
    VideoMessageComponent,
    ChatThreadComponent,
    ChatThreadsListComponent,
    ChatAppFormComponent,
    ThreadAppFormComponent,
    DataroomLocationComponent,
    UserBoardComponent,
    BoardActionBarComponent,
    ProjectAvatarComponent,
    SpaceAvatarComponent,
    NotepadComponent,
    MessageEditComponent,
    LinkedObjectBadgeComponent,
    ChatMessageDetailsComponent,
    SortingToggleComponent,
    ChatSearchComponent,
    ProjectSettingsComponent,
    GroupAvatarComponent,
    WebhookAvatarComponent,
    WebhooksListComponent,
    IntegrationsComponent,
    WebhookCreateUpdateModalComponent,
    AvatarImageShowEditComponent,
    WebhookMessageComponent,
    WebhookGithubMessageComponent,
    WebhookGitlabMessageComponent,
    WebhookBitbucketMessageComponent,
    WebhookPushMessageComponent,
    WebhookPRMessageComponent,
    WebhookJobStatusComponent,
    BoardLaneComponent,
    ColumnComponent,
    CardComponent,
    ProgressBarComponent,
    SubTabsComponent,
    EmptyStateSpaceComponent,
    TableComponent,
    ChecklistComponent,
    ChecklistItemComponent,
    TicketColoringComponent,
    CustomizeTicketFieldsComponent,
    EmptyStateComponent,
    MessagePollComponent,
    ChatMessageContentComponent,
    ChatPinnedMessagesComponent,
    ChatPinnedMessageComponent,
    ChatPinnedItemComponent,
    PinContextMenuComponent,
    DragDropViewComponent,
    DraftsComponent,
    DraftComponent,
    DataRoomHeaderComponent,
    DataRoomGridComponent,
    DataRoomFileGridComponent,
    MessageFilesComponent,
    KanbanBoardTicketComponent,
    EditorComponent,
    EmptyContentComponent,
    // UI ELEMENTS
    ButtonComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputSelectComponent,
  ],
  providers: [
    { provide: MOMENT, useValue: moment },
    { provide: CalendarDateFormatter, useClass: CustomCalendarDateFormatter },
    { provide: NgbDateParserFormatter, useClass: NgbDateFrParserFormatter },
    DatePipe,
  ],
})
export class SharedModule {}
