import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatePipe, NgIf, NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { LinkedEntityActionType } from '../chat/chat-messages/chat-messages.component';
import { OpenVideoCallMobile } from '../../store/actions/modals.action';
import { ConfigService } from '../../services/config.service';
import { AuthState } from '../../store/states/auth.state';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventModalComponent } from '../../../modals/calendar-event/calendar-event.component';
import { NoteModalComponent } from '../../../modals/note/note.component';
import { VideoCallModalComponent } from '../../../modals/video-call/video-call-modal.component';
import { VideoCallsState } from '../../store/states/video-calls.state';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { TicketsGetById } from '../../store/actions/board.action';
import { MinimizeService } from '../../services/minimize.service';
import { DraftService } from '../../services/draft.service';
import { ObjectEnum } from '../../enums/object.enum';
import { ModalService } from '../../services/modal.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { WikiService } from '../../../pages/full-pages/wiki/wiki.service';
import { WikiPagesService } from '../../../api/services/wiki-pages.service';
import { WikiPageDbDto } from '../../../api/models/wiki-page-db-dto';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

enum UrlType {
  Wiki = 'wiki',
  Ticket = 'ticket',
}

@Component({
  selector: 'app-linked-object-badge',
  templateUrl: './linked-object-badge.component.html',
  styleUrls: ['./linked-object-badge.component.scss'],
  standalone: true,
  imports: [NgIf, TranslocoDirective, NgClass],
})
export class LinkedObjectBadgeComponent implements OnInit, OnDestroy {
  @Input() object: string;
  @Input() urlReplace: string;
  @Input() data: any;
  @Input() objectId: string;
  @Input() message: any;
  @Input() platform = 'web';
  @Input() tz: string;
  @Input() withView = true;
  @Input() isCallLabel: boolean;
  @Input() disabledAction = false;
  config: any = {};
  linkedEntityName: any[] = [];
  destroy$: Subject<void> = new Subject<void>();
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private configService: ConfigService,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private modalService: NgbModal,
    private toast: ToastrService,
    protected routerTenantPipe: RouterTenantPipe,
    private router: Router,
    private wikiService: WikiService,
    private modalsService: ModalService,
    private wikiApiService: WikiPagesService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.setEntitiesNames();
    this.disabledAction = this.disabledAction || this.message?.linkObject === 'files';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get isWikiPage(): boolean {
    return this.urlReplace === UrlType.Wiki || this.message?.linkObject === 'wiki-pages';
  }

  private setEntitiesNames(): void {
    this.linkedEntityName[LinkedEntityActionType.Ticket] = 'ticket';
    this.linkedEntityName[LinkedEntityActionType.Event] = 'calendar event';
    this.linkedEntityName[LinkedEntityActionType.Note] = 'note';
    this.linkedEntityName[LinkedEntityActionType.LaneBoard] = 'notes board';
    this.linkedEntityName[LinkedEntityActionType.Estimation] = 'estimation session';
    this.linkedEntityName[LinkedEntityActionType.Wiki] = 'wiki-pages';
  }

  viewLinkedEntity(message: any): void {
    if (this.disabledAction) {
      return;
    }

    if (this.urlReplace) {
      switch (this.urlReplace) {
        case UrlType.Ticket:
          const draft = this.draftService.isHasActiveDraft(this.data._id);

          if (draft) {
            this.draftService.openTicketOrDraft(draft, {
              id: this.data._id,
              object: this.data.object,
              objectId: this.data.objectId,
            });
          } else {
            const ticketModalRef = this.modalService.open(BoardTicketModalComponent, {
              size: 'xl',
              backdrop: 'static',
              scrollable: true,
              keyboard: false,
              beforeDismiss: () => ticketModalRef.componentInstance.closeImagePreview(true),
            });
            ticketModalRef.componentInstance.ticketData = {
              id: this.data._id,
              object: this.data.object,
              objectId: this.data.objectId,
            };
            this.minimizeService.minimizeInit(ticketModalRef);
          }
          break;
        case UrlType.Wiki:
          this.object = this.object || this.data.object;
          this.objectId = this.objectId || this.data.objectId;

          this.wikiApiService
            .wikiPagesGetOne({
              id: this.data._id,
              object: this.object,
              objectId: this.objectId,
            })
            .pipe(
              tap(
                () => {
                  const object = this.data.object;
                  const objectId = this.data.objectId;
                  this.openWiki(this.data, object, objectId);
                },
                (error) => {
                  if (error.status === 404) {
                    const message = this.data?.parentId
                      ? this.translocoService.translate('toastr.message-wiki-page-deleted')
                      : this.translocoService.translate('toastr.message-wiki-document-deleted');
                    this.toast.error(
                      message,
                      this.translocoService.translate('toastr.title-error'),
                    );
                  }
                },
              ),
              takeUntil(this.destroy$),
            )
            .subscribe();
          break;
        default:
          break;
      }

      return;
    }

    switch (message?.linkObject) {
      case LinkedEntityActionType.Event:
        const eventModalRef = this.modalService.open(CalendarEventModalComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
        });

        eventModalRef.componentInstance.modalData = {
          object: message.linkObjectData.object,
          objectId: message.linkObjectData.objectId,
          action: 'View event',
          displayName: this.translocoService.translate('calendar.view-event'),
          stopChatReminder: true,
          event: {
            _id: message.linkObjectId,
          },
        };
        break;

      case LinkedEntityActionType.Ticket:
        const draft = this.draftService.isHasActiveDraft(message.linkObjectId);

        if (draft) {
          this.draftService.openTicketOrDraft(draft, {
            id: message.linkObjectId,
            object: message.linkObjectData.object,
            objectId: message.linkObjectData.objectId,
          });
        } else {
          const ticketModalRef = this.modalService.open(BoardTicketModalComponent, {
            size: 'xl',
            backdrop: 'static',
            scrollable: true,
            keyboard: false,
            beforeDismiss: () => ticketModalRef.componentInstance.closeImagePreview(true),
          });
          ticketModalRef.componentInstance.ticketData = {
            id: message.linkObjectId,
            object: message.linkObjectData.object,
            objectId: message.linkObjectData.objectId,
          };
          this.minimizeService.minimizeInit(ticketModalRef);
        }
        break;

      case LinkedEntityActionType.Files:
        this.modalsService.viewLinkedEntity(message, {
          object: this.object,
          objectId: this.objectId,
          platform: this.platform,
        });
        break;

      case LinkedEntityActionType.Note:
        const noteModalRef = this.modalService.open(NoteModalComponent, {
          size: 'lg',
          backdrop: 'static',
        });
        noteModalRef.componentInstance.modalData = {
          action: this.translocoService.translate('notes.view-note'),
          object: 'users',
          note: message.linkObjectData,
        };
        break;

      case LinkedEntityActionType.Estimation:
        const { object } = message;
        const transformedObject = object?.slice(0, object.length - 1);

        let ticketId = '';
        const boardAbbreviation = message.chatTickets[0]?.boardAbbreviation;
        const potentialTicket: Array<string> = Array.from(
          (message.text as any).matchAll(new RegExp(boardAbbreviation + '-[0-9]+', 'g')),
          (match) => match[0],
        )[0];
        const allTickets = message.chatTickets.map((item) => ({
          ticketId: item._id,
          key: item.boardAbbreviation + '-' + item.counter,
        }));

        if (potentialTicket) {
          ticketId = allTickets.find((ticket) => ticket.key.includes(potentialTicket))?.ticketId;
        }

        if (!ticketId) {
          ticketId = message.replaceValues.find((item) => item.path === 'ticketId')?.value;
        }

        this.store
          .dispatch(new TicketsGetById({ id: ticketId }))
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.router
              .navigate(
                [this.routerTenantPipe.transform(`/${transformedObject}/${this.objectId}/board`)],
                {
                  queryParams: {
                    ticket: ticketId,
                    estimation: true,
                  },
                },
              )
              .then();
          });
        break;

      case LinkedEntityActionType.Wiki:
        this.object = this.object || message.linkWikiPage?.object;
        this.objectId = this.objectId || message.linkWikiPage?.objectId;

        this.wikiApiService
          .wikiPagesGetOne({
            id: message.linkObjectId,
            object: this.object,
            objectId: this.objectId,
          })
          .pipe(
            tap(
              () => {
                // Check result
                const object = message.linkWikiPage.object;
                const objectId = message.linkWikiPage?.objectId;
                this.openWiki(message, object, objectId);
              },
              (error) => {
                if (error.status === 404) {
                  const message = this.data?.parentId
                    ? this.translocoService.translate('toastr.message-wiki-page-deleted')
                    : this.translocoService.translate('toastr.message-wiki-document-deleted');
                  this.toast.error(message, this.translocoService.translate('toastr.title-error'));
                }
              },
            ),
            takeUntil(this.destroy$),
          )
          .subscribe();
        break;

      case LinkedEntityActionType.LaneBoard:
        this.router.navigate([
          this.routerTenantPipe.transform(`/notes-board/${message.linkObjectId}/${this.objectId}`),
        ]);
        break;
    }
  }

  openWiki(message, object, objectId): void {
    const id = message.linkObjectId || message._id;

    if (this.platform !== 'web') {
      // Route
      const route = `${
        (object || this.object) === ObjectEnum.Project
          ? ObjectEnum.ProjectRoute
          : ObjectEnum.SpaceRoute
      }/${objectId || this.objectId}`;

      // Set data
      this.wikiService.object = object || this.object;
      this.wikiService.objectId = objectId || this.objectId;

      this.router
        .navigate([this.routerTenantPipe.transform(route)], {
          queryParams: { tab: 'tabWiki' },
        })
        .then(() => {
          this.wikiService.setActiveWiki({ _id: id }).pipe(untilDestroyed(this)).subscribe();
        });
    } else {
      // Route
      const route = `${
        (object || this.object) === ObjectEnum.Project
          ? ObjectEnum.ProjectRoute
          : ObjectEnum.SpaceRoute
      }/${objectId || this.objectId}/wiki/${id}`;

      this.router.navigate([this.routerTenantPipe.transform(route)]);
    }
  }

  getLinkEntity(message) {
    let text = this.withView ? 'View ' : '';
    if (!this.urlReplace) {
      switch (this.linkedEntityName[message?.linkObject]) {
        case 'ticket':
          text = this.translocoService.translate('chat.view-ticket', {
            value: ` <b>${message?.linkObjectData?.boardAbbreviation}-${message?.linkObjectData?.counter}</b>`,
          });
          break;
        case 'calendar event':
          text += `Event <b>${this.datePipe.transform(message?.linkObjectData?.start, 'dd.MM.yy HH:mm', this.tz)}</b>`;
          break;
        case 'note':
          text += `Note`;
          break;
        case 'notes board':
          text = this.translocoService.translate('common.btn-noteboard');
          break;
        case 'wiki-pages':
          text = this.translocoService.translate('chat.view-wiki', {
            value: message.linkWikiPage?.title
              ? `<b class="wiki-badge"> ${message.linkWikiPage?.title}</b>`
              : '',
          });
          break;
        case 'estimation session':
          text = 'Join Estimation';
          break;
        default:
          text += '';
      }

      if (message.linkObject === 'files') {
        text = 'View media';
      }
    } else if (this.urlReplace === UrlType.Ticket) {
      text = this.translocoService.translate('chat.view-ticket', {
        value: `<b>${this.data.boardAbbreviation}-${this.data.counter}</b>`,
      });
    } else if (this.urlReplace === UrlType.Wiki) {
      text = this.translocoService.translate('chat.view-wiki', {
        value: this.data?.title ? `<b class="wiki-badge"> ${this.data.title}</b>` : '',
      });
    }
    return text;
  }

  openVideoCall(roomId: string): void {
    if (!this.store.selectSnapshot(VideoCallsState.getIsOpened)) {
      const user = this.store.selectSnapshot(AuthState.getUser);
      if (this.platform !== 'web') {
        this.store.dispatch(new OpenVideoCallMobile(roomId));
      } else {
        const modalRef = this.modalService.open(VideoCallModalComponent, {
          windowClass: 'cropper-modal video-call-modal',
          backdrop: false,
        });
        modalRef.componentInstance.callData = { roomId, user };
      }
    } else {
      this.toast.info(
        this.translocoService.translate('toastr.you-on-another-call'),
        this.translocoService.translate('toastr.title-video-call-opened'),
      );
    }
  }
}
