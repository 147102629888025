import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  differenceInMinutes,
  startOfHour,
  startOfDay,
  endOfDay,
  isSameMonth,
  endOfWeek,
  startOfWeek,
  getMinutes,
  setMinutes,
} from 'date-fns';
import { combineLatest, merge, Subject, Subscription } from 'rxjs';
import { takeUntil, take, catchError, map, debounceTime, defaultIfEmpty } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import {
  CalendarEventTimesChangedEvent,
  CalendarUtils,
  CalendarView,
  CalendarCommonModule,
  CalendarMonthModule,
  CalendarWeekModule,
  CalendarDayModule,
} from 'angular-calendar';
import moment from 'moment-timezone';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { CalendarEvent, CalendarEventBasis, CalendarEventException } from './calendar-utils-custom';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { DateTimeHelper } from '../../utils/date-time-helper';
import { ConfirmAlert } from '../../alerts/alerts';
import { RouterQueryService } from '../../services/router-query.service';
import { SocketsService } from '../../services/sockets.service';
import { ConfigService } from '../../services/config.service';
import {
  CalendarEventGet,
  CalendarEventRemove,
  CalendarEventsCheckedCalendarsGet,
  CalendarEventsUpdate,
  CalendarEventsUpdateStatus,
  CalendarEventExceptions,
  CalendarEventsDataUpdate,
  CalendarEventsUpdateSingle,
  CalendarEventCreate,
  CalendarEventsGetById,
  CalendarEventsGet,
  CalendarEventsSet,
  CalendarEventsCheckedCalendarsUpdate,
  CalendarTicketsGet,
  CalendarTicketsSet,
} from '../../store/actions/calendar-events.action';
import { AuthState } from '../../store/states/auth.state';
import { CalendarEventsState } from '../../store/states/calendar-events.state';
import {
  CalendarEventModalComponent,
  CalendarRepeatTypes,
} from '../../../modals/calendar-event/calendar-event.component';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { environment } from '../../../../environments/environment';
import { MinimizeService } from '../../services/minimize.service';
import { ZoomService } from '../../services/zoom.service';
import { LocalStorageKeys } from '../../../types/local-storage-keys.enum';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgClass, NgIf, NgFor, NgStyle, NgSwitch, NgSwitchCase, DatePipe } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss', './calendar-ngdeep.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    NgIf,
    CalendarCommonModule,
    SvgComponent,
    FormsModule,
    NgSelectModule,
    NgFor,
    DragAndDropModule,
    DragulaModule,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    DatePipe,
  ],
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('scrollableContainer') scrollContainer: ElementRef; // Get scrollbar component reference
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() withSubNavbar = true;
  @Input() object: string;
  @Input() objectId: string;
  @Input() readOnly: boolean;
  @Input() calendarTab?: string;

  public readonly moment = moment;

  config: any = {};
  themeMode: string;
  platform = 'web';
  view: CalendarView | 'month' | 'week' | 'day' = 'week';

  destroy$ = new Subject<void>();
  socket: WrappedSocket;
  dragulaSub = new Subscription();
  notesContainer = 'note-dragula-container';
  eventRefresh: Subject<void> = new Subject();
  isLoaded = false;
  viewDate: Date = new Date();
  event: CalendarEvent;
  events: CalendarEvent[];
  filteredEvents: CalendarEvent[];
  eventPending: string = null;
  editMode: boolean;
  activeDayIsOpen = false;
  excludeDays: number[] = [0, 6]; // for exclude weekends
  searchQuery = '';

  periodStart = null;
  periodEnd = null;

  endOfWeek = endOfWeek;
  startOfWeek = startOfWeek;

  tz: string = null;
  currTz: string = null;
  currTzAbbr: string = null;
  allCalendars;
  calendars = [];
  isCheckedCalendars = [];
  monthViewEvents = [];
  weekViewEvents = [];
  dayViewEvents = [];
  isPersonalSpace = false;
  hideSearchInput = true;
  user: UsersPublicFieldsResDto;
  lang: string = this.localStorage.get(LocalStorageKeys.language) || 'en';

  views = [
    {
      value: 'day',
      label: this.translocoService.translate('calendar.day'),
    },
    {
      value: 'week',
      label: this.translocoService.translate('calendar.week'),
    },
    {
      value: 'month',
      label: this.translocoService.translate('calendar.month'),
    },
  ];

  constructor(
    protected checkPermissionPipe: CheckPermissionPipe,
    protected readonly modal: NgbModal,
    protected readonly toastr: ToastrService,
    protected readonly routerQueryService: RouterQueryService,
    protected readonly configService: ConfigService,
    readonly routerTenantPipe: RouterTenantPipe,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly store: Store,
    readonly ref: ChangeDetectorRef,
    private actions: Actions,
    private localStorage: LocalStorageService,
    private socketsService: SocketsService,
    private dragulaService: DragulaService,
    private zoomService: ZoomService,
    private render2: Renderer2,
    private minimizeService: MinimizeService,
    public dtHelper: DateTimeHelper,
    public calendarUtils: CalendarUtils,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.socket = this.socketsService.get();
  }

  ngOnInit() {
    if (environment.is_desktop) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.refreshPeriods(true);
        }
      });
    }

    this.store.dispatch(new CalendarEventsSet());
    this.store.dispatch(new CalendarEventsCheckedCalendarsGet({}));

    this.excludeDays = this.localStorage.get('excludeDays') || [0, 6];

    const query = {
      ...this.activatedRoute.snapshot.queryParams,
      ...this.activatedRoute.snapshot.params,
    };
    if (query.event || query.ticket) {
      this.eventPending = query.event || query.ticket;
    }

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(CalendarEventsUpdateStatus))
      .subscribe(() => this.refreshPeriods(true));

    this.translocoService.events$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event.type === 'translationLoadSuccess' || event.type === 'langChanged') {
        this.lang = event.payload.langName;
        this.views = this.views.map((view) => ({
          ...view,
          label: this.translocoService.translate(`calendar.${view.value}`),
        }));
      }
    });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user;
          if (user?.timezone) {
            this.currTz = user.timezone;
            this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
          }
          this.tz = moment.tz.guess(true);
        },
      });

    this.store
      .select(CalendarEventsState.getMyCalendars)
      .pipe(takeUntil(this.destroy$))
      .subscribe((calendars) => {
        this.allCalendars = calendars;

        let spacesCal: any[];
        let projectsCal = [];
        const spacesProjectsCal = [];

        spacesCal = (calendars as any)
          .map((item) => {
            if (item.object === 'spaces') {
              return {
                calId: item._id,
                title: item.calendarName,
                id: item.objectId,
              };
            }
          })
          .filter((val) => val);

        projectsCal = (calendars as any)
          .map((item) => {
            if (item.object === 'projects') {
              return {
                calId: item._id,
                title: item.calendarName,
                spaceId: item.spaceId,
                id: item.objectId,
              };
            }
          })
          .filter((val) => val);

        (spacesCal as any).forEach((space) => {
          spacesProjectsCal.push(space);
          projectsCal.forEach((project) => {
            if (project.spaceId === space.id) {
              spacesProjectsCal.push(project);
            }
          });
        });

        this.calendars = [
          {
            id: 'users',
            title: 'Personal Calendar',
          },
          ...spacesProjectsCal,
        ];

        this.isCheckedCalendars = calendars
          .map((item) => {
            if (item.isChecked) {
              return item.object === 'users' ? 'users' : item.objectId;
            }
          })
          .filter((item) => item);

        if (this.isCheckedCalendars.length > 0) {
          this.callPrepareEventsView();
        }

        this.ref.markForCheck();
      });

    // TODO: reduce unnecessary data for calendar event and remove type conversation for tickets
    combineLatest([
      this.store
        .select(CalendarEventsState.getEvents)
        .pipe(map((filterFn) => filterFn(this.objectId))),
      this.store.select(CalendarEventsState.getTickets) as undefined as any[],
    ])
      .pipe(takeUntil(this.destroy$), debounceTime(100), defaultIfEmpty([]))
      .subscribe(([events, tickets]) => {
        const filteredTickets = this.objectId
          ? tickets.filter((ticket) => ticket.objectId === this.objectId)
          : tickets;

        const eventsPrepare = [...events, ...filteredTickets].map((item) => {
          const allDay = item.allDay ? { allDay: item.allDay } : {};

          if (item.type !== 'tickets') {
            return {
              id: item._id,
              start: this.dtHelper.convertDateForTz(this.currTz, item.start),
              end: this.dtHelper.convertDateForTz(this.currTz, item.end),
              workdays: item.repeat ? item.repeat === 'workdays' : false,
              timezone: item.timezone,
              title: item.title,
              place: item.place,
              calendarEventMembers: item.calendarEventMembers,
              guests: item.calendarEventMembers?.map(({ userId }) => userId),
              userId: item.userId as string,
              userName: item.userName,
              objectId: item.objectId,
              object: item.object,
              cssClass: item.type ? item.type : item.object,
              draggable: false,
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              actions: [],
              type: item.type,
              subtype: null,
              videoCallId: item.videoCallId,
              ...allDay,
            };
          } else {
            if (item.start !== null && item.end !== null && item.startDate !== null) {
              // start date, next duedate
              let multiEvent = [];

              if (item.startDate !== null && item.startDate !== undefined) {
                multiEvent.push({
                  id: item._id,
                  start: this.dtHelper.convertDateForTz(this.currTz, item.startDate),
                  end: this.dtHelper.convertDateForTz(this.currTz, item.startDate),
                  workdays: item.repeat ? item.repeat === 'workdays' : false,
                  timezone: item.timezone,
                  title: item.title,
                  place: item.place,
                  calendarEventMembers: item.calendarEventMembers,
                  guests: item.calendarEventMembers?.map(({ userId }) => userId),
                  userId: item.userId as string,
                  userName: item.userName,
                  objectId: item.objectId,
                  object: item.object,
                  cssClass: 'start-date',
                  draggable: true,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true,
                  },
                  actions: [],
                  type: item.type,
                  videoCallId: item.videoCallId,
                  subtype: 'start',
                });
              }

              if (item.end !== null && item.end !== undefined) {
                multiEvent.push({
                  id: item._id,
                  start: this.dtHelper.convertDateForTz(this.currTz, item.end),
                  end: this.dtHelper.convertDateForTz(this.currTz, item.end),
                  workdays: item.repeat ? item.repeat === 'workdays' : false,
                  timezone: item.timezone,
                  title: item.title,
                  place: item.place,
                  calendarEventMembers: item.calendarEventMembers,
                  guests: item.calendarEventMembers?.map(({ userId }) => userId),
                  userId: item.userId as string,
                  userName: item.userName,
                  objectId: item.objectId,
                  object: item.object,
                  cssClass: item.type ? item.type : item.object,
                  draggable: true,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true,
                  },
                  actions: [],
                  type: item.type,
                  videoCallId: item.videoCallId,
                  subtype: 'end',
                });
              }
              return multiEvent;
            }

            if (item.start === null && item.end !== null) {
              return {
                id: item._id,
                start: this.dtHelper.convertDateForTz(this.currTz, item.end),
                end: this.dtHelper.convertDateForTz(this.currTz, item.end),
                workdays: item.repeat ? item.repeat === 'workdays' : false,
                timezone: item.timezone,
                title: item.title,
                place: item.place,
                calendarEventMembers: item.calendarEventMembers,
                guests: item.calendarEventMembers?.map(({ userId }) => userId),
                userId: item.userId as string,
                userName: item.userName,
                objectId: item.objectId,
                object: item.object,
                cssClass: item.type ? item.type : item.object,
                draggable: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                actions: [],
                type: item.type,
                videoCallId: item.videoCallId,
                subtype: 'end',
              };
            }

            if (item.start !== null && item.end === null) {
              return {
                id: item._id,
                start: this.dtHelper.convertDateForTz(this.currTz, item.startDate),
                end: this.dtHelper.convertDateForTz(this.currTz, item.startDate),
                workdays: false,
                timezone: item.timezone,
                title: item.title,
                place: item.place,
                calendarEventMembers: item.calendarEventMembers,
                guests: item.calendarEventMembers?.map(({ userId }) => userId),
                userId: item.userId as string,
                userName: item.userName,
                objectId: item.objectId,
                object: item.object,
                cssClass: 'start-date',
                draggable: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                actions: [],
                type: item.type,
                videoCallId: item.videoCallId,
                subtype: 'start',
              };
            }
          }
        });

        this.events = eventsPrepare.flat(1);
        this.callPrepareEventsView();

        if (this.events && this.eventPending) {
          const event = this.events.find((item) => item.id === this.eventPending);
          if (event) {
            this.viewEvent(event);
            this.eventPending = null;
          }
        }

        this.filteredEvents = this.filterByDateEvents;
        this.ref.detectChanges();
      });

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.themeMode = templateConf.layout.variant;
        this.ref.detectChanges();
      }
    });

    if (this.platform === 'web') {
      this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.isPersonalSpace = res[0].path === 'dash';
        this.hideSearchInput = res[0].path === 'dash';
      });
    }

    this.setSocketListeners();
    this.initDragula();
  }

  ngAfterViewInit() {
    this.scrollToCurrentView();
  }

  ngOnChanges() {
    this.periodStart = null;
    this.periodEnd = null;
    this.refreshPeriods();
  }

  ngOnDestroy() {
    this.store.dispatch(new CalendarTicketsSet([]));
    this.dragulaSub?.unsubscribe();
    this.dragulaService?.destroy(this.notesContainer);
    this.destroy$?.next();
    this.destroy$?.complete();
  }

  changeView(view) {
    this.view = view;

    this.callPrepareEventsView();

    this.scrollToCurrentView();
    this.ref.detectChanges();
  }

  viewDateRangeStart() {
    if (this.view === 'month' || this.view === 'day') {
      return moment(this.viewDate).startOf(this.view);
    } else if (this.view === 'week') {
      // fix sun->mon start of week
      if (this.viewDate.getDay() === 0) {
        // day is sunday
        return moment(this.viewDate).startOf('week').add(-6, 'day');
      }

      return moment(this.viewDate).startOf('week').add(1, 'day');
    }
  }

  viewDateRangeEnd() {
    if (this.view === 'month' || this.view === 'day') {
      return moment(this.viewDate).endOf(this.view);
    } else if (this.view === 'week') {
      // fix sun->mon start of week
      if (this.viewDate.getDay() === 0) {
        // day is sunday
        return moment(this.viewDate).endOf('week').add(-6, 'day');
      }
      return moment(this.viewDate).endOf('week').add(1, 'day');
    }
  }

  filterCurrentDayEvents() {
    this.refreshPeriods();
    return this.events?.filter((item) => {
      const eventStart = moment(item?.start);
      const eventEnd = moment(item?.end);
      return (
        eventStart.isBetween(this.periodStart, this.periodEnd) ||
        eventEnd.isBetween(this.periodStart, this.periodEnd)
      );
    });
  }

  getFilteredEvents(currentEvents): any[] {
    let filteredEvents = [];
    const user = this.store.selectSnapshot(AuthState.getUser);

    this.isCheckedCalendars.forEach((calId) => {
      filteredEvents =
        calId === 'users'
          ? [
              ...filteredEvents,
              ...(currentEvents
                ? currentEvents?.filter(
                    (item) =>
                      !item?.objectId &&
                      (item?.object === 'users' || item?.guests?.includes(user._id)),
                  )
                : []),
            ]
          : [
              ...filteredEvents,
              ...(currentEvents ? currentEvents?.filter((item) => item?.objectId === calId) : []),
            ];
    });
    return filteredEvents;
  }

  getCurrentEvents(currentEvents) {
    if (!this.isPersonalSpace) {
      return currentEvents;
    }
    return this.isCheckedCalendars.length > 0 ? this.getFilteredEvents(currentEvents) : [];
  }

  prepareEventsForDayView() {
    const currentDayEvents = this.filterCurrentDayEvents();
    this.dayViewEvents = this.getCurrentEvents(currentDayEvents);
    this.isLoaded = true;
  }

  prepareEventsForWeekView() {
    const res = this.filterCurrentDayEvents();

    // Split multi-day event by days
    const currentWeekEvents = res
      ?.sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
      .reduce<any>((acc, event) => {
        if (Math.abs(moment(event?.end).diff(moment(event?.start), 'days')) > 0) {
          acc.push({
            ...event,
            end: this.dtHelper.convertDateForTz(this.currTz, moment(event?.start).endOf('day')),
            allDay: moment(event?.start).isSame(moment(event?.start).startOf('day')),
          });
          let curDate = moment(event?.start).startOf('day');
          while (moment(event?.end).diff(curDate.startOf('day'), 'days') > 1) {
            curDate = curDate.add(1, 'day');
            acc.push({
              ...event,
              allDay: true,
              start: this.dtHelper.convertDateForTz(this.currTz, curDate),
              end: this.dtHelper.convertDateForTz(this.currTz, curDate.endOf('day')),
            });
          }
          acc.push({
            ...event,
            start: this.dtHelper.convertDateForTz(this.currTz, moment(event?.end).startOf('day')),
          });
        } else {
          acc.push(event);
        }
        return acc;
      }, []);

    this.weekViewEvents = this.getCurrentEvents(currentWeekEvents);
    this.isLoaded = true;
  }

  prepareEventsForMonthView() {
    this.refreshPeriods();

    const currentMonthEvents = this.events?.filter((item) => {
      const eventStart = moment(item.start);
      const eventEnd = moment(item.end);
      return (
        eventStart.isBetween(this.periodStart, this.periodEnd) ||
        eventEnd.isBetween(this.periodStart, this.periodEnd)
      );
    });

    this.monthViewEvents = this.getCurrentEvents(currentMonthEvents);
    this.isLoaded = true;
  }

  refreshPeriods = (force = false) => {
    const periodStart = this.viewDateRangeStart();
    const periodEnd = this.viewDateRangeEnd();

    if (force || !periodStart?.isSame(this.periodStart) || !periodEnd?.isSame(this.periodEnd)) {
      this.periodStart = periodStart;
      this.periodEnd = periodEnd;

      this.store.dispatch(
        new CalendarEventsGet({
          ...this.checkObjectIsUsers(),
          objectId: this.objectId,
          from: periodStart.toISOString(),
          to: periodEnd.toISOString(),
        }),
      );

      this.store.dispatch(
        new CalendarTicketsGet({
          from: periodStart.toISOString(),
          to: periodEnd.toISOString(),
          objectId: this.objectId,
        }),
      );
    }
  };

  checkObjectIsUsers() {
    if (this.object === 'users') {
      return '';
    }

    return { object: this.object || 'users' };
  }

  get filterByDateEvents(): CalendarEvent[] {
    return this.events && this.events.length
      ? this.events.filter(
          (event) =>
            moment(event?.start).isSame(this.viewDate, 'day') ||
            moment(this.viewDate).isBetween(moment(event?.start), moment(event?.end)),
        )
      : [];
  }

  private scrollToCurrentView() {
    if (this.view === 'week' || this.view === 'day') {
      // each hour is 60px high, so to get the pixels to scroll it's just the amount of minutes since midnight
      const minutesSinceStartOfDay = differenceInMinutes(
        startOfHour(new Date()),
        startOfDay(new Date()),
      );

      const headerHeight = this.view === 'week' ? 55 : 0;

      if (this.scrollContainer) {
        this.scrollContainer.nativeElement.scrollTo({
          top: minutesSinceStartOfDay + headerHeight,
          duration: 0,
        });
      }
    } else {
      this.directiveRef?.update();
    }
  }

  changeSelectedCalendars() {
    MixpanelService.trackEvent('Calendar selected calendars change', {
      calendars: this.isCheckedCalendars,
    });
    this.store.dispatch(
      new CalendarEventsCheckedCalendarsUpdate({
        body: {
          calendarEventsChecksIds: this.isCheckedCalendars
            .map((checkedCal) => {
              if (checkedCal === 'users') {
                return this.allCalendars.filter((item) => item.object === 'users').pop();
              }
              return this.allCalendars.filter((item) => item.objectId === checkedCal).pop();
            })
            .filter((val) => val)
            .map((item) => item._id),
        },
      }),
    );

    this.callPrepareEventsView();
  }

  changeCalendarPeriod() {
    MixpanelService.trackEvent('Calendar period change', {
      period: this.view,
    });
    this.callPrepareEventsView();
  }

  resetCurrentDateView(): void {
    MixpanelService.trackEvent('Calendar reset current date view');
    this.viewDate = new Date();
    this.callPrepareEventsView();
  }

  changeIncludeWeekends(checked) {
    MixpanelService.trackEvent('Calendar include weekends change', {
      includeWeekends: checked,
    });
    this.excludeDays = checked ? [] : [0, 6];
    this.localStorage.set('excludeDays', this.excludeDays);
  }

  callPrepareEventsView() {
    this.isLoaded = false;
    if (this.view === 'month') {
      this.prepareEventsForMonthView();
    } else if (this.view === 'week') {
      this.prepareEventsForWeekView();
    } else {
      this.prepareEventsForDayView();
    }
    this.filterEventsByQuery(this.searchQuery);
  }

  hourClicked(event) {
    MixpanelService.trackEvent('Calendar hour clicked');
    if (
      this.checkPermissionPipe.transform(
        this.object + '::' + this.objectId + '::calendarEventCreate',
      )
    ) {
      this.addEvent(
        this.dtHelper.convertDateForTz(this.currTz, event.date),
        this.dtHelper.convertDateForTz(this.currTz, event.date, {
          amount: this.view === 'week' ? 60 : 30,
          unit: 'm',
        }),
      );
    }
  }

  dayClicked({ date }, isMobile = false): void {
    MixpanelService.trackEvent('Calendar day clicked');
    if (isSameMonth(date, this.viewDate) && !isMobile) {
      if (
        this.checkPermissionPipe.transform(
          this.object + '::' + this.objectId + '::calendarEventCreate',
        )
      ) {
        this.addEvent(startOfDay(date), endOfDay(date));
      }
    }

    if (isMobile) {
      this.viewDate = date;
      this.filteredEvents = this.filterByDateEvents;
    }
  }

  viewEvent(event) {
    MixpanelService.trackEvent('Calendar view event', {
      event: event.id,
      eventType: event.type,
    });

    if (event.type === 'tickets') {
      const modalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: event.id,
        object: event.object,
        objectId: event.objectId,
      };

      this.minimizeService.minimizeInit(modalRef);
    } else {
      this.routerQueryService.update({ event: event.id });
      const modalRef = this.modal.open(CalendarEventModalComponent, {
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.modalData = {
        action: 'View event',
        displayName: this.translocoService.translate('calendar.view-event'),
        object: event.object || this.object,
        objectId: event.objectId || this.objectId,
        event,
      };
    }
  }

  addEvent(
    start: Date = null,
    end: Date = null,
    mobile = false,
    title: string = '',
    description = '',
  ): void {
    MixpanelService.trackEvent('Calendar add new event');
    const currentTime = mobile ? new Date(start.setHours(new Date().getHours())) : new Date();
    const defaultStart = setMinutes(currentTime, getMinutes(currentTime) > 30 ? 60 : 30);
    const defaultEnd = setMinutes(defaultStart, getMinutes(defaultStart) > 30 ? 90 : 60);
    const modalRef = this.modal.open(CalendarEventModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalData = {
      action: 'Add new event',
      displayName: this.translocoService.translate('calendar.add-new-event'),
      object: this.object,
      objectId: this.objectId,
      event: {
        title,
        description,
        repeat: 'never',
        reminder: 'without',
        start: mobile ? defaultStart : start ?? defaultStart,
        end: mobile ? defaultEnd : end ?? defaultEnd,
        object: this.object,
        spaceId: this.object === 'spaces' ? this.objectId : undefined,
        projectId: this.object === 'projects' ? this.objectId : undefined,
        allDay: false,
        workdays: false,
      },
    };
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    if (
      moment(event.start).unix() !== moment(newStart).unix() ||
      moment(event.end).unix() !== moment(newEnd).unix()
    ) {
      const user = this.store.selectSnapshot(AuthState.getUser);

      if (event['userId'] === user._id) {
        if (this.isEventSeries(event)) {
          ConfirmAlert(null, {
            subject: this.translocoService.translate('alert.event-editing-subject'),
            text: this.translocoService.translate('alert.event-editing-text'),
            showDenyButton: true,
            denyButtonText: this.translocoService.translate('alert.event-editing-deny-btn'),
            confirmButtonText: this.translocoService.translate('alert.event-editing-confirm-btn'),
            confirmButtonClass: 'btn-success',
            denyButtonClass: 'btn-solid',
            platform: this.platform,
          })
            .then(
              (result) => {
                if (result === 'isConfirmed') {
                  this.createSingleEventInSeries(event, newStart, newEnd);
                } else if (result === 'isDenied') {
                  this.updateEventSeries(event, newStart, newEnd);
                }
              },
              () => {},
            )
            .catch((err) => catchError(err));
        } else {
          event.start = newStart;
          event.end = newEnd;
          this.editEventSubmit(event);
        }
      } else {
        this.toastr.clear();
        this.toastr.error(
          this.translocoService.translate('toastr.you-not-owner-of-calendar-event'),
          this.translocoService.translate('toastr.title-error'),
        );
      }
    }
    this.eventRefresh.next();
  }

  changeHoursInDate(date) {
    const offset = moment.tz(this.tz).format('Z');
    const diff = moment(date).diff(moment(date).utcOffset(offset, true), 'minutes');

    return moment(date).add(diff, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  }

  private setSocketListeners(): void {
    this.socket
      .fromEvent('notification:send:calendarEventCreate')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventBasis) => {
        const periodStart = this.viewDateRangeStart();
        const periodEnd = this.viewDateRangeEnd();

        if (res.objectId === this.objectId || this.object === 'users') {
          this.store.dispatch(
            new CalendarEventGet({
              ...res,
              from: periodStart.toISOString(),
              to: periodEnd.toISOString(),
            }),
          );
        }
      });

    this.socket
      .fromEvent('notification:send:calendarEventsDelete')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: CalendarEvent) => this.store.dispatch(new CalendarEventRemove(event)));

    this.socket
      .fromEvent('notification:send:calendarEventsExceptionsCreate')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventException) =>
        this.store.dispatch(new CalendarEventExceptions(res)),
      );

    this.socket
      .fromEvent('notification:send:calendarEventUpdate')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventBasis) => {
        const periodStart = this.viewDateRangeStart();
        const periodEnd = this.viewDateRangeEnd();

        if (res.objectId === this.objectId || this.object === 'users') {
          this.store.dispatch(
            new CalendarEventsDataUpdate({
              ...res,
              from: periodStart.toISOString(),
              to: periodEnd.toISOString(),
            }),
          );
        }
      });

    merge(
      this.socket.fromEvent('notification:send:ticketsCreate'),
      this.socket.fromEvent('notification:send:ticketsUpdate'),
      this.socket.fromEvent('notification:send:ticketsDelete'),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refreshPeriods(true));
  }

  private initDragula(): void {
    this.dragulaSub.add(
      this.dragulaService
        .drop(this.notesContainer)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ name, el, target, source, sibling }) => {
          const _elWeekDay = document.querySelector('.cal-hour-segment .list-group-item');
          const _elMonth = document.querySelector('.cal-cell-top .list-group-item');

          const isMonthlyView = !!_elMonth;
          const targetContainerClass = _elMonth ? '.cal-cell-top' : '.cal-hour-segment';
          const eventStartEnd = this.getEventStartEnd(isMonthlyView, target);

          let _noteTitle = document.querySelector(
            `${targetContainerClass} .list-group-item .note-title-container`,
          )?.innerHTML;
          _noteTitle = _noteTitle ? _noteTitle : 'Enter event title';

          const _noteDescription = document.querySelector(
            `${targetContainerClass} .list-group-item .note-desc-container`,
          ).innerHTML;

          el.parentElement.removeChild(!isMonthlyView ? _elWeekDay : _elMonth);

          this.addEvent(
            this.dtHelper.convertDateForTz(this.currTz, eventStartEnd.start),
            this.dtHelper.convertDateForTz(this.currTz, eventStartEnd.end),
            false,
            _noteTitle,
            _noteDescription,
          );
        }),
    );
  }

  private isEventSeries(event): boolean {
    return this.events.filter((item) => item.id === event.id).length > 1;
  }

  private editEventSubmit(event: any): void {
    this.store
      .dispatch(
        new CalendarEventsUpdate({
          id: event.id,
          body: {
            _id: event.id,
            title: event.title,
            start: moment(this.changeHoursInDate(event.start)).toISOString(),
            end: moment(this.changeHoursInDate(event.end)).toISOString(),
            userId: event.userId,
            object: event.object,
          },
        }),
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          this.toastr.clear();
          this.toastr.success(
            this.translocoService.translate('toastr.event-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  private createSingleEventInSeries(event, newStart: Date, newEnd: Date): void {
    const rawEvent = this.events.find((item) => item.id === event.id && item.start === event.start);

    this.store
      .dispatch(
        new CalendarEventsUpdateSingle({
          calendarEventId: event.id,
          exceptionDays: [event.start],
        }),
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          rawEvent.start = newStart;
          rawEvent.end = newEnd;
          rawEvent.repeat = CalendarRepeatTypes.Never;
          this.store.dispatch(new CalendarEventCreate(rawEvent));
        },
        (err) =>
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  private updateEventSeries(event, newStart: Date, newEnd: Date): void {
    this.store
      .dispatch(new CalendarEventsGetById({ id: event.id }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((res) => {
        const rawEvent = this.events.find(
          (item) => item.id === event.id && item.start.getTime() === event.start.getTime(),
        );
        const firstEvent = res.CalendarEvents.event;
        const timeStart = moment(firstEvent.start)
          .hours(moment(newStart).hours())
          .minutes(moment(newStart).minute());
        const timeEnd = moment(firstEvent.end)
          .hours(moment(newEnd).hours())
          .minutes(moment(newEnd).minute());

        const body = {
          ...rawEvent,
          _id: rawEvent.id,
          start: moment(
            this.currTz !== this.tz ? this.changeHoursInDate(rawEvent.start) : timeStart,
          ).toISOString(),
          end: moment(
            this.currTz !== this.tz ? this.changeHoursInDate(rawEvent.end) : timeEnd,
          ).toISOString(),
        };

        this.store.dispatch(new CalendarEventsUpdate({ id: event.id, body }));
      });
  }

  private getEventStartEnd(monthView: boolean, target: any): any {
    let start;
    let end;
    let eventTime;

    if (monthView) {
      eventTime = moment()
        .minute(moment().minute() > 30 ? 30 : 0)
        .second(0);
      eventTime = moment(eventTime).set(
        'date',
        moment(target.getAttribute('data-event-date')).date(),
      );

      start = this.dtHelper.convertDateForTz(this.currTz, eventTime);
      end = this.dtHelper.convertDateForTz(this.currTz, eventTime, {
        amount: 30,
        unit: 'm',
      });
    } else {
      const eventDate = target.getAttribute('data-event-date');
      start = this.dtHelper.convertDateForTz(this.currTz, eventDate);
      end = this.dtHelper.convertDateForTz(this.currTz, eventDate, {
        amount: 30,
        unit: 'm',
      });
    }

    return { start, end };
  }

  public getSelectedItemsCount(selected: any[], calendars: any[]) {
    return selected.filter((s) => calendars.map((c) => c.id).includes(s.id)).length;
  }

  filterEventsByQuery(query: string): void {
    let filteredEvents: CalendarEvent[];
    filteredEvents = this.getCurrentEvents(this.events)?.filter((event) => {
      return (
        event?.title.toLowerCase().includes(query.toLowerCase()) ||
        event?.userName.toLowerCase().includes(query.toLowerCase())
      );
    });

    this.searchQuery = query;

    if (this.view === 'month') {
      this.monthViewEvents = filteredEvents;
    } else if (this.view === 'week') {
      this.weekViewEvents = filteredEvents;
    } else {
      this.dayViewEvents = filteredEvents;
    }
  }

  toggleSearchInput(): void {
    MixpanelService.trackEvent('Calendar toggle search input');
    this.searchQuery = '';
    this.filterEventsByQuery(this.searchQuery);
    this.hideSearchInput = !this.hideSearchInput;
  }
}
