import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewRef,
  forwardRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  NgbActiveModal,
  NgbModal,
  NgbNav,
  NgbNavChangeEvent,
  NgbTooltip,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { parseISO } from 'date-fns';
import moment from 'moment-timezone';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { UsersDbDto } from '../../api/models/users-db-dto';
import { ColumnsDbDto } from '../../api/models/columns-db-dto';
import { TicketsDbDto } from '../../api/models/tickets-db-dto';
import { TicketsGetOneResDto } from '../../api/models/tickets-get-one-res-dto';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';

import {
  QuillModulesForChat,
  QuillModulesForDescription,
} from '../../shared/data/quill-configuration';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { FilesHelper } from '../../shared/utils/files-helper';
import { HtmlHelper } from '../../shared/utils/html-helper';
import { RouterTenantPipe } from '../../shared/pipes/router-tenant.pipe';
import { CheckPermissionPipe } from '../../shared/pipes/check-permission.pipe';
import { CalendarControlTypes } from '../calendar-event/calendar-event.component';
import { ConfigService } from '../../shared/services/config.service';
import { SocketsService } from '../../shared/services/sockets.service';
import { MediaService } from '../../shared/services/media.service';
import { RouterQueryService } from '../../shared/services/router-query.service';
import { QuillInitializeService } from '../../shared/services/quill/quill-init.service';
import { SpaceGetUsersList } from '../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../shared/store/actions/projects.action';
import { ThreadsSocketNewMessage } from '../../shared/store/actions/threads.action';
import {
  BoardsApplyTicketSettings,
  BoardsSetSettings,
  ColumnsGetList,
  DraftCreate,
  DraftDelete,
  DraftUpdate,
  TicketClearLocalList,
  TicketsCreate,
  TicketsCreateEstimationSession,
  TicketsDelete,
  TicketsFilesDelete,
  TicketsGetById,
  TicketsGetChecklistItems,
  TicketsGetEstimationSession,
  TicketsGetList,
  TicketsGetShortList,
  TicketsJoinMemberToEstimationSession,
  TicketsLabelCreate,
  TicketsLabelsGet,
  TicketsOpenTicketChange,
  TicketsSocketUpdateEstimationSession,
  TicketsUpdate,
  TicketsUpdateEstimationSession,
  TicketsUpdateMemberEstimationSession,
} from '../../shared/store/actions/board.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { UsersState } from '../../shared/store/states/users.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { ProjectsState } from '../../shared/store/states/projects.state';
import { BoardsState } from '../../shared/store/states/boards.state';
import { RecordService } from '../../shared/services/record.service';
import { TicketsLabelsDbDto } from '../../api/models/tickets-labels-db-dto';
import { BoardSettingsResDto } from '../../api/models/board-settings-res-dto';
import { TicketsFieldsValuesReqDto } from '../../api/models/tickets-fields-values-req-dto';
import { LockedFields } from './fields.types';
import { FieldType } from '../custom-field/enums/fieldType';
import { FieldGetList } from '../../shared/store/actions/board-field.action';
import { EstimateSessionMembers, Tabs, TicketData } from './board-ticket';
import { PRIORITY } from '../../pages/full-pages/board/kanban-board/constants/priority.constants';
import { PriorityEnum } from '../../pages/full-pages/board/kanban-board/enums/priority.enum';
import { TicketService } from '../../shared/services/ticket.service';
import { TicketsFieldsDbDto } from '../../api/models/tickets-fields-db-dto';
import {
  ChatsCreatePin,
  ChatsDeletePin,
  ChatsGetPin,
} from '../../shared/store/actions/chats.action';
import { ChatsState } from '../../shared/store/states/chats.state';
import { ChatPinnedService } from '../../shared/components/chat/chat-pinned-messages/chat-pinned.service';
import { PinType } from '../../shared/components/chat/chat-pinned-messages/enums/pin-type.enum';
import { MinimizeService } from '../../shared/services/minimize.service';
import { ProjectsDbDto } from '../../api/models/projects-db-dto';
import { ContainerRefDirective } from '../../shared/directives/container-ref.directive';
import { PreviewMediaComponent } from '../data-room-modals/preview-media/preview-media.component';
import { ToastrHelper } from '../../shared/utils/toastr-helper';
import { replaceLastSrcWithUrl } from '../../shared/utils/replace-src-helper';
import { PriorityOrderService } from '../../shared/services/priority-order.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { Keyboard } from '@capacitor/keyboard';
import {
  DOCUMENT,
  NgIf,
  NgClass,
  NgTemplateOutlet,
  NgFor,
  AsyncPipe,
  DatePipe,
} from '@angular/common';
import { environment } from '../../../environments/environment';
import { PlatformService } from '../../../app/shared/services/platform.service';
import { add } from 'cypress/types/lodash';
import { DownloadService } from '../../api/services/download.service';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { FromNowPipe } from '../../shared/pipes/from-now.pipe';
import { DragDropViewComponent } from '../../shared/components/documents/drag-drop-view/drag-drop-view.component';
import { TicketColoringComponent } from '../../shared/components/ticket-coloring/ticket-coloring.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { ChatThreadComponent } from '../../shared/components/chat/chat-thread/chat-thread.component';
import { ChecklistComponent } from '../../shared/components/checklist/checklist.component';
import { QuillEditorComponent } from 'ngx-quill';
import { FocusDirective } from '../../shared/directives/focus.directive';
import { TextareaAutoresizeDirective } from '../../shared/directives/textarea-autoresize.directive';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectAvatarComponent } from '../../shared/components/space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from '../../shared/components/space-projects/space-avatar/space-avatar.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { DragDropDirective } from '../../shared/directives/drag-drop.directive';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-board-ticket-modal',
  templateUrl: './board-ticket.component.html',
  styleUrls: ['./board-ticket.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    ContainerRefDirective,
    DragDropDirective,
    NgClass,
    SvgComponent,
    NgTemplateOutlet,
    SvgIconComponent,
    NgbTooltip,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbar,
    SpaceAvatarComponent,
    ProjectAvatarComponent,
    NgSelectModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    forwardRef(() => AvatarComponent),
    TextareaAutoresizeDirective,
    FocusDirective,
    QuillEditorComponent,
    ChecklistComponent,
    NgFor,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    ChatThreadComponent,
    NgbNavOutlet,
    CustomFieldsComponent,
    TicketColoringComponent,
    DragDropViewComponent,
    AsyncPipe,
    DatePipe,
    FromNowPipe,
    TruncatePipe,
  ],
})
export class BoardTicketModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('attachFileInput') fileInput: HTMLInputElement;
  @ViewChild('ticketModalsTab') ticketModalsTab: NgbNav;
  @ViewChild('customizeTicketButton')
  customizeTicketButton: ElementRef<HTMLDivElement>;
  @ViewChild(ContainerRefDirective, { static: true })
  boardTicketRef: ContainerRefDirective;
  @ViewChild('descriptionQuill', { static: false }) quill: any;
  @ViewChild('scrollbarContainer') scrollbarRef: NgScrollbar;

  @Output() onMinimize = new EventEmitter<void>();
  @Output() onExpand = new EventEmitter<void>();
  viewRef: ViewRef = null;

  env: any;
  platform = 'web';
  platformOS = 'web';

  users$: Observable<any[]>;
  spaces: Observable<any[]>;
  projects: Observable<any[]>;

  modalData: Partial<TicketData>;
  ticketData: Partial<TicketData>;
  settings: BoardSettingsResDto;

  userData: UsersPublicFieldsResDto;
  invitedUser = false;

  socket;
  socketEstimateSessionUpdateEvent = 'notification:send:boardsTicketsEstimationSessionUpdate';

  estimationTypes = [
    { unit: 'h', title: 'Hours' },
    { unit: 'd', title: 'Days' },
    { unit: 'sp', title: 'Storypoints' },
  ];

  estimationIsLoadingStart = false;
  estimationUserValue: FormControl = new FormControl('', [Validators.required]);
  estimationCreatorLock: FormControl = new FormControl(false);
  estimationMinMaxAvg: { min: number; max: number; avg: number } = {
    min: 0,
    max: 0,
    avg: 0,
  };

  estimateSession = null;
  estimateSessionMembers: EstimateSessionMembers[] = [];
  isHiddenEstimateSession = false;

  spaceUsers: UsersDbDto[] = [];
  projectUsers: UsersDbDto[] = [];

  spaceInfo: any;
  projectInfo: any;

  config: any = {};
  currentTab: string = Tabs.comments;
  MAX_FILE_SIZE: number;
  chatId: string;
  spaceChat;
  projectChat;

  isPinned = false;

  editorModules: any;
  ticketForm: any = this.formBuilder.group({
    objectId: ['', Validators.required],
    title: ['', Validators.required],
    description: ['', Validators.required],
    columnId: [''],
    parentId: [''],
    epicId: [''],
    selectedSubtask: null,
    estimateValue: [null],
    estimateUnit: [null],
    dueDate: [null],
    startDate: [null],
    // releaseDate: [item.releaseDate || ''],
    releaseVersion: [''],
    labels: [[]],
    realTime: [''],
    ticketMembers: [[]],
    files: null,
    isBlocked: false,
    bgColor: '#ffffff',
    customFields: [],
    priority: null,
    startDateReminder: [null],
    startDateReminderState: [''],
  });
  dataChangedInCustomFields: { [x: string]: any };
  customFieldsValues: Array<TicketsFieldsValuesReqDto> = [];
  pageTitle = '';
  pageTitleBack = '';
  submitButtonTitle = '';
  columnName = '-';
  ticketCreator = '';
  ticketUpdatedBy = '';
  ticketCreatedAt = '';
  ticketUpdatedAt = '';
  isCopyTicket = false;
  ticketHistory = [];
  needCreateNext = false;
  public boardTickets = [];

  descriptionAttachmentsBlob: File[] = [];
  fileData: {
    _id?: string;
    fileName: string;
    ownerUserId?: string;
    created_at?: string;
    originalFileName?: string;
    url?: string;
  }[] = [];
  ticketCreatorName: string;
  ticketUpdatedByName: string;
  loading = false;
  isBlocked: boolean;
  isUploading: boolean;
  isToolbarClicked = false;
  isShowCustomiseTicket = false;
  customiseTicketXY: {
    x: number;
    y: number;
  };
  prevTicketStatus = '';

  usersInfoSub$: Subscription;
  usersSub$: Subscription;

  ticket: TicketsGetOneResDto;
  tickets: TicketsDbDto[] = [];
  ticketsSelect: TicketsDbDto[] = [];
  ticketSelectParent: BehaviorSubject<TicketsDbDto[]> = new BehaviorSubject([]);
  columns: ColumnsDbDto[];
  boardColumns: any[];
  childrenList: any[] = [];
  subTicketsEpic: TicketsDbDto[] = [];
  parentTask: any = null;
  canUnpinTicket = true;
  showSubtaskSelect = false;
  isParentHasValueInCustomField = false;
  selectedSubtask = null;
  ticketMedia: any[] = [];
  mediaPreviewVisible = false;
  limitToAttachmentName: number;
  handleCtrlEnter = false;
  validateCustomField: string[] = [];
  relatedChatId = null;
  hasError = false;
  threadId: string;
  skipEscapeControls = ['tab-index-status', 'tab-index-assignees'];
  focusedControl: string;
  toggleDescriptionEditVisibility = false;
  editorDescription: any;
  mentionThreadMembers: any[];
  message: any = null;
  isLoadingPin = false;
  epicsList: any[] = [];
  epicBreadcrumb: TicketsDbDto;
  epicLabel;
  currTzAbbr = null;

  @ViewChild('scrollContainer', { static: false })
  private scrollContainer: ElementRef;

  addLabelPromise: (label: any) => Promise<any>;
  addSubtaskPromise: (task: any) => Promise<any>;
  labels: Array<TicketsLabelsDbDto> = [];
  selectedLabels;
  ticketWithDueDate: boolean;

  ticketWithStartDate: boolean;
  timeIsEmpty: boolean;

  private curTabIndex = 0;
  private maxTabIndex = 10;

  tabIndexes: { tabIndex?: string; type?: string }[] = [
    { tabIndex: 'tab-index-title', type: '' },
    { tabIndex: 'tab-index-description', type: 'quill-editor' },
    { tabIndex: 'tab-index-upload-files', type: 'button' },
    { tabIndex: 'tab-index-assignees', type: 'select' },
    { tabIndex: 'tab-index-day-date', type: '' },
    { tabIndex: 'tab-index-day-time', type: '' },
    { tabIndex: 'tab-index-labels', type: 'select' },
    { tabIndex: 'tab-index-estimate-value', type: '' },
    { tabIndex: 'tab-index-estimate-unit', type: 'select' },
    { tabIndex: 'tab-index-release-version', type: '' },
    { tabIndex: 'tab-index-parent-id', type: 'select' },
    { tabIndex: 'tab-index-sub-task', type: 'button' },
    { tabIndex: 'tab-index-status', type: 'select' },
  ];

  public priorityKeys = PRIORITY;
  fields: Array<TicketsFieldsDbDto> = [];
  public isMinimized = false;
  public isExpanded = false;
  public isMinimized$ = new Subject<void>();
  public isInternalState = false;
  public isDraggingFile: boolean;
  public parentIsConflict = new BehaviorSubject<{
    message: string;
    id: string;
  }>(null);
  public wrongSubtaskIds: Array<string> = [];
  public subtasksErrorMessage = null;
  private isAlreadyInit = false;
  private pinnedMessageId: string;
  private selectedLabelsForDraft: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public selectedLabelsForDraft$: Observable<string[]> = this.selectedLabelsForDraft.asObservable();
  currTz: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public filesHelper: FilesHelper,
    public htmlHelper: HtmlHelper,
    public formBuilder: FormBuilder,
    protected configService: ConfigService,
    private modal: NgbModal,
    private actions: Actions,
    private store: Store,
    private ref: ChangeDetectorRef,
    private socketsService: SocketsService,
    private mediaService: MediaService,
    private toastr: ToastrService,
    private downloadService: DownloadService,
    private pinnedService: ChatPinnedService,
    private routerQueryService: RouterQueryService,
    private quillInitializeService: QuillInitializeService,
    private checkPermissionPipe: CheckPermissionPipe,
    private routerTenantPipe: RouterTenantPipe,
    private activeModal: NgbActiveModal,
    private recordService: RecordService,
    private route: ActivatedRoute,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private ticketService: TicketService,
    private minimizeService: MinimizeService,
    private toastrHelper: ToastrHelper,
    private translocoService: TranslocoService,
    private priorityOrderService: PriorityOrderService,
    private platformService: PlatformService,
  ) {
    this.config = this.configService.templateConf;
    this.MAX_FILE_SIZE = this.configService.MAX_FILE_SIZE;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(e) {
    if (this.handleCtrlEnter) {
      if (e.shiftKey && e.keyCode === 9) {
        // tab key shift
        e.preventDefault();
        this.handleTabKey(false);
      } else if (e.keyCode === 9) {
        // tab key
        e.preventDefault();
        this.handleTabKey(true);
      }
      if (e.keyCode === 83 && e.ctrlKey && e.altKey) {
        // handle Ctrl+Alt+S - save a ticket
        e.preventDefault();
        this.submitForm();
      } else if (e.keyCode === 65 && e.ctrlKey && e.altKey) {
        // handle Ctrl+Alt+A - open assignee select?
        e.preventDefault();
        this.setFocusOnObjectSelect('tab-index-assignees');
      } else if (e.keyCode === 13 && e.ctrlKey) {
        // handle enter
        e.preventDefault();
        this.submitForm();
      } else if (e.keyCode === 27) {
        // handle escape
        if (!this.skipEscape()) {
          e.preventDefault();
          this.close();
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (event?.target['src'] && !this.isMinimized) {
      this.handleEditorImageClick(event?.target);
    }

    if (event?.target['classList'].contains('ql-picker-label')) {
      this.isToolbarClicked = true;
      event.stopPropagation();
    }
  }

  ngOnInit() {
    Object.keys(this.priorityKeys).forEach((key) => {
      this.priorityKeys[key].displayName = this.translocoService.translate(
        `modals.board-ticket.priority-${key}`,
      );
    });

    if (!this.ticketData.id && !this.ticketData.draft) {
      // clear check list local store
      this.store.dispatch(TicketClearLocalList).pipe(untilDestroyed(this));
    }

    this.env = this.store.selectSnapshot(AuthState.getEnv);
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.platformOS = this.store.selectSnapshot(AuthState.getPlatformOS);

    this.store.dispatch(new TicketsOpenTicketChange(true));
    const storeTickets = this.store.selectSnapshot(BoardsState.getAllTicketsList);

    if (this.isMobile) {
      Keyboard.addListener('keyboardDidShow', () => {
        if (this.scrollbarRef) {
          this.document.activeElement.scrollIntoView({ block: 'center' });
        }

        this.ref.detectChanges();
      });
    }

    if (
      !storeTickets.length ||
      !storeTickets.some((ticket) => ticket.objectId === this.ticketData.objectId)
    ) {
      this.store
        .dispatch(
          new TicketsGetList({
            object: this.ticketData.object,
            objectId: this.ticketData.objectId,
            isInternalState: true,
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const tickets = this.ticketService.getTickets();

          const isTicketParent = (ticket) =>
            tickets.find((storeTicket) => storeTicket.parentId === ticket._id);
          this.ticketsSelect = tickets.filter((t) => !t.isEpic && !isTicketParent(t));
          this.tickets = this.getTickets(tickets);
        });
    } else {
      const isTicketParent = (ticket) =>
        storeTickets.find((storeTicket) => storeTicket.parentId === ticket._id);
      this.ticketsSelect = storeTickets.filter((t) => !t.isEpic && !isTicketParent(t));
      this.tickets = this.getTickets(storeTickets);
      this.ref.detectChanges();
    }

    this.store
      .select(AuthState.getUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.userData = user;
        if (user?.timezone) {
          this.currTz = user.timezone;
          this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
        }
      });

    this.store
      .select(SpacesState.getUsers)
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        this.spaceUsers = users;
      });

    this.store
      .select(ProjectsState.getUsers)
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        this.projectUsers = users;
      });

    this.actions
      .pipe(untilDestroyed(this), ofActionSuccessful(ThreadsSocketNewMessage))
      .pipe(untilDestroyed(this))
      .subscribe(({ payload }) => {
        if (
          payload?.parentMessage?.linkObject === 'tickets' &&
          payload?.parentMessage?.linkObjectId === this.modalData?.id
        ) {
          this.threadId = payload?.parentMessage?.threadId;
        }
      });

    this.estimationCreatorLock.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((lockStatus: boolean) => {
        if (lockStatus) {
          this.estimationMinMaxAvg = this.calculateMinMaxAvg(this.estimateSession.members);
        }

        this.updateEstimateSession();
      });

    this.initializeEstimateSocket();
    this.setTicketData();
    this.getEstimateSession();
    this.initEstimationSession();

    this.isInternalState = this.modalData?.isNeedToSelectObject;

    this.initTicketServiceData();
    this.initTicketServiceSubscribers();

    this.isAlreadyInit = true;
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(untilDestroyed(this)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.ref.markForCheck();
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new TicketsOpenTicketChange(false));
    this.usersSub$?.unsubscribe();
    this.usersInfoSub$?.unsubscribe();

    this.socket.removeListener(this.socketEstimateSessionUpdateEvent);
  }

  public getSpace(spaceId) {
    return (
      this.modalData.objectId &&
      this.store.selectSnapshot(SpacesState.getSpace)(spaceId || this.modalData.objectId)
    );
  }

  public get currentProject(): ProjectsDbDto {
    return (
      this.modalData.objectId &&
      this.store.selectSnapshot(ProjectsState.getProject)(this.modalData.objectId)
    );
  }

  public get isMobile(): boolean {
    return this.platform !== 'web';
  }

  public get isTicketHasPriority(): boolean {
    return Number.isInteger(this.ticketForm.value?.priority);
  }

  /* Getters */
  public get isUserInsideEstimateSession(): boolean {
    return (
      this.estimateSessionMembers?.map((member) => member.userId).includes(this.userData?._id) &&
      this.estimateSession?.ticketId
    );
  }

  // Getter
  public get isLimitPins(): boolean {
    return (
      this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .filter((msg) => msg.linkDocument !== PinType.Message)?.length <= 14
    );
  }

  public get isOwnerEstimateSession(): boolean {
    return this.userData?._id === this.estimateSession?.ownerUserId;
  }

  public get isEstimationSessionLocked(): boolean {
    return this.estimationCreatorLock.value;
  }

  /* Methods */
  public isUserEstimate(member): boolean {
    return member.userId === this.userData?._id && member.value;
  }

  public onChangeTicketColor(color: string): void {
    this.ticketForm.patchValue({ bgColor: color });
  }

  public onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.currentTab = changeEvent.nextId;
  }

  public onSelectPriority(index: PriorityEnum): void {
    this.ticketForm.patchValue({
      isBlocked: index === PriorityEnum.Highest,
      priority: index,
    });
  }

  private buildForm(data) {
    this.ticketForm?.reset();
    const todoColumnId = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      .find((column) => column.title === 'TODO' && !column.isDeleted)?._id;

    this.isBlocked = data?.isBlocked || false;
    this.ticketWithDueDate = !!data.dueDate;
    this.ticketWithStartDate = !!data.startDate;
    this.timeIsEmpty = !data.dueDate;
    this.isParentHasValueInCustomField = !!data.parentId;

    this.ticketForm = this.formBuilder.group({
      objectId: [data.objectId || '', Validators.required],
      title: [
        this.isCopyTicket
          ? data.title + ' - ' + this.translocoService.translate('modals.board-ticket.copy-title')
          : data.title || '',
        Validators.required,
      ],
      description: [data.description || '', Validators.required],
      columnId: [data.columnId || todoColumnId || this.modalData?.objectId],
      parentId: [data.parentId || this.modalData?.parentId],
      epicId: [data.epicId || this.modalData?.epicId],
      selectedSubtask: null,
      estimateValue: [data.estimate?.value || null],
      estimateUnit: [data.estimate?.unit || null],
      dueDate: [data.dueDate ? parseISO(data.dueDate) : null],
      startDate: [data.startDate ? parseISO(data.startDate) : null],
      startDateReminder: [data.startDateReminder || ''],
      startDateReminderState: [data.startDateReminderState],
      // releaseDate: [item.releaseDate || ''],
      releaseVersion: [data.releaseVersion || ''],
      labels: [data.labels || []],
      realTime: [data.realTime || ''],
      ticketMembers: [this.modalData?.selectedMembersIds || data.ticketMembers],
      files: null,
      isBlocked: this.isBlocked,
      bgColor: data.bgColor,
      boardAbbreviation: data.boardAbbreviation,
      counter: data.counter,
      customFields: [data?.customFields || []],
      priority:
        !Number.isInteger(data?.priority) && data.isBlocked
          ? PriorityEnum.Highest
          : !Number.isInteger(data?.priority)
            ? PriorityEnum.Medium
            : data?.priority,
    });

    this.modalData.childrenList = data.childrenList;
    this.childrenList = data.childrenList;
    this.ref.detectChanges();
  }

  initEstimationSession(): void {
    this.store
      .select(BoardsState.getEstimateSession)
      .pipe(untilDestroyed(this))
      .subscribe((estimateSession) => {
        this.estimateSession = estimateSession;

        if (this.estimateSession && this.estimateSession?.members) {
          this.estimationMinMaxAvg = this.calculateMinMaxAvg(this.estimateSession.members);
          this.estimateSessionMembers = this.estimateSession.members.map((member) => {
            let foundUser;

            if (this.modalData?.object === 'spaces') {
              foundUser = this.spaceUsers.find((user) => user._id === member.userId);
            } else if (this.modalData?.object === 'projects') {
              foundUser = this.projectUsers.find((user) => user._id === member.userId);
            }

            return { ...member, ...{ user: foundUser } };
          });

          this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
            if (queryParams?.estimation && this.estimateSessionMembers.length) {
              const { _id } = this.store.selectSnapshot(AuthState.getUser);
              const isUserJoined = this.estimateSessionMembers.find(
                (member) => member.userId === _id,
              );

              if (!isUserJoined) {
                this.joinToEstimateSession();
              }

              this.currentTab = 'estimation';
            }
          });

          this.estimationCreatorLock.patchValue(estimateSession.isLocked, {
            emitEvent: false,
          });
        }
        this.ref.markForCheck();
      });
  }

  setTicketData(): void {
    if (
      this.ticketData.id &&
      !this.ticketData.isCopyTicket &&
      !this.isMinimized &&
      !this.ticketData?.draft
    ) {
      this.routerQueryService.update({ ticket: this.ticketData.id });
    }

    this.getTicketsShortList();
    this.isCopyTicket = this.ticketData.isCopyTicket;

    const rawTicketData = JSON.parse(JSON.stringify(this.ticketData));

    this.modalData = this.ticketData;

    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };

    this.limitToAttachmentName = this.platform === 'web' ? 48 : 24;
    this.descriptionAttachmentsBlob = [];
    this.fileData = [];

    this.store
      .select(BoardsState.getEpicsList)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.epicsList = res?.filter((epic) => !epic.isDeleted);
      });

    this.chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === rawTicketData.objectId)?._id;

    if (this.modalData.id) {
      this.handleCtrlEnter = true;

      this.store
        .select(BoardsState.getBoardSettings)
        .pipe(untilDestroyed(this))
        .subscribe((settings) => {
          if (settings) {
            this.settings = settings;
            this.ref.detectChanges();
          }
        });

      combineLatest([
        this.store.dispatch(new TicketsGetById({ id: this.ticketData.id })),
        this.store.dispatch(new TicketsGetChecklistItems({ id: this.ticketData.id })),
      ])
        .pipe(untilDestroyed(this))
        .subscribe(
          (dataResponse) => {
            const [dispatchData] = dataResponse;
            const ticketUpdateBy =
              this.ticket?.changesHistory[this.ticket?.changesHistory?.length - 1 || 0]?.updatedBy;
            const data = dispatchData;
            if (!this.ticketData?.draft) {
              this.ticket = data.Boards.ticket;
              this.modalData.data = data.Boards.ticket;
              if (this.modalData.data.parentId) {
                this.store
                  .dispatch(new TicketsGetById({ id: this.modalData.data.parentId }))
                  .pipe(untilDestroyed(this))
                  .subscribe((data) => {
                    this.parentTask = data.Boards.ticket;
                    this.epicBreadcrumb = this.epicsList?.find(
                      (epic) => epic._id === this.parentTask.epicId,
                    );
                  });
              }
            } else {
              this.ticket = {
                ...this.ticketData.draft.ticket,
                chatMessage: data.Boards.ticket.chatMessage,
              };
              this.modalData.data = this.ticketData.draft.ticket;
              if (this.modalData.data.parentId) {
                this.store
                  .dispatch(new TicketsGetById({ id: this.modalData.data.parentId }))
                  .pipe(untilDestroyed(this))
                  .subscribe((data) => {
                    this.parentTask = data.Boards.ticket;
                    this.epicBreadcrumb = this.epicsList?.find(
                      (epic) => epic._id === this.parentTask.epicId,
                    );
                  });
              }
            }

            this.store
              .select(BoardsState.getTicketsList)
              .pipe(untilDestroyed(this))
              .subscribe((res) => {
                this.boardTickets = res.filter((ticket) => !ticket.isDeleted && !ticket.isEpic);
              });

            const projectAndSpace$ = this.store.select(ProjectsState.getProject).pipe(
              map((filterFn) => filterFn(rawTicketData.objectId)),
              switchMap((project) =>
                this.store.select(SpacesState.getSpace).pipe(
                  map((filterFn) => filterFn(project?.spaceId)),
                  map((space) => ({ project, space })),
                ),
              ),
            );

            projectAndSpace$.subscribe((data) => {
              this.spaceInfo = data.space;
              this.projectInfo = data.project;

              if (this.spaceInfo?._id) {
                this.spaceChat = this.store
                  .selectSnapshot(ChatsState.getChats)
                  .find((chat) => chat.objectId === this.spaceInfo?._id);
              }
              if (this.projectInfo?._id) {
                this.projectChat = this.store
                  .selectSnapshot(ChatsState.getChats)
                  .find((chat) => chat.objectId === this.projectInfo?._id);
              }
            });

            this.ticketCreator = this.ticket.ownerUserId;
            this.ticketUpdatedBy = ticketUpdateBy || this.ticket.ownerUserId;
            this.ticketHistory = this.ticket.changesHistory;
            this.ticketCreatedAt = this.ticket.created_at;
            this.ticketUpdatedAt = this.ticket.updated_at;
            this.selectedLabels = this.ticket.labels;
            this.selectedLabelsForDraft.next(this.ticket.labels);

            this.epicBreadcrumb = this.epicsList?.find(
              (epic) => epic._id === this.modalData.data.epicId,
            );
            this.epicsList = this.epicsList?.filter((epic) => !epic.isDeleted);
            this.epicLabel = this.epicsList?.find(
              (ticket) => this.ticket && ticket._id === this.ticket.epicId,
            );

            const childrenIds = this.childrenList?.map((item) => item._id);
            if (childrenIds?.length) {
              this.tickets = this.tickets?.filter((item) => !childrenIds.includes(item._id));
            }

            this.actions
              .pipe(untilDestroyed(this), ofActionDispatched(ThreadsSocketNewMessage))
              .subscribe(({ payload }) => {
                if (
                  this.ticketData?.draft &&
                  !this.message &&
                  this.ticketData.draft.ticketId === payload.message?.linkObjectId
                ) {
                  this.message = payload.message;
                }

                if (!this.message && this.ticket?._id === payload.message?.linkObjectId) {
                  this.message = payload.message;
                }
              });

            this.usersInfoSub$?.unsubscribe();
            this.usersInfoSub$ = this.store
              .select(UsersState.getUsersInfo)
              .pipe(untilDestroyed(this))
              .subscribe((res) => {
                const userWithName: any = res[this.ticketCreator];
                const updatedUserWithName: any = res[this.ticketUpdatedBy];
                if (userWithName?.userName) {
                  this.ticketCreatorName = userWithName.userName;
                }
                this.ticketUpdatedByName = updatedUserWithName?.userName
                  ? updatedUserWithName.userName
                  : '';
                this.ticketSelectParent.next(this.tickets.filter((ticket) => !ticket.parentId));

                if (this.ticketHistory) {
                  this.ticketHistory = this.ticketHistory
                    .map((item) => ({
                      ...item,
                      updaterName: res[item.updatedBy]?.userName,
                    }))
                    .sort((a, b) => moment(b.updated_at).unix() - moment(a.updated_at).unix());
                }
              });

            this.modalData.selectedMembersIds = this.ticket.ticketsMembers?.map(
              (member) => member.userId,
            );

            this.getColumns(this.modalData.data.columnId);

            this.prevTicketStatus = this.modalData.data.columnId;
            this.buildForm({ ...this.modalData.data });

            if (this.modalData?.data?.fileData?.length) {
              this.fileData = JSON.parse(JSON.stringify(this.modalData?.data?.fileData));
            }

            this.ref.detectChanges();

            setTimeout(() => {
              if (this.ticket?.chatMessage) {
                this.message = this.ticket.chatMessage;
                this.threadId = this.message.threadId;
                this.relatedChatId = this.message.chatId;
              } else {
                this.message = null;
                this.threadId = undefined;
                this.relatedChatId = undefined;
              }
            }, 0);
          },
          (err) => {
            this.closeHandler();
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-success'));
          },
        );
    } else {
      if (this.ticketData?.draft?.ticket) {
        this.modalData.data = this.ticketData.draft.ticket;
        if (this.ticketData.draft.ticket.labels?.length) {
          this.selectedLabelsForDraft.next(this.ticketData.draft.ticket.labels);
        }
      }

      if (this.ticketData?.fileData?.length) {
        this.fileData = JSON.parse(JSON.stringify(this.ticketData.fileData));
      }

      if (this.modalData?.data?.fileData?.length) {
        if (this.ticketData.ticketCreatedFromRecord) {
          this.addFilesToAttachment(
            this.modalData.data.fileData.map((fileData) => fileData.file) as unknown as FileList,
          );
        } else {
          this.fileData = JSON.parse(JSON.stringify(this.modalData.data.fileData));
          this.ref.detectChanges();
        }
      }
      this.handleCtrlEnter = true;

      this.ticketCreator = this.modalData.draft
        ? this.userData._id
        : this.modalData.data?.ownerUserId
          ? this.modalData.data.ownerUserId
          : this.modalData.ticketCreator;
      this.ticketUpdatedBy = '';
      this.ticketUpdatedByName = '';

      if (!this.ticketData?.draft?.ticket) {
        this.modalData.selectedMembersIds = [];
      }

      this.modalData.boardColorClass = '';
      this.buildForm({ ...this.modalData.data });

      if (this.ticketData.chatMessageId) {
        // creating a ticket from a chat message
        this.ticketForm.patchValue({
          description: this.ticketData?.data?.description,
        });
        this.modalData.chatMessageId = this.ticketData.chatMessageId;
      } else if (this.ticketData.noteId) {
        // creating a ticket from a note
        this.ticketForm.patchValue({
          description: this.ticketData?.data?.description,
        });
        this.modalData.noteId = this.ticketData.noteId;
      }

      this.usersInfoSub$?.unsubscribe();
      this.usersInfoSub$ = this.store
        .select(UsersState.getUsersInfo)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.ticketCreatorName = res[this.ticketCreator].userName;
        });

      this.getColumns(this.modalData?.data?.columnId);
    }

    this.spaces = this.store
      .select(SpacesState.getLoadedSpaces)
      .pipe(
        map((result) =>
          result.filter((item) =>
            this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate'),
          ),
        ),
      );

    this.projects = combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ]).pipe(
      map(([spaces, projects]) =>
        projects
          .filter((item) =>
            this.checkPermissionPipe.transform('projects::' + item._id + '::calendarEventCreate'),
          )
          .map((item) => this.addSpaceAvatarForProjects(spaces, item)),
      ),
    );

    this.mentionThreadMembers = null;

    switch (rawTicketData.object) {
      case 'spaces':
        const space = this.store.selectSnapshot(SpacesState.getSpace)(rawTicketData.objectId);
        const getSpaceUsers = () => {
          this.usersSub$ = this.store
            .dispatch(
              new SpaceGetUsersList({
                id: rawTicketData.objectId,
                exists: true,
              }),
            )
            .pipe(untilDestroyed(this))
            .subscribe(
              (res) => this.getThreadMentions(res.Spaces.users),
              (error) => console.error(error),
            );
        };
        if (space) {
          const users = this.store.selectSnapshot(SpacesState.getUsers);
          if (users.length) {
            this.getThreadMentions(users);
          } else {
            getSpaceUsers();
          }
        } else {
          getSpaceUsers();
        }

        this.users$ = this.store.select(SpacesState.getUsers);
        break;
      case 'projects':
        const project = this.store.selectSnapshot(ProjectsState.getProject)(rawTicketData.objectId);
        const getProjectUsers = () => {
          this.usersSub$ = this.store
            .dispatch(
              new ProjectGetUsersList({
                id: rawTicketData.objectId,
                exists: true,
              }),
            )
            .pipe(untilDestroyed(this))
            .subscribe((res) => this.getThreadMentions(res.Projects.users));
        };
        if (project) {
          const users = this.store.selectSnapshot(SpacesState.getUsers);
          if (users.length) {
            this.getThreadMentions(users);
          } else {
            getProjectUsers();
          }
        } else {
          getProjectUsers();
        }

        this.users$ = this.store.select(ProjectsState.getUsers);
        break;
    }

    if (rawTicketData.object && rawTicketData.objectId) {
      const labels = this.store.selectSnapshot(BoardsState.getTicketsLabels);
      if (
        !labels.length ||
        !labels.some(
          (label) =>
            label.object === rawTicketData.object || label.objectId === rawTicketData.objectId,
        )
      ) {
        this.store.dispatch(
          new TicketsLabelsGet({
            object: rawTicketData.object,
            objectId: rawTicketData.objectId,
          }),
        );
      }
    }
    this.store
      .select(BoardsState.getTicketsLabels)
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.labels = [...res]));

    this.addLabelPromise = (label) => {
      return new Promise((resolve) => {
        this.loading = true;

        const body = {
          label: label,
          object: rawTicketData.object,
          objectId: rawTicketData.objectId,
        };
        this.store
          .dispatch(new TicketsLabelCreate(body))
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            if (res.Boards?.lastCreatedLabel) {
              resolve(res.Boards.lastCreatedLabel);
              this.loading = false;
            }
          });
      });
    };

    this.addSubtaskPromise = (task) => {
      return new Promise((resolve) => {
        this.selectedSubtask = { _id: 'new', title: task };
        resolve({ _id: 'new', title: task });
      });
    };

    this.submitButtonTitle =
      this.ticketData.isEpic && this.modalData.id
        ? this.translocoService.translate('modals.board-ticket.btn-save')
        : this.isCopyTicket
          ? this.translocoService.translate('modals.board-ticket.btn-copy-ticket')
          : this.ticketData.isEpic
            ? this.translocoService.translate('modals.board-ticket.btn-create-epic')
            : this.modalData.id && this.modalData.id !== ''
              ? this.translocoService.translate('modals.board-ticket.btn-save')
              : this.translocoService.translate('modals.board-ticket.btn-create');
    this.pageTitle =
      this.ticketData.isEpic && this.modalData.id
        ? this.translocoService.translate('modals.board-ticket.title-edit-epic')
        : this.ticketData.isEpic
          ? this.translocoService.translate('modals.board-ticket.title-create-epic')
          : !this.modalData.id
            ? this.translocoService.translate('modals.board-ticket.title-create-ticket')
            : this.isCopyTicket
              ? this.translocoService.translate('modals.board-ticket.title-copy-ticket')
              : this.translocoService.translate('modals.board-ticket.title-edit-ticket');
    this.checkScrollContainer();
  }

  getTickets(list: Array<TicketsDbDto>) {
    const ticket = list.find((t) => t._id === this.ticketData.id);
    const isTicketParent = (ticketToCheck: TicketsDbDto) =>
      list.find((t) => t.parentId === ticketToCheck._id);

    if (ticket && list.length) {
      this.parentTask = ticket.parentId ? isTicketParent(ticket) : null;
    }

    if (this.ticketData.draft) {
      this.childrenList = this.ticketData.draft.childrenIds?.map((childrenId) =>
        list.find((t) => t._id === childrenId),
      );
    }

    const columns = this.store.selectSnapshot(BoardsState.getColumnsList);
    if (columns.length) {
      const columnArchiveId = columns.find((column) => column.title === 'ARCHIVE')._id;

      return list
        .filter(
          (item) =>
            item._id !== this.ticketData.id &&
            item.columnId !== this.ticketData.objectId &&
            item.columnId !== columnArchiveId,
        )
        .sort((a, b) => (a.counter > b.counter ? 1 : -1));
    }

    return list
      .filter(
        (item) => item._id !== this.ticketData.id && item.columnId !== this.ticketData.objectId,
      )
      .sort((a, b) => (a.counter > b.counter ? 1 : -1));
  }

  getTicketsShortList(): void {
    if (this.ticketData.tickets) {
      this.tickets = this.getTickets(this.ticketData.tickets);
      this.ref.detectChanges();
    } else {
      this.store
        .dispatch(
          new TicketsGetShortList({
            object: this.ticketData.object,
            objectId: this.ticketData.objectId,
            short: 'dataRoom',
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.tickets = this.getTickets(res.Boards.ticketsInfo);
          this.ref.detectChanges();
        });
    }
  }

  getThreadMentions(users) {
    if (users) {
      const userId = this.store.selectSnapshot(AuthState.getUser)._id;
      this.mentionThreadMembers = [
        'all',
        ...users.filter((item) => item._id !== userId).map((item) => item.userName),
      ];
      this.ref.detectChanges();
    }
  }

  startOrJoinEstimationSession(): void {
    if (!this.estimateSession) {
      this.startEstimationSession();
    }

    if (this.estimateSession) {
      this.joinToEstimateSession();
    }
  }

  saveMemberEstimateValue(): void {
    const foundMember = this.estimateSessionMembers.find(
      (member) => member.userId === this.userData._id,
    );
    const payload = {
      id: this.ticketData.id,
      memberId: foundMember._id,
      body: {
        value: this.estimationUserValue.value,
      },
    };
    this.store
      .dispatch(new TicketsUpdateMemberEstimationSession(payload))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.estimationUserValue.reset();
      });
  }

  clearDataLists() {
    this.users$ = null;
    this.labels = [];
    this.modalData.objectId = null;
    this.modalData.selectedMembersIds = [];
    this.selectedLabels = [];
    this.ticketForm.controls.columnId.setValue(null);
  }

  attachDataLists({ object, objectId }) {
    this.ticketForm.patchValue({ parentId: null });
    this.ticketForm.patchValue({ epicId: null });
    this.modalData.sprintId = null;

    this.clearDataLists();
    this.modalData.object = object;
    this.modalData.objectId = objectId;

    this.initTicketServiceData();
  }

  spacesBtnClicked() {
    if (this.modalData.object !== CalendarControlTypes.Spaces) {
      this.clearDataLists();
    }
    this.modalData.object = CalendarControlTypes.Spaces;
  }

  copyTicketModal() {
    this.activeModal.close();

    const modalRef = this.modal.open(BoardTicketModalComponent, {
      size: 'xl',
      backdrop: 'static',
      scrollable: true,
      keyboard: false,
      beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
    });
    modalRef.componentInstance.ticketData = {
      id: this.ticket._id,
      object: this.modalData.object,
      objectId: this.modalData.objectId,
      parentId: this.ticket.parentId,
      boardColorClass: this.getColumnBoardColor(this.ticket.columnId),
      boardAbbreviation: this.ticket.boardAbbreviation,
      counter: this.ticket.counter,
      tickets: this.tickets,
      isCopyTicket: true,
      sprintId: this.ticket.sprintId || null,
      isNeedToSelectObject: true,
    };
    this.minimizeService.minimizeInit(modalRef);
  }

  async navigateToChat(id) {
    const user = this.store.selectSnapshot(AuthState.getUser);

    if (this.ticketForm.dirty) {
      await this.toDraft();

      if (!environment.subdomains) {
        this.router.navigate([this.routerTenantPipe.transform(`chat/${id}`, user.tenantName)]);
      } else {
        this.router.navigate(['/chat', id]);
      }
    } else {
      if (!environment.subdomains) {
        this.router.navigate([this.routerTenantPipe.transform(`chat/${id}`, user.tenantName)]);
      } else {
        this.router.navigate(['/chat', id]);
      }
    }
    setTimeout(() => {
      this.closeHandler();
    }, 0);
  }

  openModal(modalData) {
    setTimeout(() => {
      const modalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });

      modalRef.componentInstance.ticketData = modalData;
    }, 0);
  }

  routeToTask() {
    if (this.ticketForm.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.openModal({
              id: this.modalData.data.parentId,
              object: this.parentTask?.object,
              objectId: this.parentTask?.objectId,
              isEpic: this.epicLabel ? this.epicLabel.isEpic : false,
              isExpand: false,
            });
            this.removeDraft();
            this.closeHandler();
          }

          if (result === 'isConfirmed') {
            this.submitForm();
            this.openModal({
              id: this.modalData.data.parentId,
              object: this.parentTask?.object,
              objectId: this.parentTask?.objectId,
              isEpic: this.epicLabel ? this.epicLabel.isEpic : false,
              isExpand: false,
            });
            this.removeDraft();
            this.closeHandler();
          }
        },
        () => {},
      );
    } else {
      this.openModal({
        id: this.modalData.data.parentId,
        object: this.parentTask?.object,
        objectId: this.parentTask?.objectId,
        isEpic: this.epicLabel ? this.epicLabel.isEpic : false,
        isExpand: false,
      });

      this.removeDraft();
      this.closeHandler();
    }
  }

  routeToEpic() {
    if (this.ticketForm.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.openModal({
              id: this.epicBreadcrumb._id,
              object: this.epicBreadcrumb?.object,
              objectId: this.epicBreadcrumb?.objectId,
              isEpic: true,
              boardAbbreviation: this.epicBreadcrumb.boardAbbreviation,
              counter: this.epicBreadcrumb.counter,
              tickets: this.boardTickets,
            });
            this.removeDraft();
            this.closeHandler();
          }

          if (result === 'isConfirmed') {
            this.submitForm();
            this.openModal({
              id: this.epicBreadcrumb._id,
              object: this.epicBreadcrumb?.object,
              objectId: this.epicBreadcrumb?.objectId,
              isEpic: true,
              boardAbbreviation: this.epicBreadcrumb.boardAbbreviation,
              counter: this.epicBreadcrumb.counter,
              tickets: this.boardTickets,
            });
            this.removeDraft();
            this.closeHandler();
          }
        },
        () => {},
      );
    } else {
      this.openModal({
        id: this.epicBreadcrumb._id,
        object: this.epicBreadcrumb?.object,
        objectId: this.epicBreadcrumb?.objectId,
        isEpic: true,
        boardAbbreviation: this.epicBreadcrumb.boardAbbreviation,
        counter: this.epicBreadcrumb.counter,
        tickets: this.boardTickets,
      });
      this.removeDraft();
      this.closeHandler();
    }
  }

  projectsBtnClicked() {
    if (this.modalData.object !== CalendarControlTypes.Projects) {
      this.clearDataLists();
    }
    this.modalData.object = CalendarControlTypes.Projects;
  }

  public updateEstimateSession(): void {
    const payload = {
      estimationSessionId: this.estimateSession._id,
      id: this.ticketData.id,
      body: {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        isLocked: this.estimationCreatorLock.value,
      },
    };

    this.store
      .dispatch(new TicketsUpdateEstimationSession(payload))
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public memberAnswerStatusForCurrentUser(member): string {
    return member.value ? member.value : '?';
  }

  public memberAnswerStatusForGuest(member): string {
    return member.value ? '✅' : '?';
  }

  public clearEstimationSession(): void {
    this.estimateSession = null;
    this.estimateSessionMembers = [];
  }

  private initializeEstimateSocket(): void {
    this.socket = this.socketsService.get();
    this.socket.on(this.socketEstimateSessionUpdateEvent, (estimateSession) => {
      this.store.dispatch(new TicketsSocketUpdateEstimationSession(estimateSession));
    });
  }

  private getColumns(columnId: string = null): void {
    this.store
      .dispatch(
        new ColumnsGetList({
          object: this.modalData.object,
          objectId: this.modalData.objectId,
          isInternalState: true,
        }),
      )
      .pipe(untilDestroyed(this), take(1))
      .subscribe((data) => {
        this.ticketService.columns$.pipe(untilDestroyed(this)).subscribe((columns) => {
          this.prepareColumns(columns, columnId);
        });
      });
  }

  private skipEscape(): boolean {
    return this.skipEscapeControls.includes(this.focusedControl);
  }

  private getEstimateSession(): void {
    if (this.ticketData.id) {
      const payload = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        id: this.ticketData.id,
      };

      this.isHiddenEstimateSession = true;
      this.store
        .dispatch(new TicketsGetEstimationSession(payload))
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            if (
              this.estimateSession &&
              this.estimateSession.isLocked &&
              this.estimateSession.members
            ) {
              this.estimationMinMaxAvg = this.calculateMinMaxAvg(this.estimateSession.members);
            }
            this.isHiddenEstimateSession = false;
          },
          () => (this.isHiddenEstimateSession = true),
        );
    }
  }

  private startEstimationSession(): void {
    this.estimationIsLoadingStart = true;
    const payload = {
      id: this.ticketData.id,
      body: {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
      },
    };

    this.store
      .dispatch(new TicketsCreateEstimationSession(payload))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.joinToEstimateSession();
        this.estimationIsLoadingStart = false;
      });
  }

  private joinToEstimateSession(): void {
    const payload = {
      id: this.ticketData.id,
      body: {
        value: 0,
      },
    };

    this.store.dispatch(new TicketsJoinMemberToEstimationSession(payload));
  }

  private calculateMinMaxAvg(members): {
    min: number;
    max: number;
    avg: number;
  } {
    const values = members.map((member) => member.value).filter(Boolean);

    if (values.length === 0) {
      return this.resetToDefaultValueMinMaxAvg();
    }

    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = Math.round(values.reduce((a, b) => a + b, 0) / values.length);
    return { min, max, avg };
  }

  private resetToDefaultValueMinMaxAvg(): {
    min: number;
    max: number;
    avg: number;
  } {
    return { min: 0, max: 0, avg: 0 };
  }

  setFocusedControl(dataTabindex: string): void {
    this.focusedControl = dataTabindex;
    this.tabIndexes.find((element, index) => {
      if (element.tabIndex === dataTabindex) {
        this.curTabIndex = index;
        return element.tabIndex === dataTabindex;
      }
    });
  }

  private handleTabKey(forward: boolean): void {
    let control: any;

    if (!forward) {
      this.curTabIndex > 0 ? this.curTabIndex-- : (this.curTabIndex = this.maxTabIndex);
    } else {
      this.curTabIndex < this.maxTabIndex ? this.curTabIndex++ : (this.curTabIndex = 0);
    }

    control = <HTMLInputElement>(
      document.querySelector('[data-tabindex="' + this.tabIndexes[this.curTabIndex].tabIndex + '"]')
    );

    this.setFocusOnControl(control);
  }

  private setFocusOnControl(control: any): void {
    try {
      if (this.tabIndexes[this.curTabIndex].type === 'select') {
        this.setFocusOnObjectSelect(this.tabIndexes[this.curTabIndex].tabIndex);
      } else if (this.tabIndexes[this.curTabIndex].type === 'quill-editor') {
        switch (this.tabIndexes[this.curTabIndex].tabIndex) {
          case 'tab-index-description':
            this.editorDescription.focus();
            break;
        }
      } else {
        control.focus();
      }
    } catch (error) {}
  }

  private setFocusOnObjectSelect(object: string): void {
    const element = document
      .querySelector(`[data-tabindex="${object}"]`)
      .firstElementChild.firstElementChild.lastElementChild.getElementsByTagName('input');

    if (element.item(0)) {
      element.item(0).focus();
    }
  }

  private addSpaceAvatarForProjects(spaces, item) {
    const space = spaces.find((s) => s?._id === item?.spaceId);

    if (space) {
      item = { ...item, space: { avatarUrl: space.avatarUrl } };
    }

    return item;
  }

  pin() {
    if (this.ticketData.draft) {
      this.isPinned = true;
    } else {
      this.pinForChat();
    }
  }

  pinForChat(): void {
    if (this.chatId) {
      this.isLoadingPin = true;
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              linkDocument: 'tickets',
              linkDocumentId: this.ticketData?.draft
                ? this.ticketData?.draft.ticketId
                : this.ticket._id,
              object: this.modalData.object,
              objectId: this.modalData.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = true;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    }
  }

  unPin() {
    if (this.ticketData.draft) {
      this.isPinned = false;
    } else {
      this.unPinForChat();
    }
  }

  unPinForChat(): void {
    if (this.chatId) {
      const ticketId = this.ticketData?.draft ? this.ticketData?.draft.ticketId : this.ticket._id;
      const id =
        this.store
          .selectSnapshot(ChatsState.getPinMessages)
          .find((pin: any) => pin.linkDocument && pin.linkTicket?._id === ticketId)?._id ||
        this.pinnedMessageId;

      ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
        subject: this.translocoService.translate('alert.unpin-subject'),
        text: this.translocoService.translate('alert.unpin-text'),
        confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
        platform: this.platform,
      }).then(() => {
        this.isLoadingPin = true;
        this.store
          .dispatch(
            new ChatsDeletePin({
              id: this.chatId,
              pinnedMessageId: id,
              object: this.modalData.object,
              objectId: this.modalData.objectId,
            }),
          )
          .pipe(untilDestroyed(this))
          .subscribe(
            () => {
              this.isPinned = false;
              this.isLoadingPin = false;
            },
            () => {
              this.isLoadingPin = false;
            },
          );
      });
    }
  }

  /**
   * Close modal handler
   */
  close(force = false) {
    if (this.ticketForm.dirty && !force) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.removeDraft();
            this.closeHandler();
          }

          if (result === 'isConfirmed') {
            this.submitForm();
            this.removeDraft();
            this.closeHandler();
          }
        },
        () => {},
      );
    } else {
      this.removeDraft();
      this.closeHandler();
    }
  }

  closeHandler(): void {
    this.store.dispatch(new TicketsOpenTicketChange(false));
    this.activeModal.close();
    this.routerQueryService.update({ ticket: null, estimation: null });
    this.handleCtrlEnter = false;
    this.ticket = null;
    this.threadId = null;
    this.message = null;
    this.selectedLabels = null;
    this.clearDataLists();
    this.clearEstimationSession();
    this.ticketService.chatId = null;
  }

  editorDescriptionCreated(editorDescription): void {
    this.editorDescription = editorDescription;
    QuillInitializeService.handleEditorCreated(editorDescription);
  }

  isSafariScreenshot(): boolean {
    return (
      this.editorDescription.root.innerHTML &&
      this.editorDescription.root.innerHTML.includes('<img src="//:0">')
    );
  }

  descriptionBlur() {
    if (!this.isToolbarClicked) {
      this.toggleDescriptionEditVisibility = false;
    }
    this.isToolbarClicked = false;
  }

  handlePaste(event) {
    this.toggleDescriptionEditVisibility = true;

    const clipboardItems = event.clipboardData.items;
    const imageItem: any = Array.from(clipboardItems).find(
      (item: any) => item.type.indexOf('image') !== -1,
    );

    if (imageItem) {
      // Take image from clipboard
      const imageBlob = imageItem.getAsFile();
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      // Make blob to base64
      reader.onloadend = () => {
        const data = reader.result as string;
        // SetTimeout for update current editorDescription
        setTimeout(() => {
          if (this.isSafariScreenshot()) {
            // Replace broken url to base64 url
            this.editorDescription.root.innerHTML = replaceLastSrcWithUrl(
              this.editorDescription.root.innerHTML,
              data,
            );
          }
        }, 0);
      };
    }
  }

  statusSelectChange(selectedValue: ColumnsDbDto): void {
    if (selectedValue?._id) {
      this.modalData.boardColorClass = this.getColumnBoardColor(selectedValue?._id);
    }
  }

  scrollToEnd() {
    const element = this.scrollContainer.nativeElement;
    element.scrollLeft = element.scrollWidth - element.clientWidth;
  }

  checkScrollContainer(): void {
    if (this.scrollContainer) {
      this.scrollToEnd();
    } else {
      setTimeout(() => {
        this.checkScrollContainer();
      }, 100);
    }
  }

  private getColumnBoardColor(columnId: string): string {
    const columnColor: any = this.columns.find((col: any) => col._id === columnId);
    return columnColor?.boardColorClass ? columnColor.boardColorClass : '';
  }

  toggleFlag(): void {
    this.isBlocked = !this.isBlocked;
    this.ticketForm.patchValue({ isBlocked: this.isBlocked });
  }

  toggleCustomize(event?: { isBoardApplyAllTickets: boolean }): void {
    if (event && event?.isBoardApplyAllTickets) {
      this.submitForm(event.isBoardApplyAllTickets);
    }
    this.customiseTicketXY = {
      x: this.customizeTicketButton.nativeElement.offsetLeft,
      y: this.customizeTicketButton.nativeElement.offsetTop + 54,
    };

    this.isShowCustomiseTicket = !this.isShowCustomiseTicket;
  }

  closeCustomize() {
    this.isShowCustomiseTicket = false;
  }

  copyTicketLink(modalData) {
    const host =
      `http${this.env.ssl ? 's' : ''}://` +
      (this.env.main_host || this.env.base_host !== 'localhost:4200'
        ? `${modalData.data.tenantName}.${this.env.main_host}`
        : `${this.env.base_host}/${modalData.data.tenantName}`);
    const title = modalData.data.column?.title;
    const board = title === 'ARCHIVE' ? 'archive' : 'backlog';
    const ticketUrl = `${host}/${modalData.object.slice(0, -1)}/${modalData.objectId}/board/${board}?ticket=${
      modalData.id
    }`;

    this.htmlHelper.copyValueToBuffer(ticketUrl);
    this.toastr.success(
      this.translocoService.translate('toastr.message-link-copied'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  dataHasChanged(data, newData) {
    return !!(
      data.title !== newData.title ||
      data.description !== newData.description ||
      data.columnId !== newData.columnId ||
      data.estimate?.value !== newData.estimateValue ||
      data.estimate?.unit !== newData.estimateUnit ||
      data.releaseVersion !== newData.releaseVersion ||
      data.startDate !== newData.startDate ||
      data.startDateReminder !== newData.startDateReminder ||
      data.isBlocked !== newData.isBlocked ||
      data.object !== newData.object ||
      data.objectId !== newData.objectId ||
      data.parentId !== newData.parentId ||
      data.epicId !== newData.epicId ||
      data.labels?.length !== newData.labels?.length ||
      data.ticketsMembers?.length !== newData.ticketMembers?.length ||
      !data.labels.every((v, i) => v === newData.labels[i]) ||
      !data.ticketsMembers
        .map((item) => item.userId)
        .every((v, i) => v === newData.ticketMembers[i]) ||
      newData.files?.length ||
      data.dueDate !== newData.dueDate ||
      data.startDate !== newData.startDate
    );
  }

  onChangeCustomFieldData($event: { [p: string]: any }) {
    if (this.parentIsConflict.value && $event?.Parent !== this.parentIsConflict.value.id) {
      this.parentIsConflict.next({
        id: this.parentIsConflict.value.id,
        message: null,
      });
    }
    const remappedCustomFieldData: { [x: string]: any } = {};
    Object.keys($event).forEach((key) => {
      if (LockedFields[key]) {
        if (key === 'Estimation') {
          remappedCustomFieldData['estimateValue'] = $event[key][key];
          remappedCustomFieldData['estimateUnit'] = $event[key].unit;
        } else {
          remappedCustomFieldData[LockedFields[key]] = $event[key];
        }
      }
    });
    if (this.fields.length) {
      const customFields = this.fields.filter(
        (field) => !field.isLocked && field.isVisible && $event[field.title] !== null,
      );
      this.customFieldsValues = customFields.map((customField): TicketsFieldsValuesReqDto => {
        const customFieldFromForm = this.ticketForm.value?.customFields?.find(
          (field) => field?.ticketFieldId === customField._id,
        );
        if (customFieldFromForm) {
          // Field to update

          if (
            customField.type === FieldType.Unit &&
            $event[customField.title]?.[customField.title]
          ) {
            return {
              _id: customFieldFromForm._id,
              value: String($event[customField.title][customField.title]),
              unit: $event[customField.title].unit,
              ticketFieldId: customField._id,
            };
          }

          if (customField.type === FieldType.MultiSelect) {
            return {
              _id: customFieldFromForm._id,
              value: JSON.stringify($event[customField.title]),
              ticketFieldId: customField._id,
            };
          }

          return {
            _id: customFieldFromForm._id,
            value: $event[customField.title],
            ticketFieldId: customField._id,
          };
        } else {
          // Field to create

          if (customField.type === FieldType.Unit && $event[customField.title]) {
            return {
              value: String($event[customField.title][customField.title]),
              unit: $event[customField.title].unit,
              ticketFieldId: customField._id,
            };
          }

          if (customField.type === FieldType.MultiSelect) {
            return {
              value: JSON.stringify($event[customField.title]),
              ticketFieldId: customField._id,
            };
          }

          return {
            value: $event[customField.title],
            ticketFieldId: customField._id,
          };
        }
      });

      this.customFieldsValues = this.customFieldsValues.filter((fieldValue) => {
        const fieldConfig = customFields.find(
          (customField) => customField._id === fieldValue.ticketFieldId,
        );
        const fieldValueInForm = (this.ticketForm.value?.customFields || []).find(
          (value) => value.ticketFieldId === fieldValue.ticketFieldId,
        );
        if (fieldConfig.type === FieldType.Unit && $event[fieldConfig.title]) {
          return (
            !!$event[fieldConfig.title][fieldConfig.title] &&
            !!$event[fieldConfig.title]?.unit &&
            (fieldValueInForm?.value !== $event[fieldConfig.title][fieldConfig.title] ||
              fieldValueInForm?.unit !== $event[fieldConfig.title]?.unit)
          );
        } else if (fieldConfig.type === FieldType.MultiSelect) {
          return (
            $event[fieldConfig.title] !== null &&
            fieldValueInForm?.value !== JSON.stringify($event[fieldConfig.title] || [])
          );
        } else {
          return (
            $event[fieldConfig.title] !== null &&
            fieldValueInForm?.value !== $event[fieldConfig.title]
          );
        }
      });
    }

    this.dataChangedInCustomFields = this.isChanged(
      this.ticketForm.value,
      remappedCustomFieldData,
      Object.keys(this.ticketForm.value),
    );

    if (
      Object.keys(this.dataChangedInCustomFields).length === 1 &&
      this.dataChangedInCustomFields.hasOwnProperty('parentId')
    ) {
      this.ticketForm.patchValue({
        parentId: this.dataChangedInCustomFields.parentId,
      });
    }
    this.isParentHasValueInCustomField = !!remappedCustomFieldData?.parentId;
  }

  detectValidForm(event: string[]) {
    this.validateCustomField = [...event];
  }

  isChanged(
    left: Record<string, any>,
    right: Record<string, any>,
    params: string[],
  ): { [x: string]: any } {
    const obj: { [x: string]: any } = {};
    for (const param of params) {
      if (left[param] === undefined || right[param] === undefined) {
        continue;
      }

      if (left[param] !== right[param]) {
        obj[param] = right[param];
      }
    }
    return obj;
  }

  submitForm(isBoardApplyTicketSettings?: boolean) {
    this.hasError = false;

    if (this.descriptionAttachmentsBlob?.length) {
      this.ticketForm.patchValue({ files: this.descriptionAttachmentsBlob });
    }
    if (!this.modalData.id) {
      // console.log('submit create');
      this.addTicketSubmit(isBoardApplyTicketSettings); // Create ticket
    } else if (this.isCopyTicket) {
      // console.log("submit is copy ticket");
      this.addTicketSubmit();
    } else {
      // console.log('submit update');
      this.editTicketSubmit(isBoardApplyTicketSettings); // Update ticket
    }
  }

  prepareForCreateOrUpdate(isCreate = false) {
    const customFieldsValues = [...this.customFieldsValues];
    const checklist = this.store.selectSnapshot(BoardsState.getChecklist);
    const checklistFilter = checklist.map((item) => {
      return {
        _id: isCreate ? undefined : item._id,
        text: item.text,
        isCompleted: item.isCompleted,
      };
    });

    // TODO: Make for this interface
    let newData: any = { ...this.ticketForm.value };

    if (isCreate) {
      if (this.modalData?.chatMessageId) {
        // creating a ticket from a chat message
        newData.chatMessageId = this.modalData.chatMessageId;
      } else if (this.modalData?.noteId) {
        // creating a ticket from a note
        newData.noteId = this.modalData.noteId;
      }

      if (this.modalData?.sprintId) {
        newData.sprintId = this.modalData.sprintId;
      }

      if (this.modalData?.isEpic) {
        newData.isEpic = this.modalData.isEpic;
        newData.columnId = this.modalData.objectId;
      }

      if (!this.modalData.isEpic && Object.keys(this.dataChangedInCustomFields).length) {
        newData = { ...newData, ...this.dataChangedInCustomFields };
      }

      const addData = Object.assign(
        {
          ...newData,
          object: this.modalData.object,
          objectId: this.modalData.objectId,
          order: 0,
        },
        this.addTime(newData),
      );

      this.deleteTimeFields(newData);

      if (addData.ticketMembers?.length === 0) {
        addData.isNoMembers = true;
      }

      addData.allDay = this.timeIsEmpty;

      if (!this.modalData.isEpic && Object.keys(this.dataChangedInCustomFields).length) {
        if (this.dataChangedInCustomFields['estimateValue']) {
          addData.estimate.value = this.dataChangedInCustomFields['estimateValue'];
        }

        if (this.dataChangedInCustomFields['estimateUnit']) {
          addData.estimate.unit = this.dataChangedInCustomFields['estimateUnit'];
        }
      }

      if (this.modalData.isNeedToSelectObject && this.fileData?.length) {
        addData.attachmentIds = this.fileData.map((file) => file._id);
      }

      let customFieldsForNewTicket;
      if (this.isCopyTicket) {
        const allUniqueCustomFieldIds: string[] = [
          ...new Set(
            [...this.ticketForm.value.customFields, ...customFieldsValues].map(
              ({ ticketFieldId }) => ticketFieldId,
            ),
          ),
        ];
        customFieldsForNewTicket = allUniqueCustomFieldIds.map((ticketFieldId) => {
          const changedFieldValue = customFieldsValues.find(
            (customField) => customField.ticketFieldId === ticketFieldId,
          );
          if (changedFieldValue) {
            if (changedFieldValue._id) {
              delete changedFieldValue._id;
            }
            return changedFieldValue;
          }

          const newFieldValue = this.ticketForm.value.customFields.find(
            (customField) => customField.ticketFieldId === ticketFieldId,
          );

          if (newFieldValue.hasOwnProperty('_id')) {
            const { _id, ...rest } = newFieldValue;
            return rest;
          }
          return newFieldValue;
        });
      } else {
        customFieldsForNewTicket = customFieldsValues;
      }

      addData.dueDate = addData.dueDate?.toISOString();
      addData.startDate = addData.startDate?.toISOString();
      addData.checkListItems = checklistFilter;
      addData.customFields = customFieldsForNewTicket;

      addData.startDateReminder = addData.startDateReminder
        ? this.mapStartDateReminder(addData.startDateReminder)
        : '';

      delete addData.counter;

      return addData;
    }

    if (!this.modalData.isEpic && Object.keys(this.dataChangedInCustomFields).length) {
      this.ticketForm.patchValue({ ...this.dataChangedInCustomFields });
      newData = { ...newData, ...this.dataChangedInCustomFields };
    }

    if (!newData.bgColor) {
      newData.bgColor = 'null';
    }

    newData.allDay = this.timeIsEmpty;

    if (!newData.ticketMembers) {
      newData.ticketMembers = [];
    }

    if (newData.ticketMembers.length === 0) {
      newData.isNoMembers = true;
    }

    if (!newData.labels || newData.labels.length === 0) {
      newData.isNoLabels = true;
    }

    if (!newData.estimateUnit && !newData.estimateValue) {
      newData.estimate = 'null';
    }

    const addTimeFields = this.addTime(newData);

    const fieldsToDelete = [
      '_id',
      'object',
      'objectId',
      'realTime',
      'boardAbbreviation',
      'counter',
    ];
    this.deleteFields(newData, fieldsToDelete);

    if (typeof newData.columnId !== 'undefined' && !newData.columnId) {
      delete newData.columnId;
    }

    const body = Object.assign(
      {
        ...newData,
        object: this.modalData.object,
        objectId: this.modalData.objectId,
      },
      addTimeFields,
    );

    if (!this.modalData.isEpic && Object.keys(this.dataChangedInCustomFields).length) {
      if (this.dataChangedInCustomFields['estimateValue']) {
        body.estimate.value = this.dataChangedInCustomFields['estimateValue'];
      }

      if (this.dataChangedInCustomFields['estimateUnit']) {
        body.estimate.unit = this.dataChangedInCustomFields['estimateUnit'];
      }
    }

    if (customFieldsValues.length) {
      body.customFields = customFieldsValues;
    }

    body.dueDate = body.dueDate ? body.dueDate.toISOString() : 'null';
    body.startDate = body.startDate ? body.startDate.toISOString() : 'null';
    body.startDateReminder = body.startDateReminder
      ? this.mapStartDateReminder(body.startDateReminder)
      : '';
    body.checkListItems = checklistFilter?.length ? checklistFilter : 'null';

    return body;
  }

  handleSaveTicketError(error: {
    message: string;
    parentId?: string;
    wrongSubtaskIds?: Array<string>;
  }): void {
    if (error.parentId) {
      this.parentIsConflict.next({
        message: error.message,
        id: error.parentId,
      });
    }

    if (error?.wrongSubtaskIds) {
      this.wrongSubtaskIds = error.wrongSubtaskIds;
      this.subtasksErrorMessage = error.message;
    }

    this.toastr.error(error.message, this.translocoService.translate('toastr.title-error'));
  }

  isWrongSubTask(subtask: TicketsDbDto): boolean {
    return this.wrongSubtaskIds.some((id) => id === subtask._id);
  }

  async editTicketSubmit(isBoardApplyTicketSettings: boolean) {
    const ticketId = this.modalData.data._id || this.ticketData?.draft?.ticketId;

    if (this.validateCustomField.length) {
      this.toastr.clear();
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-field', {
          text: this.validateCustomField.join(', '),
        }),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (!this.checkTitle()) {
      this.toastr.clear();
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-title'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (this.checkFileSize(this.ticketForm.value.files)) {
      this.toastr.clear();
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-file-size', {
          size: '1GB',
        }),
        this.checkFileSize(this.ticketForm.value.files).name,
      );
      return;
    }

    if (!this.isDueDateValid()) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-due-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (this.ticketData.isEpic && !this.isDateValid()) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-start-date-be-less-due-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    const body = this.prepareForCreateOrUpdate();

    if (
      body.startDateReminderState !== undefined &&
      body.startDateReminderState !== true &&
      body.startDateReminderState !== null &&
      !this.isStartDateValid(body.startDate, body.dueDate)
    ) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-start-date-be-less-due-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (
      body.startDateReminderState !== undefined &&
      body.startDateReminderState !== true &&
      body.startDateReminderState !== null &&
      !this.isReminderOptionValid(body.startDate, body.startDateReminder)
    ) {
      this.toastr.error(
        this.translocoService.translate(
          'toastr.err-message-start-date-reminder-be-less-current-date-and-time',
        ),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    } else {
      console.log('pomin weryfikacje reminder');
    }

    const updateTicket = (newBody) => {
      if (!this.hasError) {
        if (this.dataHasChanged(this.modalData.data, newBody)) {
          const ticketAbbr = `${this.modalData.data.boardAbbreviation}-${this.modalData.data.counter}`;
          this.isUploading = true;
          if (!newBody.parentId) {
            newBody.parentId = 'null';
          }
          if (!newBody.epicId) {
            newBody.epicId = 'null';
          }

          if (isBoardApplyTicketSettings) {
            this.store
              .dispatch(
                new BoardsSetSettings({
                  bgColor: newBody.bgColor,
                  object: newBody.object,
                  objectId: newBody.objectId,
                }),
              )
              .pipe(untilDestroyed(this))
              .subscribe(
                () => {
                  this.handleTicketsColumnsProcessing(null, null, false);
                  this.closeHandler();
                  this.toastr.success(
                    this.translocoService.translate('toastr.message-ticket-updated', {
                      text: ticketAbbr,
                    }),
                    this.translocoService.translate('toastr.title-success'),
                    { enableHtml: true },
                  );
                },
                (err) => {
                  this.isUploading = false;
                  this.toastr.error(
                    err.message,
                    this.translocoService.translate('toastr.title-error'),
                  );
                },
              );
          } else {
            if (this.ticketData?.draft) {
              this.isUploading = true;
              const sharedData = this.prepareDraftSharedBody(body);
              this.store
                .dispatch(
                  new DraftUpdate({
                    id: this.ticketData.draft._id,
                    body: {
                      ...body,
                      ...sharedData,
                    },
                    isNeedToSaveChanges: true,
                  }),
                )
                .pipe(untilDestroyed(this))
                .subscribe(
                  () => {
                    const isPinnedInChat = this.store
                      .selectSnapshot(ChatsState.getPinMessages)
                      .some((pin) => pin.linkTicket?._id === this.ticketData?.draft.ticketId);
                    if (this.isPinned !== isPinnedInChat) {
                      if (this.isPinned && !isPinnedInChat) {
                        this.pinForChat();
                      } else {
                        this.unPinForChat();
                      }
                    }

                    this.isUploading = false;
                    this.activeModal.close();
                  },
                  (error) => {
                    this.handleSaveTicketError(error);
                    this.isUploading = false;
                  },
                );
            } else {
              this.store
                .dispatch(
                  new TicketsUpdate({
                    ticketUpdateId: ticketId,
                    body: newBody,
                  }),
                )
                .pipe(untilDestroyed(this), take(1))
                .subscribe(
                  () => {
                    this.handleTicketsColumnsProcessing(null, null, false);
                    this.closeHandler();
                    this.toastr.success(
                      this.translocoService.translate('toastr.message-ticket-updated', {
                        text: ticketAbbr,
                      }),
                      this.translocoService.translate('toastr.title-success'),
                      { enableHtml: true },
                    );
                  },
                  (err) => {
                    this.isUploading = false;
                    this.toastr.error(
                      err.message,
                      this.translocoService.translate('toastr.title-error'),
                    );
                  },
                );
            }
          }
        }
      }
    };

    const storeTickets = this.store.selectSnapshot(BoardsState.getAllTicketsList);
    if (storeTickets.length && !this.isMinimized && !this.ticketData.draft) {
      const ticketToRecount = storeTickets.find((t) => t._id === ticketId);
      if (ticketToRecount) {
        if (
          ticketToRecount.priority !== this.ticketForm.value.priority ||
          ticketToRecount.columnId !== this.ticketForm.value.columnId
        ) {
          const ticketToUpdate = await this.priorityOrderService.recountTicketOrders(
            {
              ...ticketToRecount,
              columnId: this.ticketForm.value.columnId,
              priority: this.ticketForm.value.priority,
            },
            {
              isChangedPriority:
                ticketToRecount.priority !== this.ticketForm.value.priority &&
                ticketToRecount.columnId === this.ticketForm.value.columnId,
              isChangedColumn: ticketToRecount.columnId !== this.ticketForm.value.columnId,
              isMoveTicketToBoardOrBacklog:
                (ticketToRecount.columnId !== this.ticketForm.value.columnId &&
                  ticketToRecount.columnId === ticketToRecount.objectId) ||
                this.ticketForm.value.columnId === ticketToRecount.objectId,
            },
          );

          if (ticketToUpdate) {
            body.order = ticketToUpdate.order;
            body.orderInBacklog = ticketToUpdate.orderInBacklog;
          }
        }
      }
    }

    if (this.isMinimized || this.ticketData.draft) {
      const ticketToRecount = storeTickets.find((t) => t._id === ticketId);

      if (
        ticketToRecount.priority !== this.ticketForm.value.priority ||
        ticketToRecount.columnId !== this.ticketForm.value.columnId
      ) {
        const ticketToUpdate = await this.priorityOrderService.recountTicketOrders(
          {
            ...ticketToRecount,
            columnId: this.ticketForm.value.columnId,
            priority: this.ticketForm.value.priority,
          },
          {
            isChangedPriority:
              ticketToRecount.priority !== this.ticketForm.value.priority &&
              ticketToRecount.columnId === this.ticketForm.value.columnId,
            isChangedColumn: ticketToRecount.columnId !== this.ticketForm.value.columnId,
            isMoveTicketToBoardOrBacklog:
              (ticketToRecount.columnId !== this.ticketForm.value.columnId &&
                ticketToRecount.columnId === ticketToRecount.objectId) ||
              this.ticketForm.value.columnId === ticketToRecount.objectId,
          },
        );

        if (ticketToUpdate) {
          body.order = ticketToUpdate.order;
          body.orderInBacklog = ticketToUpdate.orderInBacklog;
        }
      }

      updateTicket(body);
      this.isUploading = true;
    } else {
      updateTicket(body);
    }
  }

  async addTicketSubmit(isBoardApplyTicketSettings?: boolean) {
    const hasError = this.validateTicketData();
    if (hasError) {
      return;
    } else {
      this.isUploading = true;
    }

    const addData = this.prepareForCreateOrUpdate(true);

    if (!this.isStartDateValid(addData.startDate, addData.dueDate)) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-start-date-be-less-due-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      this.isUploading = false;
      return;
    }

    if (!this.isReminderOptionValid(addData.startDate, addData.startDateReminder)) {
      this.toastr.error(
        this.translocoService.translate(
          'toastr.err-message-start-date-reminder-be-less-current-date-and-time',
        ),
        this.translocoService.translate('toastr.title-error'),
      );
      this.isUploading = false;

      return;
    }

    const createTicket = (addNewData) => {
      const fieldsToDelete = ['boardAbbreviation', 'counter'];
      this.deleteFields(addNewData, fieldsToDelete);
      if (!this.hasError) {
        const keysToDelete = Object.keys(addNewData).filter(
          (key) => addNewData[key] === null || addNewData[key] === '' || addNewData[key] === 'null',
        );
        const dataToUpdate = Object.assign(
          {},
          ...Object.keys(addNewData)
            .filter((key) => !keysToDelete.includes(key))
            .map((key) => ({ [key]: addNewData[key] })),
        );

        if (this.ticketData?.draft) {
          const sharedData = this.prepareDraftSharedBody(dataToUpdate);
          this.store
            .dispatch(
              new DraftUpdate({
                id: this.ticketData.draft._id,
                body: { ...dataToUpdate, ...sharedData },
                isNeedToSaveChanges: true,
              }),
            )
            .pipe(untilDestroyed(this))
            .subscribe(
              () => {
                this.isUploading = false;
                this.activeModal.close();
              },
              (error) => {
                this.handleSaveTicketError(error);
                this.isUploading = false;
              },
            );

          return;
        }

        this.store
          .dispatch(new TicketsCreate(addNewData))
          .pipe(
            untilDestroyed(this),
            withLatestFrom(this.store.select(BoardsState.getLastAddedTicketId)),
          )
          .subscribe(
            ([res, ticketId]) => {
              const ticket = res.Boards.tickets.find((item) => item._id === ticketId);
              const ticketAbbr = ticket ? `${ticket.boardAbbreviation}-${ticket.counter}` : '';

              if (this.modalData?.showToastMessage || this.modalData?.isCopyTicket) {
                const message = this.modalData.isCopyTicket
                  ? this.translocoService.translate('toastr.message-ticket-copied', {
                      text: ticketAbbr,
                    })
                  : this.translocoService.translate('toastr.message-ticket-created', {
                      text: ticketAbbr,
                    });
                this.toastr.clear();
                this.toastr.success(
                  message,
                  this.translocoService.translate('toastr.title-success'),
                  {
                    enableHtml: true,
                  },
                );
                this.modalData.showToastMessage = false;
              }

              if (isBoardApplyTicketSettings) {
                this.store.dispatch(
                  new BoardsApplyTicketSettings({
                    id: ticketId,
                  }),
                );
              }

              this.handleTicketsColumnsProcessing(null, null, false);
              if (this.modalData.ticketCreatedFromRecord) {
                this.recordService.clearRecord();
              }
              if (this.needCreateNext) {
                this.needCreateNext = false;
                this.ticketForm.reset();
                this.modalData.lastTicketAbbr = ticketAbbr;
                this.routerQueryService.update({ ticket: null });
                this.ticketForm.controls.columnId.setValue(this.boardColumns[0]._id);
                this.ticketForm.controls.priority.setValue(PriorityEnum.Medium);
                this.ticketForm.controls.objectId.setValue(this.modalData.objectId);
                this.descriptionAttachmentsBlob = [];
                this.fileData = [];
              } else {
                this.closeHandler();
              }
            },
            (err) => {
              this.isUploading = false;
              this.needCreateNext = false;
              this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
            },
          );
      } else {
        this.needCreateNext = false;
      }
    };

    if (this.isMinimized || this.isExpanded || this.isCopyTicket || this.ticketData.isConvert) {
      this.isUploading = true;

      const ticket = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        ...addData,
      };

      const ticketToCreate = await this.priorityOrderService.recountTicketOrders(ticket, {
        isCreateTicket: true,
      });

      if (ticketToCreate) {
        addData.order = ticketToCreate.order;
        if (
          typeof ticketToCreate.orderInBacklog === 'number' &&
          ticketToCreate.orderInBacklog > 0
        ) {
          addData.orderInBacklog = ticketToCreate.orderInBacklog;
        }
      }

      createTicket(addData);
    } else {
      const storeTickets = this.store.selectSnapshot(BoardsState.getAllTicketsList);
      if (storeTickets.length && !this.isMinimized) {
        const ticket = {
          object: this.modalData.object,
          objectId: this.modalData.objectId,
          ...addData,
        };
        const ticketToCreate = await this.priorityOrderService.recountTicketOrders(ticket, {
          isCreateTicket: true,
        });

        if (ticketToCreate) {
          addData.order = ticketToCreate.order;
          if (
            typeof ticketToCreate.orderInBacklog === 'number' &&
            ticketToCreate.orderInBacklog > 0
          ) {
            addData.orderInBacklog = ticketToCreate.orderInBacklog;
          }
        }
      }

      createTicket(addData);
    }
  }

  private checkTitle(): boolean {
    return !(this.ticketForm.value.title === '' || this.ticketForm.value.title === undefined);
  }

  private checkFileSize(files: File[]): File | undefined {
    return files?.find((file) => file.size >= this.MAX_FILE_SIZE);
  }

  private checkObjectId(): boolean {
    return !(
      this.ticketForm.value.objectId === null || this.ticketForm.value.objectId === undefined
    );
  }

  private isStartDateValid(startDate, dueDate): boolean {
    if (startDate == undefined || startDate == null) {
      return true;
    }

    if (
      moment(startDate).isValid() &&
      moment(dueDate).isValid() &&
      dueDate !== null &&
      dueDate !== undefined
    ) {
      if (moment(startDate).isBefore(dueDate)) {
        return true;
      } else {
        return false;
      }
    }

    const startDateTime = moment(startDate).tz(this.userData?.timezone);
    const currentTime = moment().tz(this.userData?.timezone);

    console.log('startDate', moment(startDate).format());
    console.log('currentTime', currentTime.format());

    if (moment(startDateTime).isValid()) {
      if (moment(startDateTime).isAfter(currentTime)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private isReminderOptionValid(startDate: string, startDateReminder: string): boolean {
    if (!startDateReminder || startDateReminder.trim() === '') {
      return true;
    }

    const startDateTime = moment(startDate).tz(this.userData?.timezone);
    const currentTime = moment().tz(this.userData?.timezone);

    let reminderTime = moment(startDateTime).subtract(0, 'm');

    switch (startDateReminder) {
      case '0m':
        reminderTime = moment(startDateTime).subtract(0, 'm');
        break;
      case '5m':
        reminderTime = moment(startDateTime).subtract(5, 'm');
        break;
      case '10m':
        reminderTime = moment(startDateTime).subtract(10, 'm');
        break;
      case '15m':
        reminderTime = moment(startDateTime).subtract(15, 'm');
        break;
      case '30m':
        reminderTime = moment(startDateTime).subtract(30, 'm');
        break;
      case '1h':
        reminderTime = moment(startDateTime).subtract(1, 'h');
        break;
      case '2h':
        reminderTime = moment(startDateTime).subtract(2, 'h');
        break;
      case '1d':
        reminderTime = moment(startDateTime).subtract(1, 'd');
        break;
      case '2d':
        reminderTime = moment(startDateTime).subtract(2, 'd');
        break;
      case '1w':
        reminderTime = moment(startDateTime).subtract(1, 'w');
        break;
    }

    console.log('startDateTime', startDateTime.format());
    console.log('reminderTime', reminderTime.format());
    console.log('current date', currentTime.format());

    if (reminderTime.isAfter(currentTime)) {
      return true;
    }

    return false;
  }

  private isDueDateValid(): boolean {
    console.log('isDueDateValid');
    const date = this.ticketForm.controls['dueDate'].value;
    return moment(date).isValid() || date === null;
  }

  private isDateValid(): boolean {
    const start = this.ticketForm.controls['startDate'];
    const end = this.ticketForm.controls['dueDate'];
    return start.value !== null && end.value !== null && start.value < end.value;
  }

  private handleTicketsColumnsProcessing(
    message: string,
    type: string,
    showMessage: boolean = true,
  ): void {
    this.isUploading = false;
    if (showMessage) {
      this.toastr.clear();
      this.toastr.success(message, this.translocoService.translate('toastr.title-success'));
    }
  }

  datePickedWithoutTime(event: boolean): void {
    this.timeIsEmpty = event;
  }

  addTime(data) {
    let addData = {};

    if (data.estimateValue || data.estimateUnit) {
      if (data?.estimateValue <= 0 && !this.ticketData?.draft) {
        this.toastr.error(
          this.translocoService.translate('toastr.err-message-estimation-value'),
          this.translocoService.translate('toastr.title-error'),
        );
        this.hasError = true;
      } else if (!data.estimateUnit && !this.ticketData?.draft) {
        this.toastr.error(
          this.translocoService.translate('toastr.err-message-estimation-unit'),
          this.translocoService.translate('toastr.title-error'),
        );
        this.hasError = true;
      } else {
        addData = Object.assign(addData, {
          estimate: { value: data.estimateValue, unit: data.estimateUnit },
        });
      }
    }

    if (data.realTime) {
      const realTime = this.formatTime(data.realTime);
      if (realTime !== 0) {
        addData = Object.assign(addData, { realTime });
      }
    }

    /* if (
      typeof data.releaseDate !== 'undefined' &&
      data.releaseDate !== null &&
      !isNaN(data.releaseDate.year) &&
      !isNaN(data.releaseDate.month) &&
      !isNaN(data.releaseDate.day)
    ) {
      const releaseDate = new Date(data.releaseDate.year, data.releaseDate.month - 1, data.releaseDate.day, 0, 0, 0, 0);
      addData = Object.assign(addData, { releaseDate: releaseDate.toISOString() });
    } else {
      addData = Object.assign(addData, { releaseDate: null });
    } */

    return addData;
  }

  formatTime(timeString) {
    const regexTime = /((?<days>\d+)\s*(d))|((?<hours>\d+)\s*(h))/gi;
    const result = [...timeString.matchAll(regexTime)];
    let timeSeconds = 0;

    if (result.length > 0) {
      for (const search of result) {
        if (search.groups) {
          for (const groupName in search.groups) {
            if (typeof search.groups[groupName] !== 'undefined') {
              switch (groupName) {
                case 'days':
                  timeSeconds += search.groups[groupName] * 28800; // 8h
                  break;
                case 'hours':
                  timeSeconds += search.groups[groupName] * 3600; // 1h
                  break;
              }
            }
          }
        }
      }
    }

    if (timeSeconds === 0 && timeString !== '') {
      this.hasError = true;
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-estimation-format'),
        this.translocoService.translate('toastr.title-error'),
      );
    }

    return timeSeconds;
  }

  deleteTimeFields(data) {
    this.deleteFields(data, ['estimateValue', 'estimateUnit', 'realTime']);
  }

  deleteFields(data, fieldsToDelete) {
    for (const fieldName in data) {
      if (fieldsToDelete.indexOf(fieldName) !== -1) {
        delete data[fieldName];
      }
    }
  }

  deleteTicket() {
    if (this.ticket) {
      ConfirmAlert(null, {
        platform: this.platform,
        confirmButtonText: this.translocoService.translate('alert.confirm-button'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        text: this.translocoService.translate('alert.default-text'),
        subject: this.translocoService.translate('alert.delete-ticket', {
          value: `${
            this.ticketData.isEpic
              ? this.translocoService.translate('modals.board-ticket.epic')
              : this.translocoService.translate('modals.board-ticket.ticket')
          } "${this.ticket.title}"`,
        }),
      }).then(
        () => {
          this.store
            .dispatch(new TicketsDelete({ ticketDeleteId: this.modalData.id }))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(
              () => {
                this.handleTicketsColumnsProcessing(
                  this.ticketData.isEpic
                    ? this.translocoService.translate('modals.board-ticket.epic-deleted')
                    : this.translocoService.translate('modals.board-ticket.ticket-deleted'),
                  'Success',
                );
                this.closeHandler();
              },
              (err) => {
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                );
              },
            );
        },
        () => {},
      );
    }
  }

  private setColumnBoardColor(columns: any[], columnId: string): string {
    const column: any = columns?.find((col: any) => col._id === columnId);
    return column?.boardColorClass ? column.boardColorClass : '';
  }

  fileChange($event): void {
    if ($event.target?.files?.length) {
      this.addFilesToAttachment($event.target.files);
    }
  }

  private handleEditorImageClick(target: any): void {
    if (
      !this.mediaService.checkIfAvatar(target?.classList) &&
      !!target.closest('.description-editor') &&
      this.modalData &&
      this.modalData?.data?.description
    ) {
      const attachment = {
        url: target.src,
        fileName: 'image.jpg',
        originalFileName: 'image.jpg',
        ownerUserId: this.modalData?.data?.ownerUserId,
        updated_at: this.modalData?.data['updated_at'],
      };

      this.prepareMediaPreview(true);
      this.previewMedia(attachment);
    }
  }

  private prepareMediaPreview(isDescription = false): void {
    if (isDescription) {
      const imagesUrls = this.mediaService.fetchImagesFromString(
        this.ticketForm.controls?.description?.value,
      );
      this.ticketMedia = imagesUrls.map((url) => ({
        url: url,
        fileName: 'image.jpg',
        originalFileName: 'image.jpg',
        ownerUserId: this.modalData?.data?.ownerUserId,
        updated_at: this.modalData?.data['updated_at'],
      }));
    } else {
      this.ticketMedia = this.fileData.map((file) => ({
        url: file.url,
        _id: file._id,
        fileName: file.originalFileName,
        originalFileName: file.originalFileName,
        ownerUserId: this.modalData?.data?.ownerUserId,
        updated_at: this.modalData?.data['updated_at'],
      }));
    }

    this.ticketMedia[0].isFirstMedia = true;
    this.ticketMedia[this.ticketMedia.length - 1].isLastMedia = true;
  }

  previewMedia(attachment): void {
    if (this.modalData?.id) {
      const mediaPreviewModal = this.modal.open(PreviewMediaComponent, {
        size: 'xl',
        windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
        centered: true,
      });

      mediaPreviewModal.componentInstance.previewData = {
        platform: this.platform,
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        isMobile: this.isMobile,
        currentMedia: attachment,
      };

      mediaPreviewModal.componentInstance.isNotDataRoom = true;
      mediaPreviewModal.componentInstance.pinnedType = PinType.TicketFile;
      mediaPreviewModal.componentInstance.media = this.ticketMedia;

      mediaPreviewModal.closed.pipe(untilDestroyed(this)).subscribe(() => {
        let scrollTop = this.quill.quillEditor.scrollingContainer.scrollTop;
        this.quill.quillEditor.focus();
        this.quill.quillEditor.scrollingContainer.scrollTop = scrollTop;
      });

      this.minimizeService.minimizeInit(mediaPreviewModal, {
        width: '360px',
        height: '307px',
        right: '40px',
        borderRadius: '10px',
      });
    }
  }

  closeImagePreview(close: boolean): boolean {
    if (close) {
      this.ticketMedia = [];
      this.mediaPreviewVisible = false;
    }
    return close;
  }

  showMediaPreview(attachment: {
    _id?: string;
    fileName: string;
    originalFileName?: string;
    url?: string;
  }): void {
    if (this.isMinimized) {
      return;
    }

    this.prepareMediaPreview();
    this.previewMedia(attachment);
  }

  copyAttachmentLink(url) {
    this.htmlHelper.copyValueToBuffer(url);

    this.toastr.success(
      this.translocoService.translate('toastr.message-link-copied'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  downloadAttachment(document) {
    if (this.platformService.isCapacitor || this.platformService.isTauri) {
      this.downloadService.downloadFile(document);
    } else {
      this.filesHelper.downloadFile(document.url, document.originalFileName, this.platform);
    }
  }

  removeAttachment(index: number, fileName: string): void {
    const fileIndex = this.descriptionAttachmentsBlob.findIndex((file) => file.name === fileName);
    this.descriptionAttachmentsBlob.splice(fileIndex, 1);
    if (this.modalData.isNeedToSelectObject || this.ticketData.draft) {
      this.fileData.splice(index, 1);
      return;
    }
    this.store
      .dispatch(new TicketsFilesDelete({ id: this.fileData[index]._id }))
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => this.fileData.splice(index, 1));
  }

  getColumnById(columnId) {
    return this.columns?.find((item) => item._id === columnId);
  }

  getColumnColor(columnId) {
    return this.getColumnById(columnId)?.boardColorClass;
  }

  getColumnTitle(columnId) {
    const column = this.getColumnById(columnId);
    return column?.displayName || column?.title;
  }

  openTicket(ticket, isExpand = false) {
    this.closeHandler();

    setTimeout(() => {
      const modalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: ticket._id,
        object: ticket.object,
        objectId: ticket.objectId,
        isEpic: this.epicLabel ? this.epicLabel.isEpic : false,
        isExpand,
      };
      if (isExpand) {
        modalRef.componentInstance.ticketForm = this.ticketForm;
      }
      this.minimizeService.minimizeInit(modalRef);
    }, 0);
  }

  closeSubtaskSelect() {
    this.showSubtaskSelect = !this.showSubtaskSelect;
    this.ticketForm.controls['selectedSubtask'].setValue(null);
  }

  addSubTask(taskId) {
    if (this.ticketData.draft) {
      const ticket = this.tickets.find((task) => task._id === taskId);
      this.childrenList = [...(this.childrenList || []), ticket];
      this.ticketForm.controls['selectedSubtask'].setValue(null);
      this.ref.detectChanges();
      return;
    }
    if (taskId !== 'new') {
      if (this.tickets?.length) {
        const ticket = this.tickets?.find((item) => item._id === taskId);
        this.updateParentId({ ...ticket, epicId: this.modalData.id }, this.ticketData.isEpic);
      } else {
        const tickets = this.store.selectSnapshot(BoardsState.getTicketsList);
        const ticket = tickets?.find((item) => item._id === taskId);
        this.updateParentId({ ...ticket, epicId: this.modalData.id }, this.ticketData.isEpic);
        this.tickets = this.getTickets(tickets);
        this.ref.detectChanges();
      }
    } else {
      const columns = this.store.selectSnapshot(BoardsState.getColumnsList);
      const addData = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        title: this.selectedSubtask.title,
        parentId: !this.ticketData.isEpic ? this.modalData.id : 'null',
        columnId: columns.find((column) => column.title === 'TODO')?._id,
        epicId: this.ticketData.isEpic ? this.modalData.id : '',
      };

      this.store
        .dispatch(new TicketsCreate(addData))
        .pipe(
          untilDestroyed(this),
          withLatestFrom(this.store.select(BoardsState.getLastAddedTicketId)),
        )
        .subscribe(
          ([res, ticketId]) => {
            const ticket = res.Boards.tickets.find((item) => item._id === ticketId);
            const ticketAbbr = ticket ? `${ticket.boardAbbreviation}-${ticket.counter}` : '';

            if (this.modalData?.showToastMessage) {
              this.toastr.clear();
              this.toastr.success(
                this.translocoService.translate('toastr.message-ticket-created', {
                  text: ticketAbbr,
                }),
                this.translocoService.translate('toastr.title-success'),
                { enableHtml: true },
              );
              this.modalData.showToastMessage = false;
            }

            this.handleTicketsColumnsProcessing(null, null, false);
            this.childrenList = [...(this.childrenList || []), ticket];
            if (this.ticketData.isEpic) {
              this.subTicketsEpic = [...(this.subTicketsEpic || []), ticket];
            }
          },
          (err) => {
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    }
    this.ticketForm.controls['selectedSubtask'].setValue(null);
  }

  removeSubTask(ticket) {
    if (this.ticketData.draft) {
      this.childrenList = this.childrenList.filter((child) => child._id !== ticket._id);
      return;
    }
    this.updateParentId(ticket, this.ticketData.isEpic, false);
  }

  updateParentId(ticket, isEpic = false, addParent = true) {
    const body = {
      parentId: addParent && !isEpic ? this.ticketData.id : 'null',
      description: ticket.description,
      epicId: !isEpic ? null : addParent ? ticket.epicId : 'null',
    };

    this.store
      .dispatch(new TicketsUpdate({ ticketUpdateId: ticket._id, body }))
      .pipe(untilDestroyed(this), take(1))
      .subscribe(
        () => {
          if (addParent) {
            this.childrenList = [...(this.childrenList || []), ticket];
            this.subTicketsEpic = isEpic
              ? [...(this.subTicketsEpic || []), ticket]
              : this.subTicketsEpic;
            this.tickets = this.tickets?.filter((item) => item._id !== ticket._id);
            this.ticketsSelect = this.ticketsSelect.filter((item) => item._id !== ticket._id);
          } else {
            this.tickets = [...this.tickets, ticket].sort((a, b) =>
              a.counter > b.counter ? 1 : -1,
            );
            this.childrenList =
              !isEpic && this.childrenList?.filter((item) => item._id !== ticket._id);
            this.ticketsSelect = this.ticketsSelect.filter((item) => item._id !== ticket._id);
            this.subTicketsEpic = isEpic
              ? this.subTicketsEpic?.filter((item) => item._id !== ticket._id)
              : this.subTicketsEpic;
          }
        },
        (err) => {
          this.isUploading = false;
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }
  clearEstimate() {
    this.ticketForm.controls['estimateValue'].setValue(null);
  }

  get noneColorBoardTicket() {
    return (
      !this.ticketForm.value.bgColor ||
      this.ticketForm.value.bgColor === '#ffffff' ||
      this.ticketForm.value.bgColor === 'null'
    );
  }

  get colorBoardTicket() {
    return this.ticketForm.value.bgColor ? this.ticketForm.value.bgColor : this.settings?.bgColor;
  }

  toggleSubTasksSelect() {
    this.showSubtaskSelect = !this.showSubtaskSelect;
  }

  initTicketServiceData() {
    const { objectId, object } = this.modalData;

    if (object && objectId) {
      const actionPayload = {
        id: objectId,
        exists: true,
        isInternalState: true,
      };
      if (object === CalendarControlTypes.Spaces) {
        this.store.dispatch(new SpaceGetUsersList(actionPayload));
      } else if (object === CalendarControlTypes.Projects) {
        this.store.dispatch(new ProjectGetUsersList(actionPayload));
      }
      const payloadForAction = { object, objectId, isInternalState: true };

      this.users$ = this.ticketService.users$;

      this.store.dispatch(new FieldGetList(payloadForAction));
      this.store.dispatch(new TicketsLabelsGet(payloadForAction));
      this.store.dispatch(new ColumnsGetList(payloadForAction));
      if (!this.isAlreadyInit) {
        this.ticketService.chatId = this.chatId;
        this.store.dispatch(new ChatsGetPin({ id: this.chatId, isInternalState: true }));
      }
    }
  }

  initTicketServiceSubscribers() {
    this.ticketService.labels$
      .pipe(untilDestroyed(this))
      .subscribe((labels) => (this.labels = [...labels]));
    this.ticketService.columns$
      .pipe(untilDestroyed(this))
      .subscribe((columns) => this.prepareColumns(columns, this.modalData?.data?.columnId));
    this.ticketService.fields$
      .pipe(untilDestroyed(this))
      .subscribe((fields) => (this.fields = fields?.fields || []));
    this.ticketService.pinnedMessages$.pipe(untilDestroyed(this)).subscribe((pinnedMessages) => {
      const ticketId = this.ticket?._id || this.modalData?.id;
      if (!ticketId) {
        return;
      }

      const pinnedMessage = pinnedMessages.find(
        (pin) => pin.linkDocument && pin.linkTicket?._id === ticketId,
      );

      this.pinnedMessageId = pinnedMessage?._id;
      this.isPinned = this.ticketData?.draft ? this.ticketData?.draft.isPinned : !!pinnedMessage;

      if (pinnedMessage) {
        this.canUnpinTicket = this.pinnedService.canDeletePin(
          pinnedMessage,
          this.modalData.objectId,
        );
      }
    });
  }

  prepareColumns(columns: ColumnsDbDto[], columnId: string = null) {
    this.columns = [];

    for (const column of columns) {
      if (column.objectId === this.modalData.objectId && !column.isDeleted) {
        this.columns.push({
          ...column,
          title: column.isLocked && column.displayName ? column.displayName : column.title,
        });
      }
    }

    this.boardColumns = [...this.columns, { title: 'BACKLOG', _id: this.modalData.objectId }];

    if (columnId) {
      this.modalData.boardColorClass = this.setColumnBoardColor(this.columns, columnId);
    } else if (this.boardColumns[0]._id) {
      this.ticketForm.controls.columnId.setValue(this.boardColumns[0]._id);
      this.modalData.boardColorClass = this.setColumnBoardColor(
        this.columns,
        this.boardColumns[0]._id,
      );
    }
  }

  expandTicket() {
    this.onExpand.emit();
    this.isExpanded = true;
    this.isMinimized = false;
    this.ref.detectChanges();
  }

  minimizeTicket() {
    this.onMinimize.emit();
    this.isMinimized = true;
    this.isExpanded = false;
    this.ticketService.setTickets(this.store.selectSnapshot(BoardsState.getTicketsList));
    this.isMinimized$.next();
    this.isInternalState = true;
    this.ref.markForCheck();
  }

  toDraft() {
    const hasError = this.validateTicketData(true);
    if (hasError) {
      return;
    }

    const data = this.prepareForCreateOrUpdate(!this.modalData.id);
    const sharedData = this.prepareDraftSharedBody(data);
    if (this.ticketData.draft) {
      delete data?.realTime;
      this.store.dispatch(
        new DraftUpdate({
          id: this.ticketData.draft._id,
          body: {
            ...data,
            ...sharedData,
          },
        }),
      );
    } else {
      const draftTicket = this.store
        .selectSnapshot(BoardsState.getDrafts)
        .find((draft) => draft.ticketId === this.modalData?.id);
      const isHasDraft = this.modalData?.id && draftTicket;
      const keysToDelete = Object.keys(data).filter(
        (key) => data[key] === null || data[key] === '' || data[key] === 'null',
      );
      const dataToUpdate = Object.assign(
        {},
        ...Object.keys(data)
          .filter((key) => !keysToDelete.includes(key))
          .map((key) => ({ [key]: data[key] })),
      );

      if (isHasDraft) {
        this.store.dispatch(
          new DraftUpdate({
            id: draftTicket._id,
            body: { ...data, ...sharedData },
          }),
        );
      } else {
        this.store.dispatch(
          new DraftCreate({
            body: {
              ...dataToUpdate,
              ...sharedData,
            },
            ...(this.modalData.id ? { ticketId: this.modalData.id } : {}),
          }),
        );
      }
    }

    this.routerQueryService.update({ ticket: null, estimation: null });
    this.activeModal.close();
  }

  removeDraft(): void {
    if (this.ticketData.draft) {
      this.store.dispatch(new DraftDelete({ id: this.ticketData.draft._id }));
    }
  }

  fileDropped(files: FileList): void {
    this.addFilesToAttachment(files);
  }

  setIsDraggingFile(isDragging: boolean): void {
    this.isDraggingFile = isDragging;
  }

  addFilesToAttachment(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.fileData.push({
        fileName: files[i].name,
        originalFileName: files[i].name,
        ownerUserId: this.userData._id as string,
      });
      this.descriptionAttachmentsBlob.push(files[i]);
    }
  }

  prepareDraftCustomFields(oldCustomFields: any[], updatedCustomFields: any[]): any[] {
    // old - this.ticketForm.value.customFields, updated - data.customFields
    const uniqueTicketFieldsIds = [
      ...new Set([
        ...(oldCustomFields?.length ? oldCustomFields.map((field) => field.ticketFieldId) : []),
        ...(updatedCustomFields?.length
          ? updatedCustomFields.map((field) => field.ticketFieldId)
          : []),
      ]),
    ];

    return uniqueTicketFieldsIds.map((ticketFieldId) => {
      const updatedCustomField = updatedCustomFields?.find(
        (field) => field.ticketFieldId === ticketFieldId,
      );
      return updatedCustomField
        ? updatedCustomField
        : oldCustomFields?.find((field) => field.ticketFieldId === ticketFieldId);
    });
  }

  prepareDraftSharedBody(data) {
    // data - prepared body to the request
    const customFields = this.prepareDraftCustomFields(
      this.ticketForm.value.customFields,
      data.customFields,
    );
    const attachmentIds = this.fileData?.length ? this.fileData.map((file) => file._id) : undefined;
    return {
      customFields,
      attachmentIds,
      childrenIds: this.childrenList?.map((child) => child._id),
      isPinned: this.isPinned,
      files: this.descriptionAttachmentsBlob,
    };
  }

  validateTicketData(isDraft = false) {
    let errorMsg = null,
      title: string;
    if (!isDraft && this.validateCustomField.length) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-field', {
        text: this.validateCustomField.join(', '),
      });
    } else if (!this.checkTitle()) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-title');
    } else if (!isDraft && !this.checkObjectId()) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-board');
    } else if (!isDraft && !this.ticketForm.value.columnId) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-status');
    } else if (
      this.checkFileSize(this.ticketForm.controls.files.value) ||
      this.checkFileSize(this.descriptionAttachmentsBlob)
    ) {
      title =
        this.checkFileSize(this.ticketForm.controls.files.value)?.name ||
        this.checkFileSize(this.descriptionAttachmentsBlob)?.name;
      errorMsg = this.translocoService.translate('toastr.err-message-file-size', { size: '1GB' });
    } else if (!isDraft && !this.isDueDateValid()) {
      errorMsg = this.translocoService.translate('toastr.err-message-start-end-date');
    } else if (
      !isDraft &&
      !this.isStartDateValid(this.ticketForm.value.startDate, this.ticketForm.value.dueDate)
    ) {
      errorMsg = this.translocoService.translate('toastr.err-message-start-date-be-less-due-date');
    } else if (!isDraft && this.ticketData.isEpic && !this.isDateValid()) {
      errorMsg = this.translocoService.translate('toastr.err-message-date');
    }

    if (errorMsg) {
      this.needCreateNext = false;
      this.toastr.clear();
      this.toastr.error(
        errorMsg,
        title ? title : this.translocoService.translate('toastr.title-error'),
      );
      return errorMsg;
    }
  }

  private mapStartDateReminder(key: string): string {
    switch (key) {
      case 'Without':
        return 'without';
      case 'At the time of the event':
        return '0m';
      case '5 minutes before':
        return '5m';
      case '15 minutes before':
        return '15m';
      case '30 minutes before':
        return '30m';
      case '1 hour before':
        return '1h';
      case '2 hours before':
        return '2h';
      case '1 day before':
        return '1d';
      case '2 days before':
        return '2d';
      case '1 week before':
        return '1w';
      default:
        return key;
    }
  }
}
